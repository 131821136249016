<script lang="ts">
import { getOrgById } from '@/api/organizations';
import { Role } from '@/api/roles';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CommFormInput from '@/components/form/CommFormInput.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { filterDeactivatedOrg } from '@/utils/index';
import { customFailedMessage } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface ModuleAccessNode {
  children: string[];
  disabled: boolean;
  id: string;
  label: string;
  name: string;
}

@Component({
  name: 'UserManagementInputForm',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
    'module-access': ModuleAccess,
    'comm-form-input': CommFormInput,
  },
})
export default class extends Vue {
  @Prop({ default: true }) inputFormIsInEditMode!: boolean;
  @Prop({ required: true }) userForm!: any;
  @Prop() roles!: Role[];
  @Prop() organizationId!: string;
  @Prop() authorizableResources!: [];
  @Prop() errorInfos!: ErrorType[];
  @Prop() activateOrDeactivate!: string;

  /** Local variables */
  errorCode = ERROR_CODE_LIST;
  orgs: any = [];
  disabledTreeSel: boolean = false;
  organizationIdIsLoading: boolean = false;

  created() {
    this.handleSelectedAccessModules();
    this.fetchOrganizationData();
  }

  /**
   * Get inline message
   */
  get inlineMsg() {
    return false;
  }

  /**
   * Handle selected access modules
   */
  handleSelectedAccessModules() {
    if (!this.inputFormIsInEditMode) return;
    let defaultSelected: string[] = [];
    this.userForm.claims.forEach((item: any) => {
      defaultSelected.push(item.resource);
    });
    this.userForm.claimsFtd = defaultSelected;
  }

  /**
   * Validate claims
   * @param rule
   * @param value
   * @param callback
   */
  validateClaimsFtd = (rule: any, value: any, callback: any) => {
    const checkedNode = (
      this.$refs.userManagementModuleAccess as any
    ).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  /**
   * Validate organization id
   * @param rule
   * @param value
   * @param callback
   */
  validateOrgId = (rule: any, value: any, callback: any) => {
    if (this.userForm.organizationId) {
      callback();
    } else {
      callback(new Error(`${this.$t('userModule.tipSelectOrg')}`));
    }
  };

  /**
   * Validate organization iduser name field
   * @param rule
   * @param value
   * @param callback
   */
  validateUserName = (rule: any, value: any, callback: any) => {
    if (this.userForm.organizationId) {
      callback();
    } else {
      callback(new Error(`${this.$t('organizationModule.tipSelectName')}`));
    }
  };

  /**
   * Validate phone number field
   * @param rule
   * @param value
   * @param callback
   */
  validatePhoneNumber = (rule: any, value: any, callback: any) => {
    const onlyNumberRegex = /[0-9]|\./;
    if (
      this.userForm.mobilePhone &&
      !onlyNumberRegex.test(this.userForm.mobilePhone)
    ) {
      callback(new Error(`${this.$t('userModule.onlyNumbers')}`));
    }
    callback();
  };

  /**
   * Get rules for input form
   */
  get rules() {
    const tmpRules = {
      username: [{ validator: this.validateUserName, trigger: 'input' }],
      email: [
        { required: true, message: this.$t('userModule.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('userModule.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      role: [
        {
          required: true,
          message: this.$t('userModule.tipSelectRole'),
          trigger: 'change',
        },
      ],
      organizationId: [{ validator: this.validateOrgId, trigger: 'input' }],
      claimsFtd: [{ validator: this.validateClaimsFtd, trigger: 'change' }],
      mobilePhone: [{ validator: this.validatePhoneNumber, trigger: 'change' }],
    };
    return tmpRules;
  }

  /**
   * Send event for input
   */
  handleEmailInput() {
    this.userForm.email = this.userForm.email.trim().replace(/\s+/g, '');
    this.$emit('handle-email-input');
  }

  /**
   * Handle access module selection
   */
  handleAccessModuleSelection() {
    (this.$refs.userForm as any).validateField('organizationId');
  }

  /**
   * Handle tree select expanded/open
   */
  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  /**
   * Handle tree select close/minimize
   */
  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  /**
   * Email validation
   */
  get errEmailInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos?.find((item) => item.field === this.errorCode.email)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateEmail')}`;
    } else if (
      this.errorInfos?.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  /**
   * Handle change role event
   */
  changeRole(roleCode: string) {
    this.roles.find((role) => {
      if (role.code === roleCode) {
        this.$emit('change-role', role.claims);
      }
    });
  }

  /**
   * Tree select custom key for children
   * @param node
   */
  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children?.length > 0 ? node.children : undefined,
    };
  }

  /**
   * Get organization management info
   */
  async fetchOrganizationData() {
    try {
      this.organizationIdIsLoading = true;
      await getOrgById(this.organizationId).then((res) => {
        if (res.code === 200) {
          let tempResData = res.data;
          this.orgs = filterDeactivatedOrg([tempResData]);
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.organizationIdIsLoading = false;
    }
  }

  /**
   * Validate access modules and send event to the parent with selections
   */
  validateClickAction() {
    (this.$refs.userForm as any).validate((valid: any) => {
      if (valid) {
        return this.$emit('handle-api-event');
      }
    });
  }

  /**
   * Hide user management input form
   */
  cancelUserManagementForm() {
    this.$emit('cancel-user-form');
  }

  /** Remove user remotly */
  async removeUser() {
    this.$emit('remove-user-event');
  }

  /**
   * Show modal window event
   */
  showModalWindow() {
    this.$emit('show-modal-window');
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos?.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }
}
</script>

<template>
  <div class="user-mgmt-general">
    <div class="d-flex jc-start ai-center" style="margin: 20px 0">
      <div class="d-flex ai-center flex-g1"></div>
      <div class="d-flex ai-center">
        <el-button
          type="plain"
          @click="validateClickAction"
          style="margin-right: 5px"
        >
          <i class="el-icon-plus common-icon" />{{ $t('common.save') }}
        </el-button>
        <el-button
          v-if="inputFormIsInEditMode"
          type="plain"
          @click="showModalWindow"
          style="margin-right: 5px"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/deactivate.svg"
              />
            </div>
            <div>
              {{
                activateOrDeactivate === 'deactivate'
                  ? $t('common.deactivate')
                  : $t('common.activate')
              }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="inputFormIsInEditMode"
          type="plain"
          @click="removeUser"
          style="margin-right: 5px"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/deactivate.svg"
              />
            </div>
            <div>
              {{ $t('common.remove') }}
            </div>
          </div>
        </el-button>
        <el-button
          type="plain"
          @click="cancelUserManagementForm"
          style="margin-right: -15px"
        >
          <i class="el-icon-close common-icon" />{{ $t('common.cancelBtn') }}
        </el-button>
      </div>
    </div>
    <el-form
      ref="userForm"
      :model="userForm"
      :rules="rules"
      :inline-message="inlineMsg"
      style="margin-left: 20px"
    >
      <cus-form-item
        v-if="inputFormIsInEditMode"
        :title="'userModule.userName'"
        :errContent="errNameInfo"
      >
        <el-form-item prop="username">
          <el-input
            v-model="userForm.username"
            v-bind:placeholder="$t('userSetting.inputUserName')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        v-if="inputFormIsInEditMode"
        :title="'userModule.firstName'"
        :required="false"
      >
        <el-form-item>
          <el-input
            v-model="userForm.firstName"
            v-bind:placeholder="$t('userSetting.inputFirstName')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        v-if="inputFormIsInEditMode"
        :title="'userModule.lastName'"
        :required="false"
      >
        <el-form-item>
          <el-input
            v-model="userForm.lastName"
            v-bind:placeholder="$t('userSetting.inputLastName')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        :title="$t('userModule.email')"
        :errContent="errEmailInfo"
        style="margin-top: 10px"
      >
        <el-form-item prop="email">
          <el-input
            id="new_user_input_email"
            v-model="userForm.email"
            :disabled="inputFormIsInEditMode"
            v-bind:placeholder="$t('userModule.inputEmail')"
            @input="handleEmailInput"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        :title="$t('userModule.userRole')"
        :required="!inputFormIsInEditMode"
      >
        <el-form-item prop="role">
          <el-select
            id="new_user_select_role"
            v-model="userForm.role"
            :clearable="false"
            v-bind:placeholder="$t('userModule.inputAccountRole')"
            @change="changeRole"
            :disabled="inputFormIsInEditMode"
          >
            <el-option
              v-for="role in roles"
              :key="role.id"
              :label="role.name"
              :value="role.code"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="$t('userModule.organizationName')">
        <el-form-item prop="organizationId">
          <treeselect
            v-loading="organizationIdIsLoading"
            id="new_user_tree_org"
            :options="orgs"
            v-model="userForm.organizationId"
            :normalizer="customKey"
            :clearable="false"
            :placeholder="$t('userModule.select')"
            @input="handleAccessModuleSelection"
            @open="handleTreeselectOpen"
            @close="handleTreeselectClose"
          />
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        v-if="inputFormIsInEditMode"
        :title="'userModule.mobilePhone'"
        :required="false"
      >
        <el-form-item prop="mobilePhone">
          <el-input
            v-model="userForm.mobilePhone"
            v-bind:placeholder="$t('userSetting.inputMobile')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        v-if="inputFormIsInEditMode"
        :title="$t('userModule.note')"
        style="margin-top: 10px"
        :required="false"
      >
        <el-form-item prop="note">
          <el-input
            id="new_user_input_note"
            v-model="userForm.note"
            v-bind:placeholder="$t('userModule.note')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <div class="cus-form-module">
        <div class="cus-form-module-header">
          <span class="show-title">{{ $t('userModule.moduleAccess') }}:</span>
          <span v-if="true" class="required-field">*</span>
        </div>
        <div>
          <el-form-item prop="claimsFtd">
            <module-access
              id="new_user_module_claim"
              class="user_module_claim"
              ref="userManagementModuleAccess"
              :authorizableResources="authorizableResources"
              :defaultChecked="userForm.claimsFtd"
              :defaultExpand="false"
              :isEdit="false"
            />
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<style scoped>
.cus-form-module {
  margin-left: 3px;
}
.cus-form-module-header {
  margin-right: 0px;
}
</style>

<style lang="scss" scoped>
.user-mgmt-general {
  margin: 10px 20px;
}

.show-title {
  margin-left: 55px;
}
</style>
