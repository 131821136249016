<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'NavCardPage',
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ default: true }) visible!: boolean;
  @Prop({ default: true }) arrowBack!: boolean;
  @Prop({ default: true }) totalSubscriptions!: number;

  goBack() {
    //the arrow back doesn't always go back in some situations.
    if (!this.arrowBack) {
      this.$emit('arrow-click');
    } else this.$router.go(-1);
  }
}
</script>

<template>
  <div class="cus-add-container" style="overflow: auto">
    <div class="header d-flex ai-center jc-between">
      <div id="new_card_header_title" class="d-flex ai-center">
        <i
          v-if="visible"
          class="el-icon-arrow-left"
          style="font-size: 25px; cursor: Pointer; margin-left: 20px"
          @click="goBack"
        />
        <span class="header-title">{{ $t(title) }}</span>
        <span class="total-statistics"
          >{{ $t('subsMgmt.totalNumberSubscriptionOrders') }}:</span
        >
        <span class="total-statistics-value">{{ totalSubscriptions }}</span>
      </div>
      <slot name="button"></slot>
    </div>

    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.total-statistics {
  margin-left: 21px;
  font-size: 16px;
  color: #c1c1c1;
}

.total-statistics-value {
  font-size: 16px;
  color: #c1c1c1;
  padding-left: 4px;
}
</style>

<style lang="scss" scoped>
.cus-add-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  height: 100%;
  padding-bottom: 12px;

  .header {
    font-weight: 500;
    font-family: $font-Roboto-Regular;
    line-height: 21px;
    border-bottom: 1px solid #dddddd;
    padding: 20px 20px;
    height: 60px;
  }

  .body {
    display: flex;
    // min-height: 100vh;
    flex-direction: column;
    // background-color:blanchedalmond;
    min-height: calc(100% - 140px);
  }
}

.el-icon-arrow-down {
  font-size: 12px;
}

@media (max-width: 768px) {
  .viewPageBtn {
    display: none;
  }

  .mobileViewPageBtn {
    display: block;
  }
}
</style>
