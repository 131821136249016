<script lang="ts">
import ResizeMixin from '@/components/charts/mixins/resize';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { formatNumber } from '@/utils/number';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue, UnitValue } from '@/utils/units/unitValue';
import echarts, { ECharts } from 'echarts';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

export type BarChartEntry = [string, string];

@Component({
  name: 'BarChart',
})
export default class extends mixins(ResizeMixin) {
  @Prop() id!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '200px' }) height!: string;
  @Prop({ default: '#48B8F4' }) itemColor!: any;
  @Prop({ default: '#137AB1' }) emphasisColor!: any;

  /**
   * Chart values, given as [X,Y] key-value pairs.
   *
   * Y values are expected to be in the unit given by `yAxisUnit`,
   * which should be metric (i.e. non-user converted).
   */
  @Prop({ required: true }) chartData!: BarChartEntry[];

  /**
   * Y-axis unit.
   *
   * Unit is expected to be metric (i.e. non-user converted).
   */
  @Prop({ required: true }) yAxisUnit!: KPI_UNIT;

  chart: ECharts | null = null;
  xAxisData: string[] = [];
  yAxisData: UnitValue<number | undefined>[] = [];
  unitConversion!: UseUnitConversionReturnType;

  formatNumber = formatNumber;

  @Watch('chartData', { deep: true })
  handleData(newData: any) {
    if (newData) {
      this.collateAxisData();
      this.initChart();
    }
    return newData;
  }

  created() {
    this.unitConversion = useUnitConversion();
  }

  mounted() {
    this.$nextTick(() => {
      this.collateAxisData();
      this.initChart();
    });
  }

  collateAxisData() {
    this.xAxisData = [];
    this.yAxisData = [];
    this.chartData?.forEach((item: any) => {
      if (moment(item[0]).isValid()) {
        this.xAxisData.push(moment(item[0]).format('MM/DD'));
      } else {
        this.xAxisData.push(item[0]);
      }
      this.yAxisData.push(
        this.unitConversion.currentUserConvertUnitValue(
          toUnitValue(parseFloat(item[1]), this.yAxisUnit)
        )
      );
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.resizeChart);
    this.chart.dispose();
    this.chart = null;
  }

  triggerTooltip(xAxiValue: any) {
    let dataIndex: number = -1;
    this.chartData.forEach((item: any, index: number) => {
      if (item[0] === xAxiValue) {
        dataIndex = index;
        return;
      }
    });
    this.chart?.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: dataIndex,
      position: (pt: any) => {
        return [pt[0], '50%'];
      },
    });
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        position: 'top',
        formatter: (datas: any) => {
          // datas: trigger:item   datas[0]:trigger axis
          let resHtml = `<p style="color: #B6B6B6; text-align:center; margin: 9px 16px 4px">${this.chartData[
            datas[0].dataIndex
          ][0].replace(
            /-/g,
            '/'
          )}</p><p style="color: #fff; text-align:center; margin: 3px 0 10px 0;"> <span style="font-size: 16px">${formatNumber(
            datas[0].value,
            2
          )}</span> <span style="font-size: 14px">${this.$t(
            this.yAxisUnit
          )}</span></p>`;
          return resHtml;
        }, //the custom style of tooltip
        backgroundColor: 'rgba(55, 62, 65, 0.8)',
        textStyle: {
          color: '#fff',
        },
      },
      grid: {
        top: '8%',
        left: '80',
        bottom: '14%',
        right: '40',
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            fontSize: 12,
            color: '#373E41',
          },
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: this.$t(this.yAxisUnit),
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',
            },
          },
          axisLabel: {
            fontSize: 14,
            color: '#373E41',
          },
        },
      ],
      series: [
        {
          name: 'barChart',
          type: 'bar',
          markLine: {
            silent: true,
            data: [{ type: 'average' }],
            lineStyle: {
              color: '#EA6D01',
              type: 'dotted',
            },
            label: {
              show: true,
              position: 'start',
              formatter: (data: any) => {
                let str: string = this.$t('KPI.ave') + '.' + data.value;
                return str;
              },
            },
            symbol: ['none', 'none'],
          }, //the average line style
          barMaxWidth: 24,
          data: this.yAxisData.map((item) => item.v),
          lineStyle: {
            color: '#707070',
            width: 1,
          },
          itemStyle: {
            color: this.itemColor,
          },
        },
      ],
    });

    window.addEventListener('resize', this.resizeChart);
    this.resizeChart();
  }

  resizeChart() {
    this.chart?.resize();
  }
}
</script>

<template>
  <div :id="id" :style="{ height: height, width: width }" />
</template>

<style lang="scss" scoped></style>
