import { render, staticRenderFns } from "./RegisteredAssets.vue?vue&type=template&id=3ed217d1&scoped=true"
import script from "./RegisteredAssets.vue?vue&type=script&lang=ts"
export * from "./RegisteredAssets.vue?vue&type=script&lang=ts"
import style0 from "./RegisteredAssets.vue?vue&type=style&index=0&id=3ed217d1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed217d1",
  null
  
)

export default component.exports