<script lang="ts">
import { getKpiData, getOrganizationAssetsHierarchyLegacy } from '@/api/assets';
import { KpiDataRequest, KpiDataResponse } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import TimeSelect from '@/components/form/TimeSelect.vue';
import GrowthPercentage from '@/components/general/GrowthPercentage.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { findAssets } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { isDesignatedCompany } from '@/utils/companyService';
import { calculatePercentageChange } from '@/utils/math';
import { DEFAULT_DATE_RANGE, getPreviousRange } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import FleetTargetExpanded from '@/widgets/fleet/expanded/FleetTargetExpanded.vue';
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';

interface WidgetInfo {
  percentages: {
    currentPeriod: number;
    lastPeriod: number;
  };
  unit: string;
  currentPeriod: {
    start: string;
    end: string;
  };
  lastPeriod: {
    start: string;
    end: string;
  };
  currentCycle: number;
  lastCycle: number;
  growthPercentage: {
    averageLoadPerTrip?: number;
    totalTrips?: number;
  };
  averageLoadPerTrip: {
    currentCycle: number;
    lastCycle: number;
  };
  totalTrips: {
    currentCycle: number;
    lastCycle: number;
  };
}

const components = {
  FleetTargetExpanded,
  TimeSelect,
  WidgetCard,
  GrowthPercentage,
};

@Component({
  name: 'FleetTarget',
  components,
})
export default class extends Vue {
  static components: typeof components;

  @Inject() expanded!: boolean;

  /** Local variables */
  isLoading: boolean = true;
  dateRange = DEFAULT_DATE_RANGE;
  arrowList = ['positive', 'negative'];
  firstLoad: boolean = true;
  currentCycle: KpiDataResponse['details'][number]['fields'] = [];
  lastCycle: KpiDataResponse['details'][number]['fields'] = [];
  widgetInfo: WidgetInfo = {
    percentages: {
      currentPeriod: 0,
      lastPeriod: 0,
    },
    unit: '',
    currentPeriod: {
      start: '',
      end: '',
    },
    lastPeriod: {
      start: '',
      end: '',
    },
    currentCycle: 0,
    lastCycle: 0,
    growthPercentage: {
      averageLoadPerTrip: undefined,
      totalTrips: undefined,
    },
    averageLoadPerTrip: {
      currentCycle: 0,
      lastCycle: 0,
    },
    totalTrips: {
      currentCycle: 0,
      lastCycle: 0,
    },
  };
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_TIME',
      },
    },
    details: [
      {
        entity: this.$route.params.id ? 'ENTT_ASSET' : 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
          {
            code: KPI_FIELDS.TripCount,
            unit: KPI_UNIT.UnitCount,
          },
          {
            code: KPI_FIELDS.TippingTime,
            unit: KPI_UNIT.Second,
          },
          {
            code: KPI_FIELDS.PTOWorkingHours,
            unit: KPI_UNIT.Hour,
          },
          // TODO Apparently, we need to ask for HoursTipping and HoursFiltering,
          // even if we're only interested in the (total) PTOWorkingHours. This
          // is a bug in the KPI microservice.
          {
            code: KPI_FIELDS.PTOWorkingHoursTipping,
            unit: KPI_UNIT.Hour,
          },
          {
            code: KPI_FIELDS.PTOWorkingHoursFiltering,
            unit: KPI_UNIT.Hour,
          },
        ],
      },
    ],
  };
  defaultKpiUnit: string = KPI_UNIT.MetricTonne;
  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();
    this.unitConversion = useUnitConversion();
    this.getData();
  }

  handleTimeFilter(dateRange: DateRange) {
    this.dateRange = dateRange;
    if (!this.firstLoad) {
      this.getData();
    }
  }

  async getData() {
    try {
      this.isLoading = true;

      this.requestPayload.metadata.timezone = unref(
        this.context
      ).primaryOrgTimeZone;
      this.requestPayload.metadata.selection!.startDate = this.dateRange.start;
      this.requestPayload.metadata.selection!.endDate =
        this.dateRange.endExclusive;
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;

      if (this.$route.params.id) {
        this.requestPayload.metadata.filter = {
          assetIds: [this.$route.params.id],
        };
      } else if (
        this.loggedInUser.value &&
        isDesignatedCompany(this.loggedInUser.value.companyType)
      ) {
        const organizationResponse = await getOrganizationAssetsHierarchyLegacy(
          AssetType.TippingVehicle,
          unref(this.context).organization?.id,
          unref(this.context)
        );

        const assets = findAssets(organizationResponse.organization);
        this.requestPayload.metadata.filter = {
          assetIds: assets.map((asset) => asset.id),
        };
      }

      const responseCurrentCycle = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );

      if (
        responseCurrentCycle.hasOwnProperty('data') &&
        responseCurrentCycle.data?.details?.length > 0
      ) {
        this.currentCycle = responseCurrentCycle.data.details[0].fields;
      }

      const previousRange = getPreviousRange(this.dateRange);
      this.requestPayload.metadata.selection!.startDate = previousRange.start;
      this.requestPayload.metadata.selection!.endDate =
        previousRange.endExclusive;
      const responseLastCycle = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );
      if (
        responseLastCycle.hasOwnProperty('data') &&
        responseCurrentCycle.data?.details?.length > 0
      ) {
        this.lastCycle = responseLastCycle.data.details[0].fields;
      }
      this.populateWidgetInfo();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
      this.firstLoad = false;
    }
  }

  populateWidgetInfo() {
    const totalTripsCurrentCycle = this.sum(
      this.currentCycle,
      KPI_FIELDS.TripCount
    );
    const totalTripsLastCycle = this.sum(this.lastCycle, KPI_FIELDS.TripCount);

    const averageLoadPerTrip = {
      currentCycle:
        totalTripsCurrentCycle !== 0
          ? this.unitConversion.currentUserConvertUnitValue({
              v:
                this.sum(this.currentCycle, KPI_FIELDS.TippingPayload) /
                totalTripsCurrentCycle,
              unit: KPI_UNIT.MetricTonne,
            }).v
          : 0,
      lastCycle:
        totalTripsLastCycle !== 0
          ? this.unitConversion.currentUserConvertUnitValue({
              v:
                this.sum(this.lastCycle, KPI_FIELDS.TippingPayload) /
                totalTripsLastCycle,
              unit: KPI_UNIT.MetricTonne,
            }).v
          : 0,
    };

    const previousRange = getPreviousRange(this.dateRange);
    this.widgetInfo = {
      percentages: this.getPayloadPercentages(
        this.sum(this.currentCycle, KPI_FIELDS.TippingPayload),
        this.sum(this.lastCycle, KPI_FIELDS.TippingPayload)
      ),
      unit:
        this.currentCycle.find((kpi) => kpi.code === KPI_FIELDS.TippingPayload)
          ?.unit ?? 'UNIT_METRIC_TONNE', // Fallback because we currently don't get unit forwarded (AHMAPP-4556)
      currentPeriod: {
        start: this.dateRange.start,
        end: this.dateRange.end,
      },
      lastPeriod: {
        start: previousRange.start,
        end: previousRange.end,
      },
      currentCycle: this.unitConversion.currentUserConvertUnitValue({
        v: this.sum(this.currentCycle, KPI_FIELDS.TippingPayload),
        unit: KPI_UNIT.MetricTonne,
      }).v,
      lastCycle: this.unitConversion.currentUserConvertUnitValue({
        v: this.sum(this.lastCycle, KPI_FIELDS.TippingPayload),
        unit: KPI_UNIT.MetricTonne,
      }).v,
      growthPercentage: {
        averageLoadPerTrip: calculatePercentageChange(
          averageLoadPerTrip.lastCycle,
          averageLoadPerTrip.currentCycle
        ),
        totalTrips: calculatePercentageChange(
          totalTripsLastCycle,
          totalTripsCurrentCycle
        ),
      },
      averageLoadPerTrip: averageLoadPerTrip,
      totalTrips: {
        currentCycle: totalTripsCurrentCycle,
        lastCycle: totalTripsLastCycle,
      },
    };
  }

  private sum(
    fields: KpiDataResponse['details'][number]['fields'],
    kpiCode: string
  ): number {
    const values =
      fields
        .find((field) => field.code === kpiCode)
        ?.values.map((value) => value.v) ?? [];
    // Note: original type marks these as string, but apparently, sometimes they can also be null.
    // TODO Probably fix the type of KpiDataResponse instead!
    const total = values.reduce(
      (
        a: string | number | null | undefined,
        b: string | number | null | undefined
      ) => {
        a ??= 0;
        b ??= 0;
        return +a + +b; // cast each to number if needed
      },
      0
    );
    return total;
  }

  private getPayloadPercentages(
    currentPeriodPayload: number,
    lastPeriodPayload: number
  ) {
    if (isNaN(currentPeriodPayload)) currentPeriodPayload = 0;
    if (isNaN(lastPeriodPayload)) lastPeriodPayload = 0;
    if (currentPeriodPayload === 0 && lastPeriodPayload === 0)
      return { currentPeriod: 0, lastPeriod: 0 };
    return currentPeriodPayload >= lastPeriodPayload
      ? {
          currentPeriod: 100,
          lastPeriod:
            (100 * Math.min(currentPeriodPayload, lastPeriodPayload)) /
            Math.max(currentPeriodPayload, lastPeriodPayload),
        }
      : {
          currentPeriod:
            (100 * Math.min(currentPeriodPayload, lastPeriodPayload)) /
            Math.max(currentPeriodPayload, lastPeriodPayload),
          lastPeriod: 100,
        };
  }

  /** Get current unit */
  get currentUnit(): string {
    return this.widgetInfo.unit ? this.widgetInfo.unit : this.defaultKpiUnit;
  }
}
</script>

<template>
  <WidgetCard v-if="!expanded" :loading="isLoading" :expandable="true">
    <TimeSelect
      :expanded="true"
      @select="handleTimeFilter"
      :customizable="true"
    />
    <div class="target-container">
      <ul class="chart-list">
        <li class="row" key="current">
          <div class="lable">
            <span>{{ $t('tippingVehicles.target.currentCycle') }}</span>
            <span class="period">
              {{ widgetInfo.currentPeriod.start }} -
              {{ widgetInfo.currentPeriod.end }}
            </span>
          </div>
          <div class="bar">
            <div
              class="progress"
              :style="`width: ${widgetInfo.percentages.currentPeriod}%`"
            />
            <span class="value">
              {{ widgetInfo.currentCycle.toFixed(2) }}
              {{ $t(`tippingVehicles.target.unit.${currentUnit}`) }}
            </span>
          </div>
        </li>
        <li class="row" key="last">
          <div class="lable">
            <span>{{ $t('tippingVehicles.target.lastCycle') }}</span>
            <span class="period">
              {{ widgetInfo.lastPeriod.start }} -
              {{ widgetInfo.lastPeriod.end }}
            </span>
          </div>
          <div class="bar">
            <div
              class="progress"
              :style="`width: ${widgetInfo.percentages.lastPeriod}%`"
            />
            <span class="value">
              {{ widgetInfo.lastCycle.toFixed(2) }}
              {{ $t(`tippingVehicles.target.unit.${currentUnit}`) }}
            </span>
          </div>
        </li>
      </ul>
      <div class="tab">
        <h3></h3>
        <h3 class="header-1">
          {{ $t('tippingVehicles.target.totalTrips') }}
        </h3>
        <h3 class="header-2">
          {{
            `${$t('kpiTarget.table.payloadPerTrip')} (${$t(
              'UNIT_METRIC_TONNE'
            )})`
          }}
        </h3>
        <p class="cell-1">{{ $t('tippingVehicles.target.currentCycle') }}</p>
        <a class="cell-2">
          {{ widgetInfo.totalTrips.currentCycle }}
          <GrowthPercentage
            class="growthPercentage"
            :growthPercentage="widgetInfo.growthPercentage.totalTrips"
          />
        </a>
        <a class="cell-3">
          {{ widgetInfo.averageLoadPerTrip.currentCycle.toFixed(2) }}
          <GrowthPercentage
            class="growthPercentage"
            :growthPercentage="widgetInfo.growthPercentage.averageLoadPerTrip"
          />
        </a>
        <p class="cell-1">{{ $t('tippingVehicles.target.lastCycle') }}</p>
        <a class="cell-2">{{ widgetInfo.totalTrips.lastCycle }}</a>
        <a class="cell-3">
          {{ widgetInfo.averageLoadPerTrip.lastCycle.toFixed(2) }}
        </a>
      </div>
    </div>
  </WidgetCard>
  <FleetTargetExpanded v-else />
</template>

<style lang="scss" scoped>
.time-selector {
  padding: 16px 0px 0px 28px;
}

.target-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 30px 10px 30px;
}

.chart-list {
  border-bottom: 1px solid #dddddd;
  list-style-type: none;
  width: 100%;

  .row {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .lable {
      width: 174px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: column;

      .period {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        opacity: 0.8;
        margin-top: 4px;
      }
    }

    .bar {
      margin: 0 0 0 32px;
      height: 40px;
      width: 100%;
      background: #e6e6e6;
      display: flex;
      flex-direction: row;

      .value {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        margin: auto 16px;
      }

      .progress {
        height: 40px;
        background: #91c19a;
      }
    }
  }
}

.tab {
  color: #373e41;
  display: grid;
  grid-template-areas:
    'empty  header-1 header-2'
    'cell-1 cell-2 cell-3';

  h3 {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  p {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  a {
    display: flex;
    margin: auto auto auto 0;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  .header-1 {
    grid-area: header-1;
  }

  .header-2 {
    grid-area: header-2;
  }

  .header-3 {
    grid-area: header-3;
  }
}

.growthPercentage {
  margin-left: 6px;
}
</style>
