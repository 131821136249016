<script lang="ts">
import { AssetObjectModel, getAssetById } from '@/api/assets';
import { getEvent } from '@/api/event';
import { GeofenceAsset } from '@/api/geofenceTypes';
import { getProductModelById } from '@/api/products';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import MobileCompactor from '@/icons/svg/mobileCompactor.svg';
import StaticCompactor from '@/icons/svg/staticCompactor.svg';
import TippingVehicle from '@/icons/svg/tippingVehicle.svg';
import router from '@/router';
import {
  hasNormalOverviewRoutesForAssetType,
  hasOperationalOverviewRoutesForAssetType,
  isSingleAssetViewRoute,
  operationalDashboardSingleAssetOverviewLocation,
  singleAssetOverviewLocation,
} from '@/router/links/assetOverview';
import { AssetType } from '@/utils/assetTypes';
import { customFailedMessage } from '@/utils/prompt';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'EventDetailsModal',
  components: {
    LeafletMap,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() eventId!: string;

  @Prop({ default: false })
  operationalSupportMode!: boolean;

  /** Local variables */
  url: string | undefined = Vue.prototype.$envConfig.VUE_APP_MAP_URL;
  center: number[] = [0, 0];
  zoom: number = 10;
  assets: Array<GeofenceAsset> = [];
  event: any = null;

  @InitializeReactive
  asset: AssetObjectModel | undefined;

  productModel: any = null;

  productModelImageStyle: any = {
    backgroundImage: '',
  };
  addressLabel: string | null = null;

  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;

  async created() {
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();
    await this.getEventDetails();
  }

  /** Get event details */
  async getEventDetails() {
    if (this.eventId) {
      await getEvent(this.eventId, unref(this.context)).then((response) => {
        if (response.code !== 200) {
          this.closeDialog();
          customFailedMessage(response.message);
          return;
        }
        this.event = response.data;

        if (this.event.location?.lat && this.event.location?.lgt) {
          this.center = [this.event.location.lat, this.event.location.lgt];
        } else {
          this.zoom = 1;
        }

        if (this.event.address?.addressLabel) {
          this.addressLabel = this.event.address.addressLabel;
        }

        this.getAssetDetails();
      });
    }
  }

  /** Get asset details, trigger product model after response */
  async getAssetDetails() {
    const responseAsset = await getAssetById(
      this.event.assetId,
      unref(this.context)
    );

    const asset = responseAsset.data;
    this.asset = asset;
    this.getProductModelDetails();
    const assetWithGeo: GeofenceAsset = {
      id: this.asset.id,
      companyAssetId: this.asset.companyAssetId,
      assetDataCollectionId: this.asset.assetDataCollectionId,
      geodeticLatitude: this.event.location?.lat || 0,
      geodeticLongitude: this.event.location?.lgt || 0,
      assetTypeCode: this.asset.assetType,
      lastSyncTime: new Date(),
      popup: false,
    };

    if (
      assetWithGeo.geodeticLatitude &&
      assetWithGeo.geodeticLongitude &&
      assetWithGeo.geodeticLatitude * assetWithGeo.geodeticLongitude != 0
    ) {
      this.assets = [assetWithGeo];
    }
  }

  /** Get product model by id */
  async getProductModelDetails() {
    if (!this.asset) {
      this.productModel = undefined;
      return;
    }
    try {
      const response = await getProductModelById(this.asset.productModelId);
      this.productModel = response.data;
      if (this.productModel?.picturePath) {
        this.productModelImageStyle.backgroundImage = `url("${this.productModel?.picturePath}")`;
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Handle product model picture
   * - check product model if exist otherwise loading will be displayed
   * - if product model exist and picture path is defined, show product model picture path uploaded in the system
   * - if no one from above > show default svg picture of the asset
   */
  get pictureUrlSource() {
    if (!this.productModel) {
      return null;
    }
    if (
      this.productModel?.picturePath &&
      this.productModel?.picturePath.length > 0
    ) {
      return this.productModel?.picturePath;
    }
    let tempSvg;
    switch (this.asset?.assetType) {
      case AssetType.MobileCompactor: {
        tempSvg = MobileCompactor;
        break;
      }
      case AssetType.StaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.AlbaStaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.TippingVehicle: {
        tempSvg = TippingVehicle;
        break;
      }
      case AssetType.RefuseCollectionVehicle: {
        tempSvg = StaticCompactor;
        break;
      }
    }
    return tempSvg;
  }

  /** Close modal window */
  closeDialog() {
    this.$emit('quit-event-details-dialog');
  }

  handleGotoSingleAssetView(): void {
    if (!this.asset) {
      return;
    }
    if (this.operationalSupportMode) {
      router.push(
        operationalDashboardSingleAssetOverviewLocation(
          this.asset.assetType,
          this.asset.id
        )
      );
    } else {
      router.push(
        singleAssetOverviewLocation(this.asset.assetType, this.asset.id)
      );
    }
  }

  get isSingleAssetView(): boolean {
    return isSingleAssetViewRoute(this.$route);
  }

  get isSingleAssetRouteDefined(): boolean {
    if (!this.asset) {
      return false;
    }
    return this.operationalSupportMode
      ? hasOperationalOverviewRoutesForAssetType(this.asset.assetType)
      : hasNormalOverviewRoutesForAssetType(this.asset.assetType);
  }
}
</script>

<template>
  <div class="event-details-modal-container">
    <el-dialog
      v-if="event"
      :visible="visible"
      class="event-details-modal"
      :width="'1100px'"
      :z-index="5000"
      :top="0"
      @close="closeDialog"
    >
      <template v-slot:title>
        <div class="dialog-title">
          <svg-icon class="event-level-icon" :name="event.severityCode" />
          {{ event.eventName }}
        </div>
        <div
          v-if="
            isSingleAssetRouteDefined &&
            !isSingleAssetView &&
            operationalSupportMode
          "
          class="dialog-title single-asset-link"
        >
          <span>{{ $t('eventsWidget.gotoAssetPage') }}</span>
          <img
            class="link-icon"
            src="@/assets/imgs/maintenance/arrow_right.svg"
            @click="handleGotoSingleAssetView"
          />
        </div>
      </template>

      <div class="d-flex ai-center" style="flex-direction: column">
        <div class="event-info-top">
          <div
            class="product-model-image-container"
            v-if="!pictureUrlSource"
            v-loading="!pictureUrlSource"
          />
          <div
            class="product-model-image-container"
            v-if="productModel?.picturePath || pictureUrlSource"
          >
            <img class="product-model-image" :src="pictureUrlSource" />
          </div>

          <div class="event-info-top-informations">
            <div class="event-info-top-item">
              <div class="event-info-top-label">
                {{ $t('eventsWidget.assetId') }}:
              </div>
              <div class="font-weight-500 label-value">
                {{ event.companyAssetId }}
              </div>
            </div>

            <div class="event-info-top-item" v-if="operationalSupportMode">
              <div class="event-info-top-label">
                {{ $t('eventsWidget.gatewayId') }}:
              </div>
              <div class="font-weight-500 label-value">
                {{ asset?.gatewayId ?? '' }}
              </div>
            </div>

            <div class="event-info-top-item">
              <div class="event-info-top-label">
                {{ $t('eventsWidget.organization') }}:
              </div>
              <div class="font-weight-500 label-value">
                {{ event.organizationName }}
              </div>
            </div>

            <div class="event-info-top-item" v-if="addressLabel">
              <div class="event-info-top-label">
                {{ $t('eventsWidget.location') }}:
              </div>
              <div class="font-weight-500 label-value">
                {{ addressLabel }}
              </div>
            </div>

            <div class="event-info-top-item">
              <div class="event-info-top-label">
                {{
                  operationalSupportMode
                    ? $t('eventsWidget.timeStampUtc')
                    : $t('eventsWidget.timeStamp')
                }}:
              </div>
              <div
                v-if="operationalSupportMode"
                class="font-weight-500 label-value"
              >
                {{ event.timestamp | formatTimer('datetime', 'UTC') }}
              </div>
              <div v-else class="font-weight-500 label-value">
                {{ event.timestamp | formatTimer('datetime') }}
              </div>
            </div>
          </div>
          <div class="event-description">
            <div class="event-description-label">
              {{ $t('eventsWidget.description') }}:
            </div>
            <div class="font-weight-500">{{ event.eventDescription }}</div>

            <div class="uec-description" v-if="operationalSupportMode">
              <div class="uec-description-label">
                {{ $t('eventsWidget.uec') }}:
              </div>
              <div class="font-weight-500">
                {{ event.eventTypeCode?.replace(/^EVNT_/, '') }}
              </div>
            </div>
          </div>
        </div>

        <div class="map-container">
          <div class="map">
            <LeafletMap
              :center="center"
              :zoom="zoom"
              :assets="assets"
              :enableZoom="true"
            />
          </div>
        </div>

        <div
          class="event-info-bottom"
          v-if="event.diagnostic || event.potentialSolution"
        >
          <div class="event-info-bottom-item">
            <div class="font-weight-500 event-info-bottom-label">
              {{ $t('eventsWidget.diagnostic') }}:
            </div>
            <div class="event-info-bottom-value">{{ event.diagnostics }}</div>
          </div>

          <div class="event-info-bottom-item">
            <div class="font-weight-500 event-info-bottom-label">
              {{ $t('eventsWidget.potentialSolution') }}:
            </div>
            <div class="event-info-bottom-value">
              {{ event.potentialSolution }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.event-details-modal :deep(.el-dialog) {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin: 0;
}
.event-details-modal-container :deep(.quit-editing-modal-content) {
  text-align: center;
  word-break: break-word;
}

.event-details-modal-container :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.event-details-modal :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
  margin-left: 35%;
}

.event-details-modal :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}
</style>

<style lang="scss" scoped>
.event-details-modal :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  // background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
  border-bottom: 1px solid #dddddd;

  .dialog-title {
    display: flex;
    align-items: center;
    font-weight: bold;

    .event-level-icon {
      width: 28px !important;
      height: 28px !important;
      margin-right: 34px;
    }
  }
}

.event-details-modal :deep(.el-dialog__body) {
  padding: 0;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.event-details-modal :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.event-info-top {
  display: flex;
  width: 100%;
  font-weight: 400;
  line-height: 18.75px;
  font-size: 16px;
  border-bottom: 1px solid #dddddd;
  padding: 20px;
  padding-bottom: 0;

  .product-model-image-container {
    height: 128px;
    max-width: 220px;
    margin-right: 25px;

    .product-model-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &-informations {
    > div {
      margin-bottom: 17px;
    }
  }

  .event-info-top-item {
    display: flex;

    .event-info-top-label {
      width: 150px;
    }
  }

  .event-description {
    max-width: 359px;
    margin-left: 50px;
    margin-bottom: 17px;
    word-break: normal;

    &-label {
      margin-bottom: 12px;
      font-weight: 700;
    }
  }
}

.font-weight-500 {
  font-weight: 500;
}

.map-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .map-title {
    font-size: 18px;
    width: 100%;
    margin: 19px auto 14px 28px;
  }

  .map {
    height: 442px;
    max-height: 55vh;
  }
}

.event-info-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #dddddd;
  word-break: normal;

  &-item {
    width: 48%;
  }

  &-label {
    font-size: 18px;
    margin-bottom: 16.75px;
  }
}

.label-value {
  max-width: 250px;
  word-break: break-word;
}

.uec-description {
  margin-top: 20px;

  &-label {
    margin-bottom: 12px;
    font-weight: 700;
  }
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.single-asset-link {
  margin-left: auto;
  margin-right: 40px;
}
</style>
