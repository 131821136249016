import i18n from '@/lang';
import { TableColumn } from '@/utils/types/columnCustomizationTypes';
import { Option } from '@/utils/types/option';
import {
  EventCategoryType,
  UECEventType,
  UI_COLUMN_CODES,
} from '@/utils/workData/lookuptable';

export function mapEventCategoriesToOptions(
  events: EventCategoryType[]
): Option<EventCategoryType>[] {
  return events.map((event) => ({
    key: event,
    label: i18n.t(event),
  }));
}

export function mapUECEventsToOptions(
  uecEvents: UECEventType[]
): Option<UECEventType>[] {
  return uecEvents.map((event) => ({
    key: event,
    label: event,
  }));
}

export function getTableColumns(): TableColumn[] {
  return [
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_ICON, 'severityName'),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_ASSET, 'companyAssetId'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_ORGANIZATION,
      'organizationName'
    ),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP, 'timestamp'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE_CATEGORY,
      'eventTypeCategoryName'
    ),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_NAME, 'eventName'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_DESCRIPTION,
      'eventDescription'
    ),
  ];
}

export function getOperationalDashboardColumns(): TableColumn[] {
  return [
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_ASSET, 'companyAssetId'),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_GATEWAY_ID, 'gatewayId'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_ORGANIZATION,
      'organizationName'
    ),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP_UTC, 'timestamp'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE_CODE_UEC,
      'eventTypeCode'
    ),
    getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_NAME, 'eventName'),
    getColumn(
      UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_DESCRIPTION,
      'eventDescription'
    ),
  ];
}

function getColumn(code: string, propertyName: string): TableColumn {
  return {
    label: i18n.t(`operationLog.${code}`),
    code: code,
    prop: propertyName,
    required: true,
    visible: true,
    order: 0,
    kpiUnitCode: '',
    isKpiData: false,
  };
}
