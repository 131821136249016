<script lang="ts">
import {
  getOrganizationAssetsHierarchyLegacy,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { getKpisForMultipleAssets, MultiAssetKpiAssetLegacy } from '@/api/kpis';
import { getUserSearchHistory, saveUserSearchHistory } from '@/api/users';
import { Claims } from '@/auth/claims';
import { ActiveContext, useActiveContext } from '@/auth/context';
import {
  SelectedCustomerInfo,
  useSelectedCustomerInfo,
} from '@/auth/selectedCustomer';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import { Ref, unref, watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import SelectTree from './SelectTree.vue';

const components = {
  SelectTree,
};

@Component({
  name: 'NavBarSearch',
  components,
})
export default class extends Vue {
  static components: typeof components;

  assetKpis: MultiAssetKpiAssetLegacy[] = [];
  assetIds = [];
  assetList = [];
  options = [];
  selected = '';
  defaultProps = {
    parent: 'parentId', //  Parent unique identifier
    value: 'id', //  Uniquely identifies
    label: 'label', //  Label display
    children: 'children', //  Child
  };

  kpiPayload = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        fields: [
          {
            code: 'KPI.OperationalStatus',
          },
        ],
      },
    ],
  };

  historyOptions: {
    order: number;
    label: string;
  }[] = [];

  treeNode = {
    id: '',
    label: '',
    children: [],
  };

  selectedCustomerInfo!: SelectedCustomerInfo;
  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;

  async created() {
    this.selectedCustomerInfo = useSelectedCustomerInfo();
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();

    // Ugly, but watch context for changes (selected organization / customer)
    // and then reload the available list of assets. Needs to be changed to
    // computed, and only on-demand when popup is opened.
    watch(this.context, () => this.searchAssetsData());

    await this.searchAssetsData();
    await this.getHistoryData();
  }

  async getHistoryData() {
    try {
      const searchHistory = await getUserSearchHistory();
      if (searchHistory.code == 200) {
        this.historyOptions = searchHistory?.data
          ?.filter(
            (obj, index, array) =>
              array.findIndex((item) => item.name === obj.name) === index
          )
          .map((entry) => {
            return { order: entry.order, label: entry.name };
          });
        return;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async searchAssetsData() {
    if (
      this.loggedInUser.value?.mustImpersonate === true &&
      !this.selectedCustomerInfo.selectedCustomer.value?.customerId
    ) {
      // Skip fetching data if we need a selected customer, but don't have one yet.
      return;
    }

    // The search bar can be shown on ContextType.SelectedCustomer pages, and
    // LoggedInUser pages. But assets can only really be searched for on impersonated
    // customers. Instead of showing/hiding the search bar all the time, let's
    // just build a 'fake' selected customer context even on LoggedInUser pages instead.
    const context: ActiveContext = this.loggedInUser.value?.mustImpersonate
      ? {
          // TODO See if we can make this context more minimalistic
          impersonatedCompanyId:
            this.selectedCustomerInfo.selectedCustomer.value?.customerId,
          claims: new Claims([]),
          organization: undefined,
          organizationIds: [],
          primaryOrgTimeZone: '',
          isSubOrgSelection: false,
          selectedCompanyId: '',
        }
      : this.context.value;
    const response = await getOrganizationAssetsHierarchyLegacy(
      undefined,
      this.context.value.organization?.id,
      context
    );
    let ids = this.unpack(response);
    await this.getAssetKpiList(ids);
    let organisation = this.parseResponse(
      response.organization,
      {},
      this.assetKpis
    );
    /* @ts-expect-error TODO Unknown type */
    this.options = [organisation];
  }

  /* @ts-expect-error TODO Unknown type */
  async getAssetKpiList(ids) {
    if (!ids || ids.length == 0) {
      this.assetKpis = [];
      return;
    }

    this.kpiPayload.metadata.filter.assetIds = ids;
    try {
      const kpiResponse = await getKpisForMultipleAssets(
        this.kpiPayload,
        unref(this.context)
      );
      this.assetKpis = kpiResponse.code === 200 ? kpiResponse.data : [];
    } catch (err) {
      console.log(err);
      this.assetKpis = [];
    }
  }

  /* @ts-expect-error TODO Unknown type */
  unpack(o) {
    let ids =
      o.organization && o.organization.assets
        ? /* @ts-expect-error TODO Unknown type */
          o.organization.assets.map((a) => a.id)
        : [];
    if (o.organization && o.organization.suborganizations)
      ids = [
        ...ids,
        /* @ts-expect-error TODO Unknown type */
        ...o.organization.suborganizations.flatMap((c) => this.unpack(c)),
      ];
    return ids;
  }

  /* @ts-expect-error TODO Unknown type */
  async addToHistorySearch(label) {
    let lastSearch = {
      label: label,
      order: 1,
    };

    if (!this.historyOptions.some((obj) => obj.label === label)) {
      this.historyOptions.reverse().push(lastSearch);
      return;
    }

    if (this.historyOptions.length > 10) this.historyOptions.reverse().pop();

    for (let i in this.historyOptions) {
      this.historyOptions[i].order = parseInt(i, 10);
    }

    const res = await saveUserSearchHistory(
      this.historyOptions.map((entry) => {
        return { order: entry.order, name: entry.label };
      })
    );

    if (res.code !== 200) {
      throw new Error('Failed to save search history');
    }
  }

  parseResponse(
    node: OrganizationAssetsHierarchyResponseLegacy['organization'],
    /* @ts-expect-error TODO Unknown type */
    treeNode,
    /* @ts-expect-error TODO Unknown type */
    kpis
  ) {
    treeNode.parent = true;
    treeNode.id = node.id;
    treeNode.label = node.name;
    treeNode.children = [];

    for (const asset of node.assets ?? []) {
      var kpiStatus = '';
      try {
        kpiStatus =
          /* @ts-expect-error TODO Unknown type */
          kpis.find((o) => o.assetId === asset.id)?.values[0].v || '';
      } catch (e) {
        console.error(e);
      }

      let treeAsset = {
        status: kpiStatus,
        assetType: asset.assetType,
        id: asset.id,
        label: asset.companyAssetId,
        children: [],
      };
      if (treeAsset.status == null || treeAsset.status.length == 0)
        treeAsset.status = 'unavailable';
      /* @ts-expect-error TODO Unknown type */
      this.assetIds.push(treeAsset.id);
      treeNode.children.push(treeAsset);
    }

    for (let i in node.suborganizations) {
      let org = this.parseResponse(
        node.suborganizations[i].organization,
        {},
        kpis
      );
      treeNode.children.push(org);
    }

    return treeNode;
  }
}
</script>

<template>
  <div>
    <el-form @submit.native.prevent>
      <SelectTree
        v-model="selected"
        :options="options"
        :historyOptions="historyOptions"
        :props="defaultProps"
        placeholder=""
        width="400"
        @addToHistorySearch="addToHistorySearch"
      />
    </el-form>
  </div>
</template>
