import { AssetType } from '@/utils/assetTypes';
import { getRequest } from '@/utils/request';
import { AssetLifecycle, ConnectionStatus } from '@/utils/workData/lookuptable';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';
import { HyvaRegionCode } from './hyvaRegion';
import { ServiceRegionCode } from './serviceRegions';

export function getAccessibleAssets(
  data: any,
  organizationId: string
): CommonResultPromise<AccessibleAssetsResponse> {
  const request = getRequest();
  const response = request.request<AccessibleAssetsResponse>({
    url: `/accessible-assets/${organizationId}`,
    method: 'post',
    data: data,
  });
  return response;
}

export function addCustomerAssets(
  accessibleAssetIds: string[],
  organizationId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/set-accessible-assets/${organizationId}`,
    method: 'post',
    data: { accessibleAssetIds: accessibleAssetIds },
  });
  return response;
}

export function removeCustomerAssets(
  accessibleAssetIds: string[],
  organizationId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/accessible-assets/${organizationId}/remove`,
    method: 'put',
    data: { accessibleAssetIds: accessibleAssetIds },
  });
  return response;
}

export function getInaccessibleAssets(
  queryParams: any,
  organizationId: string
): CommonResultPromise<InaccessibleAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: `/inaccessible-assets/${organizationId}`,
    method: 'post',
    data: queryParams,
  });
  return response;
}

export function getAccessibleAssetsByFlexibleFiltering(
  requestData: any
): CommonResultPromise<AccessibleAssetsResponse> {
  const request = getRequest();
  const response = request.post<AccessibleAssetsResponse>(
    `/accessible-assets`,
    requestData
  );
  return response;
}

export interface InaccessibleAssetsResponse {
  assets: InaccessibleAsset[];
  total: number;
}

export interface InaccessibleAsset {
  accessOrganizations: UUID[];
  assetDataCollectionId: string;
  assetType: AssetType;
  assetStatus: AssetLifecycle;
  controllerId: string;
  companyAssetId?: string;
  id: UUID;
  productModelId: string;
  productModelNumber: string;
  registrationHandlerCompanyId: string;
  registrationHandlerCompanyName: string;
  registrationHandlerOrganizationId: string;
  registrationHandlerOrganizationName: string;
  registrationHandlerUserId: string;
  registrationHandlerUserName: string;
  serialNumber: string;
  serviceRegion: ServiceRegionCode;
  preliminaryAssetId?: string;
  timezone: string;
}

export interface AccessibleAssetsResponse {
  assets: AccessibleAssetsObjectModel[];
  total: number;
}

export interface AccessibleAssetsObjectModel extends InaccessibleAsset {
  assetStatus: AssetLifecycle;
  companyAssetId: string;
  gatewayId: string;
  companyId: string;
  companyName: string;
  organizationId: string;
  organizationName: string;
  productModelCode: string;
  productModelId: string;
  productModelNumber: string;
  lastCommunicationTime: string;
  accessTime: string;
  manufactureDate: string;
  installTime: string;
  connectionHandlerCompanyId: string;
  connectionHandlerCompanyName: string;
  connectionHandlerOrganizationId: string;
  connectionHandlerOrganizationName: string;
  connectionHandlerUserId: string;
  connectionHandlerUserName: string;
  connectionStatus: ConnectionStatus;
  vinNumber: string;
  preliminaryAssetId: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
}
