import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=38735a4f&scoped=true"
import script from "./Events.vue?vue&type=script&lang=ts"
export * from "./Events.vue?vue&type=script&lang=ts"
import style0 from "./Events.vue?vue&type=style&index=0&id=38735a4f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38735a4f",
  null
  
)

export default component.exports