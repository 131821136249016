<script lang="ts">
import { getOrgById } from '@/api/organizations';
import { getRoles, Role } from '@/api/roles';
import {
  Claim,
  copyUserTemplateFromCompany,
  createUser,
  deleteUser,
  getModules,
  getUserById,
  getUsers,
  updateActivationStatus,
  updateUser,
  UserListInformation,
} from '@/api/users';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { omit } from 'lodash';
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import UserDetailPage from './UserDetailPage.vue';
import { UserForm } from './UserForm';
import UserManagementInputForm from './UserManagementInputForm.vue';

interface SearchOptions {
  label: string;
  prop: string;
}

@Component({
  name: 'UserManagement',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
    'user-management-input-form': UserManagementInputForm,
    'user-detail-page': UserDetailPage,
    'deactivate-dialog': DeactivateDialog,
    'module-access': ModuleAccess,
  },
})
export default class extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() searchFieldOptions!: SearchOptions[];
  @Prop() cols!: string;
  @Prop() activeName!: string;
  @Prop() customerId!: string;
  @Prop() organizationId!: string;
  @Prop() selectedCompanyType!: string;

  /** Local variables */
  isUserManagementLoading: boolean = false;
  isUserModuleAccessLoading: boolean = false;
  userList: UserListInformation[] = [];
  totalUsers: number = 0;
  currentPage: number = 1;
  pageSize: number = UserModule.gridPageSize;
  userDetailFormIsVisible: boolean = false;
  userInputFormIsVisible: boolean = false;
  modalWindowIsVisible: boolean = false;
  inputFormIsInEditMode: boolean = false;
  deleteModalWindowIsVisible: boolean = false;
  dialogContent: string = '';
  userRoles: Role[] = [];
  authorizableResources = [];
  rolesSearchFieldName: string = 'companyType';
  loadingModuleAccess: boolean = false;
  activateOrDeactivate: string = '';
  userSearchParams: any = {
    reference: null,
    value: null,
  };
  userSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  userForm: UserForm = {
    id: '',
    email: '',
    role: '',
    claimsFtd: [],
    claims: [],
    organizationId: null,
    companyId: this.customerId,
    note: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    tenants: this.customerId,
    username: '',
    i18nCode: UserModule.i18nCode,
    emailVerified: false,
  };
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  activateOrDeactivateBtnIsVisible: boolean = false;
  userDetailsFormIsLoading: boolean = false;

  created() {
    this.fetchRoles();
    this.fetchModules();
    this.prepareGetUsers();
  }

  /** Prepare get users */
  prepareGetUsers() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.userSearchParams,
      this.userSortAndOrderData.sortBy,
      this.userSortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /**
   * Fetch user management data
   * @param finalUrl
   */
  async fetchUserManagementData(finalUrl: string) {
    try {
      this.isUserManagementLoading = true;
      await getUsers(finalUrl).then((res) => {
        if (res.code === 200) {
          let resData = res.data;
          resData.users.forEach((item: any) => {
            item.emailVerified = item.emailVerified
              ? 'userModule.yes'
              : 'userModule.no';
          });

          this.userList = resData.users.map((item) => ({
            ...item,
            activationStatus: this.$t(item.activationStatus),
          }));
          this.totalUsers = resData.total;
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isUserManagementLoading = false;
      this.sendTotalUsers();
    }
  }

  /**
   * Handle user input form display when btn click
   */
  handleUserInputFormDisplay() {
    this.userDetailFormIsVisible = false;
    this.userInputFormIsVisible = true;
  }

  fetchUserManagementBySearchParameters() {}

  /** Get users by page selection */
  fetchUsersDataByPageSelection(page: number) {
    this.currentPage = page;
    this.prepareGetUsers();
  }

  /** Get users by sorting event */
  fetchUsersDataBySortEvent(sortBy: string, order: string) {
    order != ''
      ? (this.userSortAndOrderData.sortBy = sortBy)
      : (this.userSortAndOrderData.sortBy = null);
    order != ''
      ? (this.userSortAndOrderData.order = order)
      : (this.userSortAndOrderData.order = null);
    this.prepareGetUsers();
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'createdDate'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'createdDate'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;
    let finalUrl = '';

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    finalUrl += `&companyId=${this.customerId}`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  /**
   * Handle flow for creating a new user
   */
  async handleRemoteApiRequest() {
    try {
      /** Take email as username otherwhise generate random string for mandatory username */
      this.userForm.username =
        this.userForm.email?.split('@')[0] ??
        (Math.random() + 1).toString(36).substring(7);
      this.isUserManagementLoading = true;

      /** Temporary */
      this.userForm.emailVerified = false;
      const newValues = omit(this.userForm, 'claims');

      if (!this.inputFormIsInEditMode) {
        await createUser(newValues).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.created') as string);
            this.errorInfos = [];
            this.copyUserTemplateFromCompany(res.data.id);
            return;
          }

          if (
            res.code === 400 &&
            res.data.errors[0].code === 'ApiErrorMaxUsersExceeded'
          ) {
            customFailedMessage(
              this.$t('userModule.maximumUsersExceeded') as string
            );
            this.errorInfos = res.data.errors;
            return;
          }

          if (
            res.code === 400 &&
            res.data.errors.indexOf('ApiErrorFieldDuplicate')
          ) {
            customFailedMessage(this.$t('userModule.duplicateEmail') as string);
            return;
          }

          promptFailedBox(this.$t('common.creation') as string);
          this.errorInfos = res.data.errors;
        });
        return;
      }

      await updateUser(this.userForm.id, newValues).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.updated') as string);
          this.errorInfos = [];
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isUserManagementLoading = false;
      this.toggleUserManagementForm();
    }
  }

  /**
   * Copy user template from company for a new created user
   * @param userId
   */
  async copyUserTemplateFromCompany(userId: string) {
    await copyUserTemplateFromCompany(userId).then((res) => {
      if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.templateNotExist') as string);
      }
    });
  }

  /**
   * Get roles
   */
  async fetchRoles() {
    try {
      let finalUrl = `?searchFieldName=${this.rolesSearchFieldName}&searchFieldValues=${this.selectedCompanyType}&size=100`;
      await getRoles(finalUrl).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.userRoles = res.data.roles;
          return;
        }

        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Handle email input
   */
  handleEmailInput() {
    this.errorInfos = [];
  }

  /**
   * Change role event handle
   * @param claims
   */
  changeRole(claims: Claim[]) {
    this.userForm.claimsFtd = claims.map((claim) => claim.resource);
  }

  /**
   * Fetch Access Modules
   */
  async fetchModules() {
    try {
      this.loadingModuleAccess = true;
      await getModules().then((res) => {
        if (res.code === 200) {
          this.authorizableResources = res.data;
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.loadingModuleAccess = false;
    }
  }

  /** Toggle user management form hide or display */
  toggleUserManagementForm() {
    this.userInputFormIsVisible = !this.userInputFormIsVisible;
    if (!this.userInputFormIsVisible) {
      this.prepareGetUsers();
      this.cleanupUserInputForm();
    }
  }

  /** Clean up user input form */
  cleanupUserInputForm() {
    this.userForm = {
      id: '',
      email: '',
      role: '',
      claimsFtd: [],
      claims: [],
      organizationId: null,
      companyId: this.customerId,
      note: '',
      activationStatus: ACTIVATION_STATUS.Activated,
      tenants: this.customerId,
      username: '',
      i18nCode: UserModule.i18nCode,
      emailVerified: false,
    };
    this.inputFormIsInEditMode = false;
  }

  /** Open detail view page for table item row click */
  async handleRowItemClick(userRowItem: any) {
    if (userRowItem.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.activateOrDeactivate = 'activate';
    } else {
      this.activateOrDeactivate = 'deactivate';
    }
    this.userForm = userRowItem;
    this.userDetailFormIsVisible = true;
    await this.handleGetUserModuleAccessData();
  }

  /** Show modal window for deactivate/activate an user*/
  showModalWindow() {
    if (this.userForm.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.userForm.username,
      }) as string;
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.userForm.username,
      }) as string;
    }
    this.modalWindowIsVisible = true;
  }

  /** Show modal window for removing user */
  showModalWindowForRemovingUser() {
    this.deleteModalWindowIsVisible = true;
  }

  /** Cancel activate/deactivate modal window */
  handleCancelDeactivateModal() {
    this.modalWindowIsVisible = false;
  }

  /** Edit current user from detail view page */
  editCurrentUser() {
    this.inputFormIsInEditMode = true;
    this.handleUserInputFormDisplay();
  }

  /** Cancel user input form */
  cancelUserManagementForm() {
    this.cleanupUserInputForm();
    this.userDetailFormIsVisible = false;
  }

  /** Remove user remotly */
  async handleRemoveUser() {
    try {
      this.deleteModalWindowIsVisible = false;
      this.isUserManagementLoading = true;
      var response = await deleteUser(this.userForm.id);
      if (response.code !== 200) {
        promptFailedBox(this.$t('common.removed') as string);
        return;
      }
      promptSuccessBox(this.$t('common.remove') as string);
    } catch (error) {
      console.log(error);
    } finally {
      this.prepareGetUsers();
      this.userDetailFormIsVisible = false;
      this.userInputFormIsVisible = false;
    }
  }

  /** Handle deactivate user */
  async handleDeactivateOrActivateUser() {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;
    try {
      this.modalWindowIsVisible = false;
      this.isUserManagementLoading = true;
      await updateActivationStatus(this.userForm.id, {
        activationStatus:
          this.activateOrDeactivate === 'deactivate'
            ? ACTIVATION_STATUS.Deactivated
            : ACTIVATION_STATUS.Activated,
      }).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(infoMsg);
          this.userForm.activationStatus = ACTIVATION_STATUS.Deactivated;
        } else if (res.code === 400) {
          promptFailedBox(infoMsg);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.userDetailFormIsVisible = false;
      this.userInputFormIsVisible = false;
      this.cleanupUserInputForm();
      this.prepareGetUsers();
    }
  }

  /** Close dialog related to remove user */
  closeDeleteDialog() {
    this.deleteModalWindowIsVisible = false;
  }

  /**
   * Send total users count to parent component for header statistics component
   */
  @Emit('send-total-users')
  sendTotalUsers() {
    return this.totalUsers;
  }

  handleSelectedAccessModules() {
    this.userForm.claimsFtd = this.userForm.claims.map(
      (claim) => claim.resource
    );
  }

  async handleGetUserModuleAccessData() {
    try {
      this.isUserModuleAccessLoading = true;
      this.userDetailsFormIsLoading = true;
      const result = await getUserById(this.userForm.id);
      this.getOrganizationById(result.data?.organizationId);
      this.userForm.claims = result.data.claims;
      this.handleSelectedAccessModules();
    } catch (error) {
      console.log(error);
    } finally {
      this.isUserModuleAccessLoading = false;
    }
  }

  /**
   * Fetch assigned org by id details for selected user
   * @param organizationId
   */
  async getOrganizationById(organizationId: string): Promise<void> {
    try {
      if (!organizationId) return;
      this.activateOrDeactivateBtnIsVisible =
        (await getOrgById(organizationId)).data.activationStatus ===
        ACTIVATION_STATUS.Activated
          ? true
          : false;
    } catch (error) {
      console.log(error);
    } finally {
      this.userDetailsFormIsLoading = false;
    }
  }
}
</script>

<template>
  <div
    v-loading="isUserManagementLoading"
    :element-loading-text="$t('userModule.loadingUserData')"
  >
    <div v-if="!userInputFormIsVisible && !userDetailFormIsVisible">
      <select-table-header
        style="margin: 20px 0"
        :searchFieldOptions="searchFieldOptions"
        :cols="cols"
        :searchParams="userSearchParams"
        @search-event="fetchUserManagementBySearchParameters"
      >
        <template #rightHeader>
          <el-button
            type="plain"
            @click="handleUserInputFormDisplay"
            style="margin-right: 5px"
          >
            <i class="el-icon-plus common-icon" />{{
              $t('userModule.addNewUser')
            }}
          </el-button>
        </template>
      </select-table-header>
      <el-row>
        <pure-table
          v-if="activeName === 'usersTab'"
          :tableList="userList"
          :total="totalUsers"
          :cols="cols"
          @handle-page="fetchUsersDataByPageSelection"
          @handle-sort-change="fetchUsersDataBySortEvent"
          @row-click="handleRowItemClick"
        />
      </el-row>
    </div>

    <div v-if="userDetailFormIsVisible" v-loading="userDetailsFormIsLoading">
      <div class="d-flex jc-start ai-center" style="margin: 20px 0">
        <div class="d-flex ai-center flex-g1"></div>
        <div class="d-flex ai-center">
          <el-button
            type="plain"
            @click="editCurrentUser"
            style="margin-right: 5px"
          >
            <i class="el-icon-plus common-icon" />{{ $t('common.edit') }}
          </el-button>
          <el-button
            type="plain"
            @click="showModalWindow"
            style="margin-right: 5px"
            v-if="activateOrDeactivateBtnIsVisible"
          >
            <div class="d-flex ai-center jc-center">
              <div>
                <img
                  style="margin-right: 5px"
                  src="@/assets/imgs/deactivate.svg"
                />
              </div>
              <div>
                {{
                  activateOrDeactivate === 'deactivate'
                    ? $t('common.deactivate')
                    : $t('common.activate')
                }}
              </div>
            </div>
          </el-button>
          <el-button
            type="plain"
            @click="showModalWindowForRemovingUser"
            style="margin-right: 5px"
          >
            <div class="d-flex ai-center jc-center">
              <div>
                <img
                  style="margin-right: 5px"
                  src="@/assets/imgs/deactivate.svg"
                />
              </div>
              <div>
                {{ $t('common.remove') }}
              </div>
            </div>
          </el-button>
          <el-button
            type="plain"
            @click="cancelUserManagementForm"
            style="margin-right: 5px"
          >
            <i class="el-icon-close common-icon" />{{ $t('common.cancelBtn') }}
          </el-button>
        </div>
      </div>
      <user-detail-page :userInfo="userForm" />

      <div class="cus-form-module">
        <div class="cus-form-module-header">
          <span class="show-title">{{ $t('userModule.moduleAccess') }}:</span>
          <span v-if="false" class="required-field">*</span>
        </div>
        <div>
          <div prop="claimsFtd">
            <module-access
              v-loading="isUserModuleAccessLoading"
              id="new_user_module_claim"
              class="user_module_claim"
              ref="userManagementModuleAccess"
              :authorizableResources="authorizableResources"
              :defaultChecked="userForm.claimsFtd"
              :defaultExpand="false"
              :isEdit="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="userInputFormIsVisible && !userDetailFormIsVisible">
      <user-management-input-form
        ref="userFormComponent"
        v-loading="loadingModuleAccess || isUserModuleAccessLoading"
        :userForm="userForm"
        :roles="userRoles"
        :authorizableResources="authorizableResources"
        :errorInfos="errorInfos"
        :organizationId="organizationId"
        :inputFormIsInEditMode="inputFormIsInEditMode"
        :activateOrDeactivate="activateOrDeactivate"
        @show-modal-window="showModalWindow"
        @cancel-user-form="toggleUserManagementForm"
        @handle-email-input="handleEmailInput"
        @change-role="changeRole"
        @handle-api-event="handleRemoteApiRequest"
        @remove-user-event="showModalWindowForRemovingUser"
      />
    </div>

    <deactivate-dialog
      :visiable.sync="modalWindowIsVisible"
      :activateOrDeactivate="activateOrDeactivate"
      :title="$t('userModule.user')"
      :content="dialogContent"
      @handle-cancel="handleCancelDeactivateModal"
      @handle-deactivate="handleDeactivateOrActivateUser"
    />

    <el-dialog
      :visible="deleteModalWindowIsVisible"
      class="new-cust-dialog"
      :width="'600px'"
      :top="'25vh'"
      :title="`${$t('userModule.removeUser')} ${userForm.username}`"
      @close="closeDeleteDialog"
    >
      <div class="d-flex ai-center" style="flex-direction: column">
        <div style="margin-top: 20px">
          <img src="@/assets/imgs/deactivate.svg" style="width: 48px" />
        </div>
        <div style="margin: 10px 30px 30px; font: 400 20px/36px Roboto">
          <span style="white-space: nowrap">{{
            $t('userModule.removeInfo', { name: userForm.username })
          }}</span>
        </div>
        <div style="margin: 10px 0px">
          <el-button
            class="deactivate-btn"
            style="margin-right: 30px"
            type="plain"
            @click="closeDeleteDialog"
            >{{ $t('customerModule.cancel') }}</el-button
          >
          <el-button
            class="deactivate-btn"
            type="info"
            @click="handleRemoveUser"
            >{{ $t('userModule.removeUser') }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.cus-form-module-header {
  margin-right: 0px;
}
</style>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}

.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}
</style>
