var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex jc-start ai-center"},[_c('div',{staticClass:"d-flex ai-center flex-g1"},[_c('div',{staticClass:"d-flex ai-center search-select-container",staticStyle:{"margin-right":"40px"}},[_c('el-select',{staticClass:"search-select-field",staticStyle:{"margin-right":"0px"},attrs:{"id":"common_table_search_reference","placeholder":_vm.$t('common.pleaseSelect')},on:{"change":_vm.handleSelectionChange},model:{value:(_vm.searchParams.reference),callback:function ($$v) {_vm.$set(_vm.searchParams, "reference", $$v)},expression:"searchParams.reference"}},_vm._l((_vm.searchFieldOptions),function(item,index){return _c('el-option',{key:index,attrs:{"id":'common_table_search_reference_' + index,"label":_vm.$t(item.label),"value":item.prop}})}),1),(
          !_vm.selectionWithDropDownSearchOptions.includes(_vm.searchParams.reference)
        )?_c('el-input',{staticClass:"search-input-content",attrs:{"id":"common_table_search_input_value","placeholder":_vm.$t('common.inputKeywordToSearch'),"clearable":""},on:{"input":_vm.handleInputEmpty},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.searchParams.value),callback:function ($$v) {_vm.$set(_vm.searchParams, "value", $$v)},expression:"searchParams.value"}},[_c('i',{staticClass:"el-icon-search",attrs:{"slot":"suffix"},on:{"click":_vm.handleSearch},slot:"suffix"})]):_vm._e(),(
          _vm.selectionWithDropDownSearchOptions.includes(
            _vm.searchParams.reference
          ) &&
          !_vm.isSearchFilterWithDateAndTimePicker.includes(
            _vm.searchParams.reference
          )
        )?_c('el-select',{staticClass:"custom-search-option-dropdown",staticStyle:{"margin-right":"0px"},attrs:{"id":"common_table_search_custom_input_value","placeholder":_vm.$t('commonTable.selectAnOption')},on:{"change":_vm.handleSelectionInputChange},model:{value:(_vm.searchParams.value),callback:function ($$v) {_vm.$set(_vm.searchParams, "value", $$v)},expression:"searchParams.value"}},_vm._l((_vm.customInputSelectionForSearching),function(item,index){return _c('el-option',{key:index,attrs:{"id":'common_table_search_input_value_' + index,"label":_vm.$t(item.value),"value":item.id}})}),1):_vm._e(),(
          _vm.selectionWithDropDownSearchOptions.includes(
            _vm.searchParams.reference
          ) &&
          _vm.isSearchFilterWithDateAndTimePicker.includes(_vm.searchParams.reference)
        )?_c('el-date-picker',{staticClass:"date-time-picker",attrs:{"id":"common_table_search_date_time_picker_value","type":"date","placeholder":_vm.searchParams.reference === _vm.customSelection.searchByInstallTime
            ? _vm.$t('assetMgmt.selectInstallTime')
            : _vm.$t('userModule.selectCreatedDate'),"picker-options":_vm.pickerOptions},on:{"change":_vm.handleSelectionInputChange},model:{value:(_vm.searchParams.value),callback:function ($$v) {_vm.$set(_vm.searchParams, "value", $$v)},expression:"searchParams.value"}}):_vm._e()],1),_vm._t("leftHeader")],2),(_vm.addRemoveAccess)?_c('div',[(_vm.$router.currentRoute.path.includes('/customer-management/view/'))?_c('el-button',{staticClass:"el-button el-button--plain el-button--medium",attrs:{"icon":"el-icon-remove-outline"},on:{"click":_vm.handleRemoveAssets}},[_vm._v(" "+_vm._s(_vm.$t('common.removeAccess'))+" ")]):_vm._e(),(_vm.$router.currentRoute.path.includes('/customer-management/view/'))?_c('el-button',{staticClass:"el-button el-button--plain el-button--medium",attrs:{"icon":"el-icon-plus"},on:{"click":_vm.handleAddAssets}},[_vm._v(" "+_vm._s(_vm.$t('common.addAccess'))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex ai-center"},[_vm._t("rightHeader"),(_vm.cols && _vm.isColumnSelectionVisible)?_c('div',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}],staticClass:"column-button"},[_c('div',{staticClass:"d-flex ai-center jc-center",attrs:{"id":"common_table_column_selection_btn"}},[_vm._m(0),_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.selectColumn'))+" ")])])]):_vm._e()],2),_c('el-popover',{ref:"popover",attrs:{"placement":"bottom","trigger":"click"}},_vm._l((_vm.cols),function(item,index){return _c('el-checkbox',{key:index,staticStyle:{"display":"block","color":"#373e41 !important"},attrs:{"id":'common_table_column_selection_reference' + index,"label":/* @ts-expect-error TODO Wrong type */
        _vm.$t(item.label),"disabled":/* @ts-expect-error TODO Wrong type */
        item.required},model:{value:(
        /* @ts-expect-error TODO Wrong type */
        item.visible
      ),callback:function ($$v) {_vm.$set(/* @ts-expect-error TODO Wrong type */
        item, "visible", $$v)},expression:"\n        /* @ts-expect-error TODO Wrong type */\n        item.visible\n      "}})}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{staticStyle:{"width":"14px","height":"14px","margin-right":"8px"},attrs:{"src":require("@/assets/imgs/customer-management/column.svg")}})])
}]

export { render, staticRenderFns }