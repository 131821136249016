export const ORG_COLS = [
  {
    label: 'organizationModule.organizationName',
    prop: 'name',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.subdivisionOf',
    prop: 'parentName',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.usersAmount',
    prop: 'usersCount',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.assetsAmount',
    prop: 'assetsCount',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.activationStatus',
    prop: 'translatedActivationStatus',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.note',
    prop: 'note',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.hyvaRegion',
    prop: 'translatedHyvaRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const ACCESSIBLE_SERVICE_REGION_PROPERTY_NAME: string =
  'translatedAccessibleServiceRegions';
export const ORG_MANAGEMENT_COLS = [
  {
    label: 'organizationModule.organizationName',
    prop: 'name',
    required: true,
    visible: true,
  },
  {
    label: 'organizationModule.subdivisionOf',
    prop: 'parentName', // get the parent name by parentId, initial BE paylaod
    required: true,
    visible: true,
  },
  {
    label: 'organizationModule.usersCount',
    prop: 'usersCount',
    required: false,
    visible: true,
  },
  {
    label: 'organizationModule.assetsCount',
    prop: 'assetsCount',
    required: false,
    visible: true,
  },
  {
    label: 'organizationModule.serviceRegions',
    prop: ACCESSIBLE_SERVICE_REGION_PROPERTY_NAME,
    required: false,
    visible: false,
  },
  {
    label: 'organizationModule.activationStatus',
    prop: 'translatedActivationStatus',
    required: false,
    visible: true,
    multiLang: true,
  },
  {
    label: 'organizationModule.postCode',
    prop: 'zipCode',
    required: false,
    visible: true,
  },
  {
    label: 'organizationModule.timezone',
    prop: 'timezone', // get the parent name by parentId, initial BE paylaod
    required: false,
    visible: false,
  },
  {
    label: 'organizationModule.note',
    prop: 'note',
    required: false,
    visible: true,
  },
];

export const ORG_MANAGEMENT_SEARCH_FIELDS = [
  {
    label: 'organizationModule.organizationName',
    prop: 'name',
  },
  // {
  //     label: 'organizationModule.subdivisionOf',
  //     prop: 'parentName'
  // },
  // {
  //     label: 'organizationModule.usersCount',
  //     prop: 'usersCount'
  // },
  // {
  //     label: 'organizationModule.assetsCount',
  //     prop: 'assetsCount'
  // },
  // {
  //     label: 'organizationModule.subOrganizationCount',
  //     prop: 'subOrganizationCount'
  // },
  // {
  //     label: 'organizationModule.postCode',
  //     prop: 'zipCode'
  // },
  // {
  //     label: 'organizationModule.timezone',
  //     prop: 'timezone'
  // },
  // {
  //     label: 'organizationModule.note',
  //     prop: 'note'
  // }
];
