import { render, staticRenderFns } from "./Trip.vue?vue&type=template&id=364d3c44&scoped=true"
import script from "./Trip.vue?vue&type=script&lang=ts"
export * from "./Trip.vue?vue&type=script&lang=ts"
import style0 from "./Trip.vue?vue&type=style&index=0&id=364d3c44&prod&lang=css&scoped=true"
import style1 from "./Trip.vue?vue&type=style&index=1&id=364d3c44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364d3c44",
  null
  
)

export default component.exports