<script lang="ts">
import { Event, getEvents } from '@/api/event';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import UtilTable from '@/components/table/UtilTable.vue';
import {
  Filter,
  FilterOperator,
  QueryParameter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { isDesignatedCompany } from '@/utils/companyService';
import { customFailedMessage } from '@/utils/prompt';
import {
  decreaseDays,
  getCurrentDay,
  getDateRangeFromStartAndEnd,
} from '@/utils/time';
import { TableColumn } from '@/utils/types/columnCustomizationTypes';
import { Option } from '@/utils/types/option';
import {
  companyTypeToEventCategories,
  UI_COLUMN_CODES,
} from '@/utils/workData/lookuptable';
import { OPERATION_LOG_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import EventsExpanded from '@/widgets/home/expanded/EventsExpanded.vue';
import { Ref, unref } from 'vue';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { mapEventCategoriesToOptions } from '../utils/events';

@Component({
  name: 'Events',
  components: {
    EventsExpanded,
    WidgetCard,
    UtilTable,
    EventDetailsModal,
  },
})
export default class extends Vue {
  @Inject() expanded!: boolean;

  @Prop({ default: false })
  operationalSupportMode!: boolean;

  cols: TableColumn[] = [];
  eventsIsLoading: boolean = false;
  tableCustomization = OPERATION_LOG_TABLE_CUSTOMIZATION;
  tableList: Event[] = [];
  isEventDetailsModalVisible: boolean = false;
  selectedEventId: string = '';
  assetRelatedData: RouteAssetItem | null = null;
  sort: { field: string; order: SorterOrder } | null = null;

  get isDetail() {
    return this.$route.name?.includes('Info') ?? false;
  }

  get rows() {
    if (!this.sort) {
      return this.tableList;
    }

    const { field, order } = this.sort;

    return [...this.tableList].sort((a, b) => {
      let result = String(a[field as keyof Event]).localeCompare(
        String(b[field as keyof Event])
      );

      if (order === SorterOrder.DESC) {
        result = -result;
      }

      return result;
    });
  }

  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;

  created() {
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();
  }

  mounted() {
    if (this.$route.name) {
      this.assetRelatedData = ROUTE_ASSET_MAP.get(this.$route.name) || null;
    }

    this.cols = [
      ...(this.operationalSupportMode
        ? []
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_ICON,
              'severityName'
            ),
          ]),
      ...(this.isDetail
        ? []
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_ASSET,
              'companyAssetId'
            ),
          ]),
      ...(this.isDetail || !this.operationalSupportMode
        ? []
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_GATEWAY_ID,
              'gatewayId'
            ),
          ]),
      ...(this.isDetail
        ? []
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_ORGANIZATION,
              'organizationName'
            ),
          ]),
      ...(this.operationalSupportMode
        ? [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP_UTC,
              'timestamp'
            ),
          ]
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP,
              'timestamp'
            ),
          ]),
      ...(this.operationalSupportMode
        ? [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE_CODE_UEC,
              'eventTypeCode'
            ),
          ]
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE,
              'eventTypeCategoryName'
            ),
          ]),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_NAME,
        'eventName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_DESCRIPTION,
        'eventDescription'
      ),
    ];

    this.getData();
  }

  async getData() {
    try {
      this.eventsIsLoading = true;
      const eventsResponse = await this.fetchEvents();
      this.tableList = eventsResponse.events;
    } catch (error) {
      customFailedMessage(this.$t('operationLog.dataFetchError') as string);
    } finally {
      this.eventsIsLoading = false;
    }
  }

  get eventTypeOptions(): Option[] {
    const loggedInUser = unref(this.loggedInUser);

    // Still loading...
    if (!loggedInUser) {
      return [];
    }

    return mapEventCategoriesToOptions(
      companyTypeToEventCategories[loggedInUser.companyType]
    );
  }

  private async fetchEvents() {
    const loggedInUser = unref(this.loggedInUser);
    const queryParameter: QueryParameter = {
      filters: this.getFilters(),
      sorters: [
        {
          field: 'timestamp',
          order: SorterOrder.DESC,
        },
      ],
      pagination: {
        page: 1,
        size: this.assetRelatedData ? 50 : 20,
      },
      timezone: isDesignatedCompany(loggedInUser!.companyType)
        ? unref(this.context)?.primaryOrgTimeZone
        : UserModule.timeZone,
    };
    return loggedInUser && isDesignatedCompany(loggedInUser.companyType)
      ? await getEvents(queryParameter, unref(this.context))
      : await getEvents(queryParameter);
  }

  getFilters(): Filter[] {
    const todayDate = getCurrentDay();
    const dateRangeCurrentDateMinusOneMonth = getDateRangeFromStartAndEnd(
      decreaseDays(todayDate, 30),
      todayDate
    );

    if (this.isDetail) {
      return [
        ...(!this.operationalSupportMode
          ? [
              {
                name: 'eventTypeCategoryCode',
                operator: FilterOperator.IN,
                value: this.eventTypeOptions.map((option) => option.key),
              },
            ]
          : [
              {
                name: 'eventTypeCategoryCode',
                operator: FilterOperator.EQUAL,
                value: ['EVNTYPECAT_UEC'],
              },
              {
                name: 'timestamp',
                operator: FilterOperator.BETWEEN_EXCLUSIVE,
                value: [
                  dateRangeCurrentDateMinusOneMonth.start,
                  dateRangeCurrentDateMinusOneMonth.endExclusive,
                ],
              },
            ]),
        {
          name: 'assetId',
          operator: FilterOperator.EQUAL,
          value: [this.$route.params.id],
        },
        {
          name: 'i18nCode',
          operator: FilterOperator.EQUAL,
          value: [UserModule.i18nCode],
        },
      ];
    }

    const orgIds = unref(this.context).organizationIds;
    return [
      ...(!orgIds
        ? []
        : [
            {
              name: 'organizationId',
              operator: FilterOperator.IN,
              value: orgIds,
            },
          ]),
      ...(!this.operationalSupportMode
        ? [
            {
              name: 'eventTypeCategoryCode',
              operator: FilterOperator.IN,
              value: this.eventTypeOptions.map((option) => option.key),
            },
          ]
        : [
            {
              name: 'eventTypeCategoryCode',
              operator: FilterOperator.EQUAL,
              value: ['EVNTYPECAT_UEC'],
            },
            {
              name: 'timestamp',
              operator: FilterOperator.BETWEEN_EXCLUSIVE,
              value: [
                dateRangeCurrentDateMinusOneMonth.start,
                dateRangeCurrentDateMinusOneMonth.endExclusive,
              ],
            },
          ]),
      ...(!this.assetRelatedData
        ? []
        : [
            {
              name: 'assetTypeCode',
              operator: FilterOperator.EQUAL,
              value: [this.assetRelatedData.assetTypeCode],
            },
          ]),
      ...(!UserModule.i18nCode
        ? []
        : [
            {
              name: 'i18nCode',
              operator: FilterOperator.EQUAL,
              value: [UserModule.i18nCode],
            },
          ]),
    ];
  }

  rowClicked(row: any) {
    this.selectedEventId = row.id;
    this.isEventDetailsModalVisible = true;
  }

  handleSort(field: string, order: SorterOrder) {
    this.sort = order ? { field, order } : null;
  }

  handleQuitEventDetailsDialog() {
    this.isEventDetailsModalVisible = false;
  }

  private getColumn(code: string, propertyName: string): TableColumn {
    return {
      label: this.$t(`operationLog.${code}`) as string,
      code: code,
      prop: propertyName,
      required: true,
      visible: true,
      order: 0,
      kpiUnitCode: '',
      isKpiData: false,
    };
  }
}
</script>

<template>
  <WidgetCard v-if="!expanded" :loading="eventsIsLoading" :expandable="true">
    <UtilTable
      class="events-table"
      :tableList="rows"
      :cols="cols"
      :customRendering="tableCustomization"
      :showPage="false"
      :showTableHeaderOptions="false"
      @row-click="rowClicked"
      @handle-sort-change="handleSort"
      :height="'100%'"
    />
    <EventDetailsModal
      v-if="isEventDetailsModalVisible"
      :visible.sync="isEventDetailsModalVisible"
      :eventId.sync="selectedEventId"
      :operationalSupportMode="operationalSupportMode"
      @quit-event-details-dialog="handleQuitEventDetailsDialog"
    />
  </WidgetCard>
  <EventsExpanded :operationalSupportMode="operationalSupportMode" v-else />
</template>

<style lang="scss" scoped>
.events-table {
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
</style>
