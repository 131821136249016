export const SUBSCRIPTION_MANAGEMENT_COLS = [
  {
    required: true, // !important used for expand/minimize column in v-for
    visible: true,
  },
  {
    label: 'subsMgmt.orderId',
    prop: 'orderId',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.cadId',
    prop: 'cadId',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.customer',
    prop: 'companyName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.startDate',
    prop: 'subscriptionStartDate',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.endDate',
    prop: 'subscriptionEndDate',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.renewedTime',
    prop: 'renewedTimes',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.daysToRenew',
    prop: 'daysToRenew',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.customerPackage',
    prop: 'customerSubscriptionPackage',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.assetPackage',
    prop: 'assetSubscriptionPackage',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
    width: 140,
  },
  {
    label: 'subsMgmt.subscriptionStatus',
    prop: 'subscriptionStatus',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
    width: 140,
  },
  {
    label: 'subsMgmt.actionColumn',
    prop: 'actions',
    required: true,
    visible: true,
    width: 200,
  },
];

export const SUBSCRIPTION_MANAGEMENT_ASSETS_COLS = [
  {
    label: 'subsMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.customerAssetId',
    prop: 'customerAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.productModelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.lastCommunicationTime',
    prop: 'lastCommunicationTime',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.installTime',
    prop: 'installTime',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.assetDataCollectionId',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.assetSerialNumber',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'common.serviceRegion',
    prop: 'serviceRegion',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.manufactureDate',
    prop: 'manufactureDate',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.actionColumn',
    prop: 'actions',
    required: true,
    visible: true,
    sortable: false,
  },
];
