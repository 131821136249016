<script lang="ts">
import { getAssetLocation, getAssetsWithLocation } from '@/api/assetLocation';
import { getAssetById } from '@/api/assets';
import { getCustomerById } from '@/api/customer';
import { getAllGeofencesByOrganisationId } from '@/api/geofence';
import { Geofence, GeofenceAsset } from '@/api/geofenceTypes';
import { getOrgByIdWithMultipleFiltering } from '@/api/organizations';
import {
  ActiveContext,
  flattenOrganizations,
  useActiveContext,
} from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { calculateAssetBoundsWithPadding } from '@/utils/bounds';
import { isCompanyTypeOf } from '@/utils/companyService';
import { DESIGNATED_COMPANY_TYPES } from '@/utils/workData/lookuptable';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import MapExpanded from '@/widgets/home/expanded/MapExpanded.vue';
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component({
  name: 'Map',
  components: {
    MapExpanded,
    WidgetCard,
    LeafletMap,
  },
})
export default class extends Vue {
  /** Local variables */
  center: number[] = [0, 0];
  zoom: number = 10;
  assets: Array<GeofenceAsset> = [];
  geofences: Geofence[] = [];
  loading: boolean = true;
  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  $refs!: {
    map: LeafletMap;
  };

  async mounted() {
    this.assets = await this.fetchAssets();
    this.geofences = await this.fetchGeofences();
    this.$refs.map?.initializeGeofence(this.geofences);

    if (this.assets.length === 1) {
      this.center = [
        this.assets[0].geodeticLatitude,
        this.assets[0].geodeticLongitude,
      ];
      this.zoom = 10;
    } else if (this.assets.length > 1) {
      const bounds = calculateAssetBoundsWithPadding(this.assets);
      if (bounds.isValid()) {
        this.$nextTick(() => {
          this.$refs.map?.setBounds(bounds);
        });
      }
    }

    this.loading = false;
  }

  private async fetchAssets(): Promise<GeofenceAsset[]> {
    /** Viewing a single selected asset */
    if (this.$route.params.id) {
      const assetId = this.$route.params.id;
      if (!unref(this.context)) return [];
      const { data } = await getAssetById(assetId, unref(this.context));
      const { data: assets } = await getAssetLocation(
        data,
        unref(this.context)
      );

      // AHMAPP-5116 discovery:
      // With the following line enabled the map automatically extends the bounds to include geofences.
      // With the following line disabled the map is max-zoomed in to the asset position.
      // This is different from MapExpanded.vue where geofences are not rendered, causing different behavior.
      // Note that simply enabling the following line would not solve the issue completely, because it will
      // still have the same incorrect behavior when there are no geofences.

      // this.center = [assets[0].geodeticLatitude, assets[0].geodeticLongitude];

      return assets;
    }

    // Viewing a fleet of assets, possible only of a specific asset type
    const assetRelatedData: RouteAssetItem | undefined = this.$route.name
      ? ROUTE_ASSET_MAP.get(this.$route.name) ?? undefined
      : undefined;
    const { data: assets } = await getAssetsWithLocation(
      assetRelatedData?.assetTypeCode,
      unref(this.context)
    );
    return assets;
  }

  /**
   * Fetch geofences
   */
  private async fetchGeofences(): Promise<Geofence[]> {
    let selectedCustomer = unref(this.context)?.impersonatedCompanyId;
    let orgIds = await this.getOrganizationsUUIDs(selectedCustomer!);
    let result = Array.of<Geofence>();

    if (!orgIds) return result;

    for (const organization of orgIds) {
      const { data: response } = await getAllGeofencesByOrganisationId(
        organization,
        unref(this.context)
      );
      // TODO-TYPING: Cast to any is needed because GeofenceResponse and Geofence don't overlap enough. Should be resolved.
      result = result.concat(response.geofences as any);
    }

    return result;
  }

  /**
   * Handle map widget resize
   */
  handleMapWidgetResize(): void {
    this.$refs.map.fixMapSize();
  }

  /**
   * Handle organization ids used on requesting geofences for:
   * - Company type helpdesk: return flatten org ids of the selected one
   * - Tipical customer: return org ids of the selected organization from active context
   * @param selectedCustomer
   */
  async getOrganizationsUUIDs(selectedCustomer: string): Promise<string[]> {
    if (!isCompanyTypeOf(DESIGNATED_COMPANY_TYPES)) {
      return unref(this.context).organizationIds!;
    }

    const customerById = await getCustomerById(selectedCustomer!);
    const primaryOrganizationResponse = await getOrgByIdWithMultipleFiltering(
      customerById?.data?.primaryOrganizationId,
      `?selectedCustomer=${selectedCustomer!}`
    );

    return flattenOrganizations(primaryOrganizationResponse.data).map(
      (org) => org.id
    );
  }
}
</script>

<template>
  <WidgetCard
    v-if="!expanded"
    :loading="loading"
    :expandable="true"
    @resize-event="handleMapWidgetResize"
  >
    <LeafletMap
      :center="center"
      :zoom="zoom"
      ref="map"
      :assets="assets"
      :enableZoom="false"
      :geofenceConfig="{ enableCreate: true, enableEdit: true }"
    />
  </WidgetCard>
  <MapExpanded v-else :geofences="geofences" />
</template>
