import { ALL_CLAIMS_CODES } from './lookuptable';

export const DASHBOARDCONF_COLS = [
  {
    label: 'dashboardConf.templateName',
    prop: 'name',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.custType',
    prop: 'translatedCustomerType',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.industry',
    prop: 'translatedIndustry',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  // {
  //     //isDefault field was returned by API
  //     label: 'dashboardConf.type',
  //     prop: 'isDefault',
  //     visible: true,
  //     multiLang: true,
  //     sortable: 'custom'
  // },
  {
    label: 'dashboardConf.type',
    prop: 'translatedType',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.action',
    prop: 'action',
    required: true,
    visible: true,
    sortable: false,
  },
];

export const DASHBOARDCONF_SEARCH_FIELDS = [
  {
    label: 'dashboardConf.templateName',
    prop: 'name',
  },
  // {
  //     label: 'dashboardConf.custType',
  //     prop: 'customerType'
  // },
  // // {
  // //     //isDefault field was returned by API
  // //     label: 'dashboardConf.type',
  // //     prop: 'isDefault',
  // //     visible: true,
  // //     multiLang: true,
  // //     sortable: 'custom'
  // // },
  // {
  //     label: 'dashboardConf.type',
  //     prop: 'type'
  // }
];

// Pages added to this list don't require any widget in order to be added to a dashboard template
export const PAGE_NO_WIDGET = [
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_HISTORY,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_PLANNER,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_SUBSCRIBED_REPORTS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_REPORT_TEMPLATES,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_RELEASE_MANAGEMENT,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_DEPLOYMENTS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_PENDING_UPDATES,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_DEPLOYMENT_HISTORY,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_TRIP_LIVE,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_HEALTH_FACTOR,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_CONNECTED_ASSETS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_ASSIGNED_ASSETS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALARM_CONFIGURATION,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_MY_PROFILE,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_ACCOUNT_SETTINGS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_VIEW_SETTINGS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_HELPCENTER,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_PROD_MDLS,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_COMPANY_MGMT,
];
