import { UserModule } from '@/store/modules/user';
import { LOCALDATE_FORMAT } from '@/utils/time';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import moment from 'moment';

const REPORT_TEMPLATE_ACTIONS = new Map<string, string>([
  ['edit', 'EDIT'],
  ['view', 'VIEW'],
  ['export', 'EXPORT'],
]);

const userClaimForReadingReportTemplate: ALL_CLAIMS_CODES =
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_REPORT_TEMPLATES;
const userClaimForUpdatingReportTemplate: ALL_CLAIMS_CODES =
  ALL_CLAIMS_CODES.AUTHRSC_ACTION_REPORT_TEMPLATES_UPDATE;

export function handleActionsForReportTemplateItem(
  userId: string | null
): string[] {
  return userId === null
    ? []
    : isReportOwner(userId) && canReadReport() && canUpdateReport()
    ? [getActionCode('view'), getActionCode('edit')]
    : isReportOwner(userId) && canReadReport() && !canUpdateReport()
    ? [getActionCode('view')]
    : isReportOwner(userId) && !canReadReport() && canUpdateReport()
    ? [getActionCode('edit')]
    : !isReportOwner(userId) && !canReadReport()
    ? []
    : !isReportOwner(userId) && canReadReport()
    ? [getActionCode('view')]
    : [];
}

export function handleDisabledItem(
  userId: string | null,
  numberOfActiveSubscriptions?: number
): boolean {
  return userId === null
    ? false
    : isReportOwner(userId) && !!numberOfActiveSubscriptions
    ? true
    : false;
}

/**
 * Check if logged in user is the owner of the item
 * @param userId
 */
export function isReportOwner(userId: string): boolean {
  return userId === UserModule.id;
}

/**
 * Check if logged user has claim to read a report template
 */
export function canReadReport(): boolean {
  return UserModule.claims.hasClaim(userClaimForReadingReportTemplate);
}

/**
 * Check if logged user has claim to update a report template
 */
export function canUpdateReport(): boolean {
  return UserModule.claims.hasClaim(userClaimForUpdatingReportTemplate);
}

/**
 * Retrieve action code name for report template item
 * @param actionName
 * @returns string as action code
 */
export function getActionCode(actionName: string): string {
  const actionCode = REPORT_TEMPLATE_ACTIONS.get(actionName)!;
  if (!actionCode) {
    throw new Error(`Action code for '${actionName}' has not been found.`);
  }

  return actionCode;
}

/**
 * Sort an array of arrays of strings by taking first key in the array and sort it ASC
 * @param data
 * @returns (string | null | undefined)[][]
 */
export function sortData(
  data: (string | null | undefined)[][] | undefined
): (string | null | undefined)[][] {
  return data!.sort(
    (a: (string | null | undefined)[], b: (string | null | undefined)[]) =>
      a[0]!.localeCompare(b[0]!)
  );
}

/**
 * Get an array with formatted days between an interval
 * e.g: for start: 2000-01-01, end: 2000-01-07 will  return ['2000-01-01', '2000-01-02', ..., '2000-01-07']
 */
export function getDaysBetweenInterval(
  startDate: string,
  endDate: string,
  dateFormat = LOCALDATE_FORMAT
): string[] {
  const getDateAsArray: (date: string) => moment.Moment = (date: string) => {
    return moment(date.split(/\D+/), dateFormat);
  };
  const diff: number =
    getDateAsArray(endDate).diff(getDateAsArray(startDate), 'days') + 1;
  const dates: string[] = [];
  for (let i = 0; i < diff; i++) {
    const nextDate: moment.Moment = getDateAsArray(startDate).add(i, 'day');
    dates.push(nextDate.format(dateFormat));
  }

  return dates;
}

/**
 * Calculate Power/Energy Efficency
 * @param payloadSumPerDate
 * @param powerConsumptionSumPerDate
 * @returns number | undefined
 */
export function calculatePowerEfficency(
  payloadSumPerDate: number,
  powerConsumptionSumPerDate: number
): number | undefined {
  return !isNaN(Number(payloadSumPerDate)) &&
    !isNaN(Number(powerConsumptionSumPerDate))
    ? Number(payloadSumPerDate / powerConsumptionSumPerDate)
    : undefined;
}

/**
 * Calculate Productivity
 * @param payloadSumPerDate
 * @param workingHoursSumPerDate
 * @returns number | undefined
 */
export function calculateProductivity(
  payloadSumPerDate: number,
  workingHoursSumPerDate: number
): number | undefined {
  return !isNaN(Number(payloadSumPerDate)) &&
    !isNaN(Number(workingHoursSumPerDate))
    ? Number(payloadSumPerDate / workingHoursSumPerDate)
    : undefined;
}
