export interface QueryParameter {
  filters?: Filter[] | null;
  sorters?: Sorter[] | null;
  pagination?: Pagination;
  timezone?: string | undefined;
}

export interface Filter {
  name: string;
  operator: FilterOperator;
  value: (string | string[])[] | string[] | undefined;
}

export enum FilterOperator {
  LIKE = 'LIKE',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  BETWEEN_INCLUSIVE = 'BETWEEN_INCLUSIVE',
  BETWEEN_EXCLUSIVE = 'BETWEEN_EXCLUSIVE',
  BETWEEN = 'BETWEEN',
  IN = 'IN',
}

export interface Sorter {
  field: string;
  order: SorterOrder;
}

export enum SorterOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  ASC_NULLS_LAST = 'ASC_NULLS_LAST',
  DESC_NULLS_LAST = 'DESC_NULLS_LAST',
}

export interface Pagination {
  page: number;
  size: number;
}

export function addFilter(
  queryParameter: QueryParameter,
  filter: Filter
): QueryParameter {
  return {
    ...queryParameter,
    filters: [...queryParameter.filters!, filter],
  };
}

export function setFilters(
  queryParameter: QueryParameter,
  filters: Filter | Filter[]
): QueryParameter {
  if (!Array.isArray(filters)) {
    filters = [filters];
  }
  return {
    ...queryParameter,
    filters,
  };
}

export function addSorter(
  queryParameter: QueryParameter,
  sorter: Sorter
): QueryParameter {
  return {
    ...queryParameter,
    sorters: [...queryParameter.sorters!, sorter],
  };
}

export function setSorters(
  queryParameter: QueryParameter,
  sorters: Sorter | Sorter[]
): QueryParameter {
  if (!Array.isArray(sorters)) {
    sorters = [sorters];
  }
  return {
    ...queryParameter,
    sorters,
  };
}

export function addPagination(
  queryParameter: QueryParameter,
  pagination: Pagination
): QueryParameter {
  return {
    ...queryParameter,
    pagination: pagination,
  };
}
