var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"header-actions"},[_c('div',{staticClass:"left-filter-inputs"},[_vm._t("default")],2),(_vm.isHeaderActionVisible)?_c('el-button',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}],staticClass:"select-columns-btn",attrs:{"type":"plain"}},[_c('div',{staticClass:"d-flex ai-center jc-center"},[_c('div',[_c('img',{staticStyle:{"width":"14px","height":"14px","margin-right":"8px"},attrs:{"src":require("@/assets/imgs/selectColumn.svg")}})]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.selectColumn'))+" ")])])]):_vm._e()],1),_c('el-table',{ref:"subsMgmt",attrs:{"data":_vm.list,"tree-props":_vm.treeProp,"row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px' },"expand-row-keys":_vm.expendRowKeys,"row-key":_vm.getRowKeys},on:{"row-click":_vm.handleRowClick,"sort-change":_vm.handleSortChange}},_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:`col_${index}`,attrs:{"prop":_vm.dragCols[index].prop,"min-width":col.width ? col.width : '140',"sortable":col.sortable,"type":index === 0 ? 'expand' : null},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex jc-between table-header"},[_c('span',{staticStyle:{"word-break":"keep-all"}},[_vm._v(_vm._s(_vm.$t(col.label)))])])]},proxy:true},(index === 0)?{key:"default",fn:function(parentSlotProps){return [(
            parentSlotProps.row.hasOwnProperty('details') &&
            Object.keys(parentSlotProps.row.details).length > 0
          )?_c('div',{staticClass:"expand-body"},[_c('div',{staticClass:"order-header-container"},[_c('div',{staticClass:"order-header-item"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.assetType'))+": "),_c('span',{staticClass:"order-header-item-value"},[_vm._v(_vm._s(_vm.$t(parentSlotProps.row.details.assetType)))])]),_c('div',{staticClass:"order-header-item"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.numberOfAssets'))+": "),_c('span',{staticClass:"order-header-item-value"},[_vm._v(_vm._s(_vm.$t(parentSlotProps.row.details.numberOfAssets)))])]),_c('div',{staticClass:"order-header-item"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.assignDate'))+": "),_c('span',{staticClass:"order-header-item-value"},[_vm._v(_vm._s(_vm.moment(parentSlotProps.row.assignDate).format('YYYY/MM/DD')))])]),_c('div',{staticClass:"order-header-item"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.industry'))+": "),_c('span',{staticClass:"order-header-item-value"},[_vm._v(_vm._s(_vm.$t(parentSlotProps.row.details.industry)))])])]),(
              parentSlotProps.row.details.hasOwnProperty('assets') &&
              parentSlotProps.row.details.assets.length > 0
            )?_c('div',{staticClass:"asset-inner-table"},[_c('el-table',{staticClass:"ineer-assets-table",staticStyle:{"width":"1800px"},attrs:{"data":parentSlotProps.row.details.assets,"row-key":"id","row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px', background: '#F0F0F0' },"header-cell-style":{ background: '#F0F0F0' }}},_vm._l((_vm.subscriptionManagementAssetCols),function(assetCol,assetIndex){return _c('el-table-column',{key:`asset_col_${assetIndex}`,attrs:{"prop":assetCol.prop,"min-width":col.width ? col.width : '100',"sortable":assetCol.sortable,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex jc-between table-header"},[_c('span',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.$t(assetCol.label)))])])]},proxy:true},(assetCol.prop === 'lastCommunicationTime')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.row.lastCommunicationTime ? _vm.moment(slotProps.row.lastCommunicationTime).format( 'YYYY/MM/DD HH:mm:ss' ) : '')+" ")]}}:(assetCol.prop === 'installTime')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.row.installTime ? _vm.moment(slotProps.row.installTime).format( 'YYYY/MM/DD HH:mm:ss' ) : '')+" ")]}}:(assetCol.prop === 'serviceRegion')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.$t(slotProps.row.serviceRegion))+" ")]}}:(assetCol.prop === 'manufactureDate')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.row.manufactureDate ? _vm.moment(slotProps.row.manufactureDate).format( 'YYYY/MM/DD HH:mm:ss' ) : '')+" ")]}}:(assetCol.prop === 'actions')?{key:"default",fn:function(slotProps){return [(
                      [
                        _vm.subsMgmtStatus.SubstatActive,
                        _vm.subsMgmtStatus.SubstatDue,
                        _vm.subsMgmtStatus.SubstatPendingRenew,
                      ].includes(slotProps.row.subscriptionStatus)
                    )?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['AUTHRSC_ACTION_SUB_TERMINATE']),expression:"['AUTHRSC_ACTION_SUB_TERMINATE']"}],staticClass:"terminate-btn action-btn",attrs:{"id":"terminate_subscription_button","type":"default","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleTerminateSingleAssetBtnClick(
                        parentSlotProps.row,
                        slotProps.row
                      )}}},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.terminateBtn'))+" ")]):_vm._e()]}}:null],null,true)})}),1)],1):_c('div',{staticClass:"no-data-on-expand"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.noAssetsDataAvailable'))+" ")])]):_c('div',{staticClass:"no-data-on-expand"},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.noDataOnExpand'))+" ")])]}}:(col.prop === 'orderId')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.orderId)}})]}}:(col.prop === 'cadId')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.cadId)}})]}}:(col.prop === 'companyName')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.companyName)}})]}}:(col.prop === 'organizationName')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.organizationName)}})]}}:(col.prop === 'customerSubscriptionPackage')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.customerSubscriptionPackage)}})]}}:(col.prop === 'assetSubscriptionPackage')?{key:"default",fn:function(slotProps){return [_c('span',{domProps:{"innerHTML":_vm._s(slotProps.row.assetSubscriptionPackage)}})]}}:(col.prop === 'subscriptionStatus')?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(slotProps.row.subscriptionStatus))+" ")])]}}:(col.prop === 'actions')?{key:"default",fn:function(slotProps){return [(
            !slotProps.row.isAutoRenewEnabled &&
            [
              _vm.subsMgmtStatus.SubstatActive,
              _vm.subsMgmtStatus.SubstatDue,
              _vm.subsMgmtStatus.SubstatPendingRenew,
            ].includes(slotProps.row.subscriptionStatus)
          )?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['AUTHRSC_ACTION_SUB_RENEW']),expression:"['AUTHRSC_ACTION_SUB_RENEW']"}],staticClass:"renew-btn action-btn",attrs:{"id":"renew_subscription_button","type":"default","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRenewBtnClick(slotProps.row)}}},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.renewBtn'))+" ")]):_vm._e(),(
            slotProps.row.isAutoRenewEnabled &&
            [
              _vm.subsMgmtStatus.SubstatActive,
              _vm.subsMgmtStatus.SubstatDue,
              _vm.subsMgmtStatus.SubstatPendingRenew,
            ].includes(slotProps.row.subscriptionStatus)
          )?_c('div',{staticClass:"auto-renew-flag"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.autoRenewFlag'))+" ")])]):_vm._e(),(
            !slotProps.row.isAutoRenewEnabled &&
            ![
              _vm.subsMgmtStatus.SubstatActive,
              _vm.subsMgmtStatus.SubstatDue,
              _vm.subsMgmtStatus.SubstatPendingRenew,
            ].includes(slotProps.row.subscriptionStatus)
          )?_c('div',{staticClass:"auto-renew-flag"},[_c('span')]):_vm._e(),(
            slotProps.row.subscriptionStatus !=
            _vm.subsMgmtStatus.SubstatTerminated
          )?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['AUTHRSC_ACTION_SUB_TERMINATE']),expression:"['AUTHRSC_ACTION_SUB_TERMINATE']"}],staticClass:"terminate-btn action-btn",attrs:{"id":"terminate_subscription_button","type":"default","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleTerminateBtnClick(slotProps.row)}}},[_vm._v(" "+_vm._s(_vm.$t('subsMgmt.terminateBtn'))+" ")]):_vm._e()]}}:null],null,true)})}),1),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(
      /* @ts-expect-error TODO Wrong type */
      parseInt(_vm.pageTotal) > parseInt(_vm.pageSize)
    ),expression:"\n      /* @ts-expect-error TODO Wrong type */\n      parseInt(pageTotal) > parseInt(pageSize)\n    "}],attrs:{"total":_vm.pageTotal,"page":_vm.listQuery.page,"limt":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limt":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getPageList}}),_c('el-popover',{ref:"popover",attrs:{"placement":"bottom","trigger":"click"}},_vm._l((_vm.cols.slice(1, 11)),function(item,index){return _c('el-checkbox',{key:index,staticStyle:{"display":"block","color":"#373e41 !important"},attrs:{"label":/* @ts-expect-error TODO Wrong type */
        _vm.$t(item.label),"disabled":/* @ts-expect-error TODO Wrong type */
        item.required},model:{value:(
        /* @ts-expect-error TODO Wrong type */
        item.visible
      ),callback:function ($$v) {_vm.$set(/* @ts-expect-error TODO Wrong type */
        item, "visible", $$v)},expression:"\n        /* @ts-expect-error TODO Wrong type */\n        item.visible\n      "}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }