import { KpiUnitValue } from '@/api/kpis';
import { KPI_UNIT } from '../units/unitDefinitions';
import { toUnitValue } from '../units/unitValue';

// table column dto
export interface UIColumn {
  name: string;
  code: string;
  isKpiData: boolean;
  dataFieldName: string;
  kpiUnitCode: string;
  isMandatory: boolean;
  isVisible: boolean;
  order: number;
}

// ui table dto
export interface UITable {
  code: string;
  columns: UIColumn[];
}

// interface used by table component
export interface TableColumn {
  label: string;
  code: string;
  prop: string;
  required: boolean;
  visible: boolean;
  order: number;
  kpiUnitCode: string;
  isKpiData: boolean; // TODO: See if we can automate the retrieval of this data in the future
}

export enum ColumnRenderType {
  Generic, // no special behavior
  Date, // format as date
  DateTime, // format as date-time
  DateTimeUtc, // format as date-time in UTC
  Component, // render as custom component
  Translatable, // translate contents
}

// informs UtilTable how to render a cell
export type ColumnRendering = {
  type: ColumnRenderType; // Type of rendering (see above)
  width?: number; // Column width, defaults to 180
  translatePrefix?: string; // Prefix will be added to the translation key if provided
  hideKpiUnit?: boolean; // Set to true to hide KPI value units
  sortable?: boolean; // Columns are sortable by default. Turn this flag false to change that
  overflow?: boolean; // Some columns shouldn't display the overflow popup
  sortField?: string; // If not provided, will use property name instead
  getValue?: (originalValue: any) => string | undefined; // Optional function to preprocess the value of the current cell. Value is rendered as empty cell if undefined is returned.
  getComponent?: () => any; // Required if RenderType is Component. Arrow function to import component, see workData/tableCustomization.ts
  getComponentProperties?: (row: any) => {}; // Function to map properties to custom components. If not provided, will map to default component property 'row'
};

/**
 * Generic kpi data field.
 * @obsolete Use KpiUnitValue instead.
 */
export interface KpiData {
  code: string;
  unit: KPI_UNIT;
  value: any;
}

/**
 * Convert old-style KpiData into KpiUnitValue.
 */
export function kpiDataToKpiUnitValue(kpi: KpiData): KpiUnitValue<number> {
  return { code: kpi.code, ...toUnitValue(parseFloat(kpi.value), kpi.unit) };
}

/**
 * Convert a KPI Unit Value back to a KpiData object.
 * Note: returns the value in the original input type, i.e. does not format
 * it into a string.
 *
 * You should not use this function in new code!
 */
export function kpiUnitValueToKpiData(kpi: KpiUnitValue<any>): KpiData {
  return {
    code: kpi.code,
    unit: kpi.unit,
    value: kpi.v,
  };
}
