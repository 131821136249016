<script lang="ts">
import {
  AccessibleAssetsObjectModel,
  getAccessibleAssets,
} from '@/api/accessibleAssets';
import {
  AssetOnboarding,
  getAssetOnBoardingByFilterParam,
} from '@/api/assetsMgmt';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  addFilter,
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import {
  ASSET_CONNECTION_STATUS_FIELD,
  ASSET_STATUS_FIELD,
  CREATED_ON,
} from '@/utils/constants';
import { customFailedMessage } from '@/utils/prompt';
import {
  BODY_BUILDER_DEALER_REGISTERED_ASSETS_SEARCH_OPTIONS,
  REGISTERED_ASSETS_COLS,
  REGISTERED_ASSETS_SEARCH_OPTIONS,
} from '@/utils/workData/assetsMgmt';
import {
  AssetLifecycle,
  COMPANY_TYPE,
  CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING,
  REGISTERED_ASSET_CONNECTION_STATUSES,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

interface SortOrder {
  sortBy: string | null;
  order: string | null;
}

interface SearchParam {
  reference: string | null;
  operator: string | null;
  value: string | null;
}

interface Column {
  label: string;
  prop: string;
  required?: boolean;
  visible?: boolean;
  sortable?: boolean;
  opeartor?: string;
}

interface TranslateColumns {
  translatedAssetType: string;
  translatedConnectionStatus: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
}

interface AccessibleAssetRowData
  extends AccessibleAssetsObjectModel,
    TranslateColumns {}

interface AssetOnboardingRowData extends AssetOnboarding, TranslateColumns {}

@Component({
  name: 'RegisteredAsset',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  assetSerial: string = '';
  registeredAssetsCols = REGISTERED_ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  registeredAssetsSearchFields: Column[] = REGISTERED_ASSETS_SEARCH_OPTIONS;
  tableList: AccessibleAssetRowData[] | AssetOnboardingRowData[] = [];
  totalRegisteredAssets: number = 0;
  isRegisteredAssetsTableLoading: boolean = false;
  tableElementPath: string = 'register/view';
  pageSize = UserModule.gridPageSize;
  currentPage: number = 1;
  useAccessibleAssets: boolean = false;
  shouldUseAccessibleAssets: boolean = false;
  companyTypesForAccessibleAssets: string[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];
  defaultFiltersForRegisteredTab: string[] = [
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered,
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnecting,
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnectingFailed,
  ];
  sortAndOrderData: SortOrder = {
    sortBy: null,
    order: null,
  };
  searchParams: SearchParam = {
    reference: null,
    operator: null,
    value: null,
  };
  defaultSortByReference: string = 'createdOn';
  defaultSortOrder: SorterOrder = SorterOrder.DESC;

  created() {
    this.$nextTick(() => {
      this.prepareDefaultInitialization();
    });
  }

  /**
   * Prepare default initialization for entering on Assets management > registered tab as default entry
   * - for hyvaadmin on old endpoint: GET /assetOnBoarding > prepare filter by connectionStatus: registered, connecting, connecting failed
   */
  prepareDefaultInitialization(): void {
    this.useAccessibleAssets = this.companyTypesForAccessibleAssets.includes(
      UserModule.companyType
    );
    if (this.useAccessibleAssets) {
      this.registeredAssetsSearchFields =
        BODY_BUILDER_DEALER_REGISTERED_ASSETS_SEARCH_OPTIONS;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Filter by sort event
   * @param sortBy
   * @param order
   */
  fetchRegisteredAssetsDataBySortEvent(sortBy: string, order: string): void {
    if (sortBy === 'serialNumber') sortBy = 'assetSerialNumber';

    this.sortAndOrderData.sortBy = order != '' ? sortBy : null;
    this.sortAndOrderData.order = order != '' ? order : null;

    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Handle paging event
   * @param page
   * @param pageSize
   */
  fetchRegisteredAssetsDataByPageSelection(
    page: number,
    pageSize: number
  ): void {
    this.currentPage = page;
    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Handle search event
   * @param col
   * @param order
   */
  handleSearching(col: string, order: string): void {
    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Generate request URL by multiple factors
   * @param pageNumber
   * @param pageSize
   * @param searchParams
   * @param sortBy
   * @param order
   * @param connectionStatus
   * @return string
   */
  generateRequestUrlWithParams(
    pageNumber: number,
    pageSize: number,
    searchParams: SearchParam,
    sortBy: string | null,
    order: string | null,
    connectionStatus?: string | null
  ): string {
    let finalUrl = '';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'installTime'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'installTime'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    finalUrl += connectionStatus
      ? `&${ASSET_CONNECTION_STATUS_FIELD}=${connectionStatus}`
      : `&${ASSET_CONNECTION_STATUS_FIELD}=${this.defaultFiltersForRegisteredTab}`;

    /** Do not add searchFieldName and searchFieldValue if the filtering is made on connectStatus reference */
    if (searchFieldName && searchFieldValue && !connectionStatus) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${searchFieldValue}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}_NULLS_LAST`;
    } else {
      finalUrl += `&sortBy=${this.defaultSortByReference}&order=${this.defaultSortOrder}`;
    }
    return finalUrl;
  }

  /**
   * Fetch registered assets accordingly with company type:
   * - for hyvaadmin: from asset on boarding
   * - for body builder: from accessible assets by org id
   * @param finalUrlParamsForSearch
   */
  async fetchRegisteredAssets(finalUrlParamsForSearch: string): Promise<void> {
    try {
      this.isRegisteredAssetsTableLoading = true;

      if (this.useAccessibleAssets) {
        await this.fetchAccessibleAssets();
      } else {
        await this.fetchInaccessibleAssets(finalUrlParamsForSearch);
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isRegisteredAssetsTableLoading = false;
    }
  }

  async fetchInaccessibleAssets(finalUrlParamsForSearch: string) {
    const response = await getAssetOnBoardingByFilterParam(
      finalUrlParamsForSearch
    );

    this.totalRegisteredAssets = response.data.total;

    this.tableList = response.data.assetsOnBoarding.map((asset) => ({
      ...asset,
      translatedAssetType: this.$t(asset.assetType),
      translatedConnectionStatus: this.$t(asset.connectionStatus),
      translatedServiceRegion: this.$t(asset.serviceRegion),
      translatedHyvaRegion: this.$t(asset.hyvaRegion),
    }));
  }

  async fetchAccessibleAssets() {
    const response = await getAccessibleAssets(
      this.prepareQueryParameterForAccessibleAssets(),
      UserModule.organizationId
    );

    this.totalRegisteredAssets = response.data.total;

    this.tableList = response.data.assets.map((asset) => ({
      ...asset,
      translatedAssetType: this.$t(asset.assetType),
      translatedConnectionStatus: this.$t(asset.assetStatus),
      translatedServiceRegion: this.$t(asset.serviceRegion),
      translatedHyvaRegion: this.$t(asset.hyvaRegion),
    }));
  }

  /**
   * Prepare query parameters for accessible assets
   */
  prepareQueryParameterForAccessibleAssets(): QueryParameter {
    const defaultFilterForRegisteredAsset: Filter = {
      name: ASSET_STATUS_FIELD,
      operator: FilterOperator.IN,
      value: [AssetLifecycle.Registered, AssetLifecycle.ConnectionFailed],
    };
    const sorterField: string =
      this.sortAndOrderData.sortBy === 'assetSerialNumber'
        ? 'serialNumber'
        : this.sortAndOrderData.sortBy ?? '';
    const sorter: Sorter =
      this.sortAndOrderData.sortBy != null
        ? {
            field: sorterField,
            order:
              this.sortAndOrderData.order === SorterOrder.ASC
                ? SorterOrder.ASC_NULLS_LAST
                : SorterOrder.DESC_NULLS_LAST,
          }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [defaultFilterForRegisteredAsset],
      sorters: [sorter],
      pagination: pagination,
    };

    if (this.searchParams.value != null) {
      /** filter option assetSerialNumber as we receive from response payload in request filter is mapped as serialNumber */
      const filterName: string =
        this.searchParams.reference === 'assetSerialNumber'
          ? 'serialNumber'
          : this.searchParams.reference ?? '';
      const operator: any = this.searchParams.operator
        ? this.searchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: filterName,
        operator: operator,
        value: [this.searchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }
}
</script>

<template>
  <div class="app-container">
    <select-table-header
      style="margin-bottom: 20px"
      :searchFieldOptions="registeredAssetsSearchFields"
      :cols="registeredAssetsCols"
      :searchParams="searchParams"
      @search-event="handleSearching"
    />
    <pure-table
      v-loading="isRegisteredAssetsTableLoading"
      :element-loading-text="$t('assetsModule.assetsAreLoading')"
      :tableList="tableList"
      :total="totalRegisteredAssets"
      :cols="registeredAssetsCols"
      :viewPagePath="tableElementPath"
      @handle-page="fetchRegisteredAssetsDataByPageSelection"
      @handle-sort-change="fetchRegisteredAssetsDataBySortEvent"
    />
  </div>
</template>

<style lang="scss" scoped>
.total_items {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  color: #373e41;
  line-height: 28px;
  opacity: 0.6;
  span {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    opacity: 0.8;
    vertical-align: bottom;
  }
}
</style>
