<script setup lang="ts">
import {
  getKpiData,
  getStatisticalKpiData,
  StatisticalData,
} from '@/api/assets';
import { UUID } from '@/api/common';
import { KpiDataField, KpiDataRequest, KpiDataValue } from '@/api/kpis';
import { useActiveContext } from '@/auth/context';
import { useLoggedInUser } from '@/auth/user';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import BarChart from '@/components/kpiCharts/BarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import KpiTargetTable, {
  KpiTargetTableData,
} from '@/components/table/KpiTargetTable.vue';
import { AsyncValue, catchAsync, useAsync } from '@/composables/async';
import { useUnitConversion } from '@/composables/conversion';
import { showNotificationOnError } from '@/composables/error';
import { useRoute } from '@/composables/router';
import { AssetType } from '@/utils/assetTypes';
import { isDesignatedCompany } from '@/utils/companyService';
import { toError } from '@/utils/error';
import { formatValue } from '@/utils/format';
import { DEFAULT_DATE_RANGE, isSameDateRange } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import { getStatisticalDataAfterDays } from '@/views/report/components/components/helpers/composeCsvReport';
import { computed, ref, unref, watchEffect } from 'vue';

interface AssetInfo {
  uuid: string;
  name: string;
}

type ChartData = string[][];

/**
 * Make sum of string array
 * Returns the value as a string
 */
function sum(array: string[], initialValue = '0') {
  return array
    .reduce(
      (acc, currentValue) => acc + Number(currentValue),
      Number(initialValue)
    )
    .toString();
}

const route = useRoute();
const context = useActiveContext();

const allAssetOptions = ref<Option[]>([]);

const dateRange = ref<DateRange>(DEFAULT_DATE_RANGE);

const fleetTargetsByAssetId = useAsync(
  computed(async (): Promise<KpiDataField[]> => {
    const filter: KpiDataRequest['metadata']['filter'] = {
      assetTypeCode: AssetType.TippingVehicle,
      organizationIds: unref(context).organizationIds,
    };

    const request: KpiDataRequest = {
      metadata: {
        filter,
        selection: {
          startDate: unref(dateRange).start,
          endDate: unref(dateRange).endExclusive,
          dataBucketDimension: 'DBDIM_ASSET',
        },
      },
      details: [
        {
          entity: 'ENTT_ASSET',
          fields: [
            {
              code: 'KPI.TippingPayload',
              unit: 'UNIT_METRIC_TONNE',
              needGrowthPercentage: false,
            },
            {
              code: 'KPI.TripCount',
              unit: 'UNIT_COUNT',
              needGrowthPercentage: false,
            },
          ],
        },
      ],
    };
    const response = await getKpiData(request, unref(context));

    return response.data.details[0]?.fields ?? [];
  })
);

const assetsInfo = computed((): AsyncValue<AssetInfo[]> => {
  const targets = unref(fleetTargetsByAssetId);
  if (targets.loading || targets.error) {
    return {
      loading: targets.loading,
      error: targets.error,
      data: undefined,
    };
  }
  if (targets.data === undefined) {
    return {
      loading: false,
      error: new Error('Invalid api response, data must be present.'),
      data: undefined,
    };
  }
  try {
    const data = targets.data[0].values.map((element: KpiDataValue) => {
      if (element.k == null || element.id == null) {
        throw new Error('Invalid api response, k and id must be present.');
      }

      return {
        uuid: element.k,
        name: element.id,
      };
    });

    return {
      loading: false,
      error: undefined,
      data,
    };
  } catch (e) {
    return {
      loading: false,
      error: toError(e),
      data: undefined,
    };
  }
});

const selectedAssetIDs = ref<UUID[]>([]);
/**
 * Show a notification whenever the assetsInfo is in an error state.
 */
showNotificationOnError(assetsInfo, 'common.errorWithFetchingData');

/**
 * multiAssetSelectList is instantiated here
 * if assetsInfo is still loading, or an error is returned, or the data is undefined, the multiAssetSelectList will be an empty list
 * otherwise multiAssetSelectList will contain all the elements from assetsInfo
 * each object from multiAssetSelectList will have a selected prop which represents the selected state of the object
 * if we are on single asset page, only the object with the corresponding asset id will have the selected prop true
 * if we are not on single asset page, all objects will have the selected prop true
 */
watchEffect(() => {
  if (unref(allAssetOptions).length !== 0) {
    return;
  }

  if (unref(assetsInfo).loading || !!unref(assetsInfo).error) {
    allAssetOptions.value = [];
    return;
  }

  const assetsInfoData = unref(assetsInfo).data;

  if (!assetsInfoData) {
    allAssetOptions.value = [];
    return;
  }

  allAssetOptions.value = assetsInfoData.map((asset) => ({
    key: asset.uuid,
    label: asset.name,
  }));

  if (unref(route).params.id) {
    selectedAssetIDs.value = [unref(route).params.id];
  } else {
    selectedAssetIDs.value = allAssetOptions.value.map((asset) => asset.key);
  }
});

function mapStatisticalData(
  statisticalData: StatisticalData,
  kpiDataValueList: KpiDataValue[] | undefined
): KpiDataValue[] {
  const staticalDataRows = getStatisticalDataAfterDays({
    ...statisticalData,
    fields: statisticalData.fields.filter(
      (field: KpiDataField) => field.code === 'KPI.TippingPayload'
    ),
  });
  if (!kpiDataValueList) {
    return staticalDataRows.map((sdr) => ({
      v: sum(sdr.v),
      ts: sdr.ts,
    }));
  } else {
    return kpiDataValueList.map((kpiDataValue) => {
      const staticalDataRow = staticalDataRows.find(
        (sdr) => sdr.ts === kpiDataValue.ts
      );
      if (staticalDataRow) {
        if (!kpiDataValue.v) {
          throw new Error('Invalid api response, v must be present.');
        }
        return {
          ...kpiDataValue,
          v: sum(staticalDataRow.v, kpiDataValue.v),
        };
      } else {
        return kpiDataValue;
      }
    });
  }
}

const loggedInUser = useLoggedInUser();
const { currentUserConvertUnitValue } = useUnitConversion();

const fleetTargetsChartDataRaw = useAsync(
  computed(async (): Promise<ChartData> => {
    const unit = KPI_UNIT.MetricTonne;
    let kpiDataValueList: KpiDataValue[];
    if (allAssetOptions.value.length === selectedAssetIDs.value.length) {
      const filter: KpiDataRequest['metadata']['filter'] =
        loggedInUser.value &&
        isDesignatedCompany(loggedInUser.value.companyType)
          ? {
              assetIds: unref(filteredTableData).data?.map((asset) => asset.id),
            }
          : {
              assetTypeCode: AssetType.TippingVehicle,
              organizationIds: unref(context).organizationIds,
            };

      const requestAllAssets: KpiDataRequest = {
        metadata: {
          filter,
          selection: {
            startDate: unref(dateRange).start,
            endDate: unref(dateRange).endExclusive,
            dataBucketDimension: 'DBDIM_TIME',
          },
        },
        details: [
          {
            entity: 'ENTT_ASSET',
            fields: [
              {
                code: 'KPI.TippingPayload',
                unit: unit,
                needGrowthPercentage: false,
              },
            ],
          },
        ],
      };
      const response = await getKpiData(requestAllAssets, unref(context));

      kpiDataValueList =
        response.data?.details[0]?.fields?.find(
          (kpiDataField) => kpiDataField.code === 'KPI.TippingPayload'
        )?.values ?? [];
    } else if (!unref(selectedAssetIDs).length) {
      kpiDataValueList = [];
    } else {
      const requestFilteredAssets: KpiDataRequest = {
        metadata: {
          filter: {
            assetIds: unref(selectedAssetIDs),
          },
          selection: {
            startDate: unref(dateRange).start,
            endDate: unref(dateRange).endExclusive,
            dataBucketDimension: 'DBDIM_TIME',
          },
        },
        details: [
          {
            entity: 'ENTT_ASSET',
            fields: [
              {
                code: 'KPI.TippingPayload',
                unit: unit,
                needGrowthPercentage: false,
              },
            ],
          },
        ],
      };
      const statisticalKpiDataResponse = await getStatisticalKpiData(
        requestFilteredAssets,
        unref(context)
      );

      statisticalKpiDataResponse.data.kpiData.forEach((statisticalData) => {
        kpiDataValueList = mapStatisticalData(
          statisticalData,
          kpiDataValueList
        );
      });
      kpiDataValueList ??= [];
    }

    return kpiDataValueList
      .map((item) => ({
        ...item,
        v: currentUserConvertUnitValue(toUnitValue(parseFloat(item.v), unit)).v,
      }))
      .map((el) => [
        `${el.ts}`,
        formatValue({ v: el.v }, { numberOfDecimals: 2 }),
      ]);
  })
);

/**
 * Show a notification whenever the fleetTargetsChartData is in an error state.
 */
showNotificationOnError(
  fleetTargetsChartDataRaw,
  'common.errorWithFetchingData'
);

const fleetTargetsChartData = catchAsync(fleetTargetsChartDataRaw, (error) => ({
  error,
  data: [],
  loading: false,
}));

const filteredTableData = computed((): AsyncValue<KpiTargetTableData[]> => {
  const kpiDataFieldList = unref(fleetTargetsByAssetId).data ?? [];

  const selectedAssetsAssetInfoList =
    unref(assetsInfo).data?.filter((asset) =>
      unref(selectedAssetIDs).some((sel) => sel === asset.uuid)
    ) ?? [];
  try {
    const assetTargetDataList = selectedAssetsAssetInfoList.map((asset) => {
      const tippingPayload = kpiDataFieldList.find(
        (dataField: KpiDataField) =>
          dataField.code === KPI_FIELDS.TippingPayload
      );

      if (tippingPayload === undefined) {
        throw new Error('Tipping Payload not found');
      }

      const tippingPayloadValue = tippingPayload?.values.find(
        (value: KpiDataValue) => value.k === asset.uuid
      );

      const trip = kpiDataFieldList
        .find(
          (dataField: KpiDataField) => dataField.code === KPI_FIELDS.TripCount
        )
        ?.values.find((value: KpiDataValue) => value.k === asset.uuid);

      if (
        !tippingPayloadValue ||
        tippingPayloadValue.v == null ||
        !trip ||
        trip.v == null
      ) {
        throw new Error('Invalid api response, v must be present.');
      }

      const convertedPayload = currentUserConvertUnitValue(
        toUnitValue(parseFloat(tippingPayloadValue.v), tippingPayload.unit)
      );

      const payloadPerTrip = convertedPayload.v / parseFloat(trip?.v);

      return {
        id: asset.uuid,
        assetId: asset.name,
        payload: convertedPayload.v,
        trip: parseFloat(trip?.v),
        payloadPerTrip: isNaN(payloadPerTrip) ? -Infinity : payloadPerTrip,
      };
    });

    return { loading: false, error: undefined, data: assetTargetDataList };
  } catch (e) {
    return {
      loading: false,
      error: toError(e),
      data: [],
    };
  }
});

/**
 * Show a notification whenever the filteredTableData is in an error state.
 */
showNotificationOnError(filteredTableData, 'common.errorWithFetchingData');

/**
 * Handle Time Filter
 * @param dateRange
 */
function handleTimeFilter(range: DateRange) {
  // TimeSelect always emits the first range, even though it's same as our default
  if (!isSameDateRange(range, unref(dateRange))) {
    dateRange.value = range;
  }
}

function onAssetSelection(selectedOptions: string[]) {
  selectedAssetIDs.value = selectedOptions;
}
</script>

<template>
  <WidgetCard>
    <template #actions>
      <MultiSelectDropDown
        v-loading="assetsInfo.loading"
        :filter-label="$t('selectAssets')"
        :options="allAssetOptions"
        :selected-options="selectedAssetIDs"
        @change="onAssetSelection"
      />
      <TimeSelect
        :expanded="true"
        @select="handleTimeFilter"
        :customizable="true"
      />
    </template>
    <div class="body-wrapper">
      <div class="card-container">
        <div class="card-item">
          <BarChart
            v-if="
              fleetTargetsChartData.loading ||
              !!fleetTargetsChartData.data?.length
            "
            v-loading="fleetTargetsChartData.loading"
            :chartData="fleetTargetsChartData.data"
            height="100%"
            :itemColor="'#589E65'"
            :yAxisUnit="$t('metricUnits.ton')"
          />
          <div class="no-table-data-block" v-else>
            <p class="no-table-data-message">{{ $t('common.noData') }}</p>
          </div>
        </div>
        <div class="card-item">
          <KpiTargetTable
            v-loading="fleetTargetsByAssetId.loading"
            :element-loading-text="$t('common.loading')"
            :tableData="filteredTableData.data ?? []"
          />
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.body-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

@media (min-width: 1422px) {
  .card-item:first-child {
    width: 60%;
  }

  .card-item:last-child {
    width: 40%;
  }
}

@media (max-width: 1422px) {
  .card-item {
    width: 100%;
  }

  .card-item:first-child {
    height: 560px;
  }

  .card-item:last-child {
    height: 50%;
  }
}

.no-table-data-block {
  display: flex;
  margin-top: 20px;

  .no-table-data-message {
    margin: auto;
  }
}
</style>
