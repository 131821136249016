import {
  OrganizationAssetsHierarchyAsset,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import {
  AssetTripEventsSummary,
  GetTripsWithEventsSummary2HoursDetails,
  SafetyEventType,
  TripsWithEventsSummary2HoursDetails,
  TripsWithEventsSummaryByAsset,
  TripsWithEventsSummaryByTime,
} from '@/api/trip';
import i18n from '@/lang';
import {
  ASSET_SAFETY_CODE,
  NEW_FLEET_SAFETY_CODE,
} from '@/router/links/safetyWidget';
import { formatUnitValue } from '@/utils/format';
import { max, min, sum } from '@/utils/math';
import { capitalizeFirstLetter } from '@/utils/string';
import {
  addHoursForTimePattern,
  ISO8601_LOCAL_DATETIME_PATTERN,
  TIME_HOUR_MINUTES_PATTERN,
} from '@/utils/time';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import moment from 'moment';
import { AggregationType } from './Constants';

export interface StackedChartSize {
  width: string;
  height: string;
}

export type TripEventSummaries =
  | {
      aggregationType: AggregationType.AggregateByTime;
      summaries: TripsWithEventsSummaryByTime[];
    }
  | {
      aggregationType: AggregationType.AggregateByAsset;
      summaries: TripsWithEventsSummaryByAsset[];
    };

/* ---------- Trip Summary selection ---------- */

export type DateSelection = {
  selectedDate: string;
};

export type AssetSelection = {
  selectedAsset: string;
};

export type TripsSummariesChartSelection = DateSelection | AssetSelection;

export function isDateSelection(
  item: TripsSummariesChartSelection | undefined
): item is DateSelection {
  return item ? 'selectedDate' in item : false;
}

export function isAssetSelection(
  item: TripsSummariesChartSelection | undefined
): item is AssetSelection {
  return item ? 'selectedAsset' in item : false;
}

/* ---------- End of Trip Summary selection ---------- */

/**
 * Get label translated 'Trips with <event>'
 * @param event
 */
export function getLabelWithEvent(event: SafetyEventType): string {
  return `${i18n.t('newFleetSafety.tripsWithEvent', { event: i18n.t(event) })}`;
}

/**
 * Get label translated '<event> percentage (%)'
 * @param event
 */
export function getEventPercentageLabel(
  event: SafetyEventType,
  unitCode: string
): string {
  return `${i18n.t('newFleetSafety.eventPercentage', {
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: i18n.t(unitCode),
  })}`;
}

/**
 * Get label translated '<event> (<unitCode>)'
 * @param event
 */
export function getSeverityEventLabelLabel(
  event: SafetyEventType | string,
  unitCode: KPI_UNIT
): string {
  return `${i18n.t('newFleetSafety.eventWithUnitCode', {
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: i18n.t(unitCode),
  })}`;
}

/**
 * Get label translated '<event> (%)'
 * @param event
 */
export function getPercentageLabel(event: SafetyEventType): string {
  return `${capitalizeFirstLetter(i18n.tc(event))} (%)`;
}

/**
 * Get label translated 'Trips without <event>'
 * @param event
 */
export function getLabelWithoutEvent(eventType: SafetyEventType): string {
  return `${i18n.t('newFleetSafety.tripsWithoutEvent', {
    event: i18n.t(eventType),
  })}`;
}

/**
 * Get label translated '<statisticRef> <event> %'
 * i.e: 'Max. overload %'
 * @param event
 */
export function getStatisticLabelTranslated(
  statisticType: 'min' | 'max' | 'avg',
  event: SafetyEventType,
  unitCode: string
): string {
  return `${i18n.t('newFleetSafety.statisticRefPercentage', {
    statisticRef: i18n.tc(`common.${statisticType}`) + '.',
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: `(${i18n.tc(unitCode)})`,
  })}`;
}

/**
 * Get same received UTC offset then format it to a pattern
 * @param dateToFormat
 * @param formatPattern if undefined then default ISO8601_LOCAL_DATETIME_PATTERN
 */
export function getFormattedSafetyDate(
  dateToFormat: string,
  formatPattern: string = ISO8601_LOCAL_DATETIME_PATTERN
): string {
  return moment(dateToFormat).utc().format(formatPattern);
}

/* --------------------- CUSTOM TOOLTIP --------------------- */

export const CUSTOM_TOOLTIP_FONT_SIZE: number = 14;
export const CUSTOM_TOOLTIP_FONT_FAMILY: string = 'Roboto';
export const CUSTOM_TOOLTIP_PADDING: number = 4;
export const CUSTOM_TOOLTIP_FONT_WEIGHT: number = 700;
export const CUSTOM_TOOLTIP_MIN_PADDING: number = 20;
export const CUSTOM_TOOLTIP_HEADER_TEXT_COLOR: string = '#cbcccd';

/**
 * Retrieve longest string by length
 */
export function getLongestLabelByLength(arrayElements: string[]): string {
  return arrayElements.reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  }, '');
}

/**
 * Calculate min row width in pixels of an content text determined by:
 * - font size
 * - font family
 */
export function getMinRowWidthInPixels(
  textContent: string,
  fontSize: number,
  fontFamily: string,
  minPadding: number = 10
): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = `${fontSize}px ${fontFamily}`;
  const textWidth = context!.measureText(textContent).width;

  return textWidth + minPadding;
}

/**
 * Get small event severity tooltip html content that will be used by echart options to render the component
 */
export function getSmallEventSeverityTooltipContent(
  tooltipTitle: string,
  eventItem: AssetTripEventsSummary,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const tripStartTimeLabel: string = i18n.tc('newFleetSafety.tripStartTime');
  const tripEndTimeLabel: string = i18n.tc('newFleetSafety.tripEndTime');
  const safetyEventLabel: string = capitalizeFirstLetter(i18n.tc(safetyEvent));

  const longestLabel: string = getLongestLabelByLength([
    tripStartTimeLabel,
    tripEndTimeLabel,
    safetyEventLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${tooltipTitle}
      </div>
    `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${tripStartTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem.tripStartTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${tripEndTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem.tripEndTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${safetyEventLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.averageSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get large event severity tooltip html content that will be used by echart options to render the component
 */
export function getLargeEventSeverityTooltipContent(
  tooltipTitle: string,
  eventItem: AssetTripEventsSummary,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const tripStartTimeLabel: string = i18n.tc('newFleetSafety.tripStartTime');
  const tripEndTimeLabel: string = i18n.tc('newFleetSafety.tripEndTime');
  const numberOfTypeEventsLabel: string = i18n
    .t('newFleetSafety.noOfTypeEvents', {
      event: i18n.tc(safetyEvent),
    })
    .toString();
  const statisticAvgLabel: string = getStatisticLabelTranslated(
    'avg',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMinLabel: string = getStatisticLabelTranslated(
    'min',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMaxLabel: string = getStatisticLabelTranslated(
    'max',
    safetyEvent,
    safetyUnitCode
  );

  const longestLabel: string = getLongestLabelByLength([
    tripStartTimeLabel,
    tripEndTimeLabel,
    numberOfTypeEventsLabel,
    statisticAvgLabel,
    statisticMinLabel,
    statisticMaxLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${tooltipTitle}
      </div>`;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${tripStartTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem?.tripStartTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${tripEndTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem?.tripEndTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${numberOfTypeEventsLabel}:
        </div>
        <div style="text-align: left;">
          ${eventItem.numberOfEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${statisticAvgLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.averageSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
        ${statisticMinLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.minimumSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
        ${statisticMaxLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.maximumSeverityLevel,
            safetyUnitCode
          )}
        </div>
      <div/>
    <div/>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the component aggregated by time
 */
export function getExpandedFleetSafetyTooltipAggregatedByTime(
  eventItem: TripsWithEventsSummaryByTime,
  safetyEvent: SafetyEventType
): string {
  const labelWithEvent: string = getLabelWithEvent(safetyEvent);
  const labelWithoutEvent: string = getLabelWithoutEvent(safetyEvent);
  const ratio: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    labelWithEvent,
    labelWithoutEvent,
    ratio,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${eventItem.bucketStartDate}
      </div>
    `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithEvent}:
        </div>
        <div style="text-align: left;">
          ${eventItem.tripsWithEventsSummary.numberOfTripsWithEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithoutEvent}:
        </div>
        <div style="text-align: left;">
          ${
            eventItem.tripsWithEventsSummary.numberOfTotalTrips -
            eventItem.tripsWithEventsSummary.numberOfTripsWithEvents
          }
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${ratio}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the tooltip component when aggregated by asset
 */
export function getExpandedFleetSafetyTooltipAggregatedByAsset(
  eventItem: TripsWithEventsSummaryByAsset,
  safetyEvent: SafetyEventType,
  redirectToSingleAssetIsVisible: boolean,
  singleAssetHref: string | undefined
): string {
  const labelWithEvent: string = getLabelWithEvent(safetyEvent);
  const labelWithoutEvent: string = getLabelWithoutEvent(safetyEvent);
  const ratio: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    labelWithEvent,
    labelWithoutEvent,
    ratio,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;
  const customArrowTopMargin: number = -4;

  let content = `<div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
    <div style="display: flex; justify-content: space-between; border-bottom: solid 1px white; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
      <div style="color: white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR};">${
    eventItem.companyAssetId
  }</div>
      ${
        redirectToSingleAssetIsVisible && singleAssetHref != undefined
          ? `
      <a style="text-decoration: none;" href="${singleAssetHref}">
        <div
          class="circle"
          style="
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1000"
        >
          <i
          style="
            color: black;
            margin-top: ${customArrowTopMargin}px;"
          >
            &#8680;
          </i>
        </div>
      </a>`
          : ``
      }
    </div>
  `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithEvent}:
        </div>
        <div style="text-align: left;">
          ${eventItem.tripsWithEventsSummary.numberOfTripsWithEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithoutEvent}:
        </div>
        <div style="text-align: left;">
          ${
            eventItem.tripsWithEventsSummary.numberOfTotalTrips -
            eventItem.tripsWithEventsSummary.numberOfTripsWithEvents
          }
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${ratio}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the right side chart tooltip
 */
export function getExpandedFleetSafetyHorizontalChartTooltip(
  bucketObj: TripsWithEventsSummary2HoursDetails,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const statisticAvgLabel: string = getStatisticLabelTranslated(
    'avg',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMinLabel: string = getStatisticLabelTranslated(
    'min',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMaxLabel: string = getStatisticLabelTranslated(
    'max',
    safetyEvent,
    safetyUnitCode
  );
  const ratioLabel: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    statisticAvgLabel,
    statisticMinLabel,
    statisticMaxLabel,
    ratioLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
  <div class="tooltip-element" style="font-size: 14px; font-family: Roboto">
    <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
      ${bucketObj.bucketLabel} - ${addHoursForTimePattern(
    bucketObj.bucketLabel,
    2
  )}
    </div>
  `;

  content += `
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticAvgLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.averageSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticMinLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.minimumSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticMaxLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.maximumSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${ratioLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            bucketObj.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Check if date and time is included into a multi level array of type (string | number)[][]
 * @param arrayData
 * @param searchedDateTime
 * @return boolean
 */
export function isSameDateAndTimeIncluded(
  arrayData: (string | number)[][],
  searchedDateTime: string
) {
  return arrayData.some((outerLevelItem) => {
    const dateTimeItem = outerLevelItem[0];

    return moment(dateTimeItem).isSame(moment(searchedDateTime));
  });
}

export function formatSeverityStatistic(
  value: number | undefined,
  unitCode: KPI_UNIT
): string {
  const unit = i18n.tc(unitCode);
  if (value === undefined || !isFinite(value)) {
    return `-- ${unit}`;
  }

  return formatUnitValue(toUnitValue(value, unitCode));
}

export interface TripSafetyStatistics {
  totalTrips: number;
  tripsWithEvents: number;
  tripsWithoutEvents: number;
  ratio: number;
  minSeverity: number;
  avgSeverity: number;
  maxSeverity: number;
}

export interface TripSafetyStatisticsForTwoHoursBuckets
  extends TripSafetyStatistics {
  totalAssets: number;
}

export function computeTripSafetyStatistics(
  selectedTrips: AssetTripEventsSummary[]
): TripSafetyStatistics {
  const totalTrips = selectedTrips.length;
  const selectedTripsWithEvents = selectedTrips.filter(
    (tripSummary) => tripSummary.numberOfEvents > 0
  );
  const tripsWithEvents = selectedTripsWithEvents.length;
  const tripsWithoutEvents = totalTrips - tripsWithEvents;

  const minSeverity = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.minimumSeverityLevel)
    .reduce(min, Infinity);
  const maxSeverity = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.maximumSeverityLevel)
    .reduce(max, -Infinity);
  const numberOfEvents = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.numberOfEvents)
    .reduce(sum, 0);
  const sumOfLevels = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.sumOfSeverityLevel)
    .reduce(sum, 0);
  const avgSeverity = sumOfLevels / numberOfEvents;

  return {
    totalTrips,
    tripsWithEvents,
    tripsWithoutEvents,
    ratio: (100 * tripsWithEvents) / totalTrips,
    minSeverity,
    avgSeverity,
    maxSeverity,
  };
}

export function computeTripSafetyStatisticsForTwoHoursBuckets(
  tripsSummaries: GetTripsWithEventsSummary2HoursDetails
): TripSafetyStatisticsForTwoHoursBuckets {
  const totalAssets = tripsSummaries.numberOfAssetsWithTrips;
  const totalTrips = tripsSummaries.tripsWithEventsDetailedSummary
    .map((summary) => summary.tripsWithEventsSummary.numberOfTotalTrips)
    .reduce(sum, 0);
  const tripsWithEvents = tripsSummaries.tripsWithEventsDetailedSummary
    .map((summary) => summary.tripsWithEventsSummary.numberOfTripsWithEvents)
    .reduce(sum, 0);
  const tripsWithoutEvents = totalTrips - tripsWithEvents;

  const eventBucketsWithEvents =
    tripsSummaries.tripsWithEventsDetailedSummary.filter(
      (tripSummary) => tripSummary.eventsDetailedSummary.numberOfEvents > 0
    );
  const minSeverity = eventBucketsWithEvents
    .map(
      (tripSummary) => tripSummary.eventsDetailedSummary.minimumSeverityLevel
    )
    .reduce(min, Infinity);
  const maxSeverity = eventBucketsWithEvents
    .map(
      (tripSummary) => tripSummary.eventsDetailedSummary.maximumSeverityLevel
    )
    .reduce(max, -Infinity);
  const numberOfEvents = eventBucketsWithEvents
    .map((tripSummary) => tripSummary.eventsDetailedSummary.numberOfEvents)
    .reduce(sum, 0);
  const sumOfLevels = eventBucketsWithEvents
    .map((tripSummary) => tripSummary.eventsDetailedSummary.sumOfSeverityLevel)
    .reduce(sum, 0);
  const avgSeverity = sumOfLevels / numberOfEvents;

  return {
    totalAssets,
    totalTrips,
    tripsWithEvents,
    tripsWithoutEvents,
    ratio: (100 * tripsWithEvents) / totalTrips,
    minSeverity,
    avgSeverity,
    maxSeverity,
  };
}

export function flattenOrganizationHierarchyAssets(
  orgHierarchy: OrganizationAssetsHierarchyResponseLegacy
): OrganizationAssetsHierarchyAsset[] {
  let flattenedAssets: OrganizationAssetsHierarchyAsset[] =
    orgHierarchy.organization.assets ?? [];

  if (orgHierarchy.organization.suborganizations === undefined) {
    return flattenedAssets;
  }

  for (const suborg of orgHierarchy.organization.suborganizations) {
    flattenedAssets = flattenedAssets.concat(
      flattenOrganizationHierarchyAssets(suborg)
    );
  }

  return flattenedAssets;
}

/**
 * New safety widgets: generate translated title for grouping scope (fleet/asset) that has:
 * - one safety event then: Fleet/Asset <event> e.g: ("Fleet Overload" or "Asset Overload") for TruckOM customer type
 */
export function defaultSafetyWidgetTitle(
  eventTypeCode: SafetyEventType,
  isAssetScope: boolean
): string {
  const groupingScopeLabel: string = isAssetScope
    ? i18n.tc('newFleetSafety.asset')
    : i18n.tc('newFleetSafety.fleet');

  return `${groupingScopeLabel} ${i18n.tc(eventTypeCode)}`;
}

/**
 * Check if the route includes the code for expanded single asset/fleet safety widget
 */
export function routeIncludesExpandedSafetyWidget(code: string): boolean {
  return [ASSET_SAFETY_CODE, NEW_FLEET_SAFETY_CODE].includes(code);
}
