import i18n from '@/lang';

export const PROD_CATA_CONF_LIST = [
  {
    label: i18n.t('prodCata.productModelName'),
    prop: 'code',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: i18n.t('prodCata.modelNumber'),
    prop: 'modelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: i18n.t('prodCata.assetType'),
    prop: 'assetTypeCode',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: i18n.t('prodCata.ofParts'),
    prop: 'numberOfParts',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: i18n.t('prodCata.status'),
    prop: 'lifeCycle',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: i18n.t('prodCata.action'),
    prop: 'action',
    required: true,
    visible: true,
  },
];

export const PROD_CATA_MODEL_PARTS_LIST = [
  {
    label: i18n.t('prodCata.containedPart'),
    prop: 'name',
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.partMaterialId'),
    prop: 'partMaterialNumber',
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.partMaterialName'),
    prop: 'partMaterialName',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.partType'),
    prop: 'partType',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.obuInstance'),
    prop: 'obuInstance',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.maintainable'),
    prop: 'isMaintainable',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.lifetime'),
    prop: 'lifeTime',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.lifetimeUnit'),
    prop: 'lifeTimeUnit',
    visible: true,
    required: true,
    multiLang: true,
  },
];

export const PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS = [
  {
    label: i18n.t('prodCata.containedPart'),
    prop: 'name',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.partMaterialId'),
    prop: 'partMaterialId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.partMaterialName'),
    prop: 'partMaterialName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.partType'),
    prop: 'partType',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.obuInstance'),
    prop: 'obuInstance',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.maintainable'),
    prop: 'isMaintainable',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.lifetime'),
    prop: 'lifeTime',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: i18n.t('prodCata.lifetimeUnit'),
    prop: 'lifeTimeUnit',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const PROD_CATA_PARTS_LIST = [
  {
    label: i18n.t('prodCata.partMaterialId'),
    prop: 'partMaterialNumber',
    required: true,
    visible: true,
  },
  {
    label: i18n.t('prodCata.partMaterialName'),
    prop: 'name',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.partType'),
    prop: 'translatedPartType',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.status'),
    prop: 'translatedLifeCycle',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.action'),
    prop: 'action',
    required: true,
    visible: true,
  },
];

export const PROD_CATA_APPROVE_LIST = [
  {
    label: i18n.t('prodCata.partMaterialId'),
    prop: 'partMaterialNumber',
    required: true,
    visible: true,
  },
  {
    label: i18n.t('prodCata.partMaterialName'),
    prop: 'name',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.partType'),
    prop: 'partType',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: i18n.t('prodCata.status'),
    prop: 'lifeCycle',
    required: true,
    visible: true,
    multiLang: true,
  },
];
