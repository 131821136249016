<script lang="ts">
import { TripResponse } from '@/api/trip';
import Pagination from '@/components/pagination/Pagination.vue';
import CustomTableCell from '@/components/table/cells/CustomTableCell.vue';
import TripLegend from '@/components/trip/TripLegend.vue';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { hasTableHorizontalScrollbar } from '@/utils/table';
import {
  ColumnRendering,
  ColumnRenderType,
  TableColumn,
} from '@/utils/types/columnCustomizationTypes';
import { useResizeObserver, UseResizeObserverReturn } from '@vueuse/core';
import Sortable from 'sortablejs';
import { computed } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'UtilTable',
  components: {
    TripLegend,
    Pagination,
    CustomTableCell,
  },
})
export default class extends Vue {
  $refs!: {
    tableWrapperRef: HTMLDivElement;
    utilTable: any;
  };

  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: TableColumn[];
  @Prop() total!: number;
  @Prop() pageTotal!: number;
  @Prop() statisticsTips!: string;
  @Prop({ default: true }) showTableHeaderOptions!: boolean;
  @Prop({
    default: () => {
      return [];
    },
  })
  customRendering!: Record<string, ColumnRendering>;
  @Prop() type!: AssetType;
  @Prop() maxHeight?: string;
  @Prop() height?: string;
  @Prop() fitToParent?: boolean;
  @Prop() page?: number;

  list: any = [...this.tableList];

  get listQuery() {
    return {
      page: this.page ?? 1,
      limit: UserModule.gridPageSize,
    };
  }

  visibleCols: TableColumn[] = [];

  resizeObserver!: UseResizeObserverReturn;

  tableHeight: number = 0;

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  @Watch('cols', { deep: true })
  onColsChange(newValue: TableColumn[], oldValue: TableColumn[]) {
    // don't emit cols-changed if no cols were loaded before
    if (oldValue && oldValue.length > 0) {
      this.$emit('cols-change', newValue);
    }
    this.visibleCols = newValue.filter((item: TableColumn) => item.visible);
  }

  handleSortChange(val: any) {
    let sortType: SorterOrder | '' = '';

    if (val.order === 'ascending') {
      sortType = SorterOrder.ASC;
    } else if (val.order === 'descending') {
      sortType = SorterOrder.DESC;
    }
    this.$emit('handle-sort-change', val.prop, sortType);
  }

  handleRowClick(row: any, column: any, event: any) {
    this.$emit('row-click', row);
  }

  getPageList(val: any) {
    this.$emit('handle-pagination', val.page, this.listQuery.limit);
  }

  mounted() {
    this.resizeObserver = useResizeObserver(
      computed(() => this.$refs.tableWrapperRef),
      () => {
        if (this.fitToParent !== true) return;

        const hasScrollbar = hasTableHorizontalScrollbar();
        this.tableHeight =
          this.$refs.tableWrapperRef.getBoundingClientRect().height -
          (hasScrollbar ? 10 : 0);
      }
    );
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    // make the columns sortable
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const newIndex = evt.newIndex;
        const oldIndex = evt.oldIndex;
        // find code of visible cols that were involved in the column re-order
        let col1 = this.visibleCols[newIndex];
        let col2 = this.visibleCols[oldIndex];
        // find index (in this.cols) of visible cols that were involved in the column re-order
        let colIndex1 = this.cols.findIndex(
          (col: any) => col.code === col1.code
        );
        let colIndex2 = this.cols.findIndex(
          (col: any) => col.code === col2.code
        );
        // remove the dragged element from the array and insert it at new index
        // Watch('cols') will emit 'cols-change'
        this.cols.splice(colIndex1, 0, this.cols.splice(colIndex2, 1)[0]);
        // filter resulting cols array by visibility
        this.visibleCols = this.cols.filter(
          (item: TableColumn) => item.visible
        );
        // assign new order value to columns
        this.visibleCols.forEach((col, index) => (col.order = index));
      },
    });
  }

  getRenderingOptions(code: string) {
    return this.customRendering[code] || { type: ColumnRenderType.Generic };
  }

  getOverflowOption(code: string) {
    const overflow = this.getRenderingOptions(code).overflow;

    return overflow ?? true;
  }

  /**
   * Highlight row
   * @param row
   */
  highlightRow(row: TripResponse) {
    this.$refs.utilTable.setCurrentRow(row);
  }

  /**
   * Select page number
   * @param pageNumber
   */
  setPage(pageNumber: number): void {
    this.listQuery.page = pageNumber;
  }
}
</script>

<template>
  <!-- Table related column property
    * required: boolean -- Is it required column, that is to say, whether this column must display
    * visible: boolean --  whether show the column
    * dateFormat: datetime/date -- whether to convert display form
    * multiLang: boolean -- whether to translate
    * sortable: boolean -- whether to sort by column
  -->
  <div :class="fitToParent === true && 'fit-to-parent-container'">
    <div
      v-if="showTableHeaderOptions"
      class="d-flex jc-end ai-center"
      style="min-width: 1500px; margin-bottom: 20px"
    >
      <el-button id="util_table_column_button" type="plain" v-popover:popover>
        <div class="d-flex ai-center jc-center">
          <div>
            <img
              style="width: 14px; height: 14px; margin-right: 8px"
              src="@/assets/imgs/selectColumn.svg"
            />
          </div>
          <div>
            {{ $t('common.selectColumn') }}
          </div>
        </div>
      </el-button>
    </div>

    <div class="no-data-element" v-if="list?.length === 0">
      {{ $t('common.noData') }}
    </div>

    <!--
      This mechanism is used to make sure that in cases such as the Events homepage table
      the table is scrollable and not the whole widget itself. E.g. not differentiating between
      the two would result in the horizontal scrollbar only showing when we scroll to the bottom of the widget.
      We can do this, because <div> is a block element while <span> is an inline one.
     -->
    <component
      :is="fitToParent === true ? 'div' : 'span'"
      ref="tableWrapperRef"
      style="flex-grow: 1"
    >
      <el-table
        v-show="list?.length > 0"
        ref="utilTable"
        :data="list"
        :tree-props="treeProp"
        row-key="id"
        :max-height="maxHeight"
        :height="tableHeight || height"
        style="overflow: auto"
        highlight-current-row
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '7px 0px' }"
        @row-click="handleRowClick"
        @sort-change="handleSortChange"
      >
        <el-table-column
          v-for="(col, index) in visibleCols"
          :key="`${col.code}_${index}`"
          :min-width="getRenderingOptions(col.code).width || 180"
          :show-overflow-tooltip="getOverflowOption(col.code)"
          :sortable="
            getRenderingOptions(col.code).sortable !== false &&
            'custom' /* checks for it to explicitly be different than false (undefined counts as true here)*/
          "
          :prop="getRenderingOptions(col.code).sortField || col.prop"
        >
          <template v-slot:header>
            <!-- TODO: find a better way to do this as well-->
            <div
              v-if="col.prop === 'summary'"
              class="d-flex table-header-flex jc-between"
            >
              <span style="white-space: nowrap; margin-right: 1rem">{{
                col.label
              }}</span>
              <TripLegend :type="type" />
            </div>
            <div v-else class="d-flex jc-between table-header">
              <span style="white-space: nowrap">{{ col.label }}</span>
            </div>
          </template>

          <template v-slot="slotProps">
            <div :key="`${col.code}_${index}`">
              <!-- For some reason el-table-column :key refuses to work with this setup.  -->
              <CustomTableCell
                :scope="slotProps"
                :row="slotProps.row"
                :column="col"
                :renderingOptions="getRenderingOptions(col.code)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </component>

    <div
      v-if="statisticsTips"
      class="d-flex ai-center"
      style="margin-top: 20px"
    >
      <span class="total-statistics">{{ $t(statisticsTips) }}:</span>
      <span class="total-statistics-value">{{ total }}</span>
    </div>

    <!-- v-show="total > 0" -->
    <Pagination
      v-show="pageTotal > listQuery.limit && list.length > 0"
      class="pagination"
      :total="pageTotal"
      :page.sync="listQuery.page"
      :limt.sync="listQuery.limit"
      @pagination="getPageList"
    />

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols"
        :key="index"
        :label="$te(item.label) ? $t(item.label) : item.label"
        v-model="item.visible"
        :disabled="item.required"
        style="display: block; color: #373e41 !important"
      ></el-checkbox>
    </el-popover>
  </div>
</template>

<style>
.fit-to-parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.el-table tbody tr:hover > td {
  background-color: var(--Main) !important;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table,
.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table .current-row > td {
  background-color: var(--DropdownHover) !important;
}

.el-table th.el-table__cell > .cell {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  margin-right: 2px;
}

.table-header-flex {
  display: flex;
  flex-direction: row;
  margin-right: 2px;
}

.pagination {
  margin-bottom: 32px;
}

.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}

.no-data-element {
  margin-left: 45%;
  margin-top: 7%;
  font-weight: bold;
  font-size: 16px;
}
</style>
