<script lang="ts">
import {
  createReportSubscription,
  fetchReportSubscription,
  renewReportSubscription,
  stopReportSubscription,
  SubscribedReport,
  updateReportSubscription,
} from '@/api/reportSubscription';
import Pagination from '@/components/pagination/Pagination.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { getAssetTypesList, ReportAssetType } from '@/utils/assetTypes';
import { generateRequestPayload } from '@/utils/misc';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  REPORT_TEMPLATE_ACTION,
  SUBSCRIPTION_MANAGEMENT_STATUS,
} from '@/utils/workData/lookuptable';
import {
  SUBSCRIBED_REPORT_COL,
  SUBSCRIBED_REPORT_SEARCH_SELECTION_OPTIONS,
} from '@/utils/workData/reportMgmt';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import CreateNewReportSubscribtionModal from '../components/CreateNewReportSubscribtionModal.vue';
import ModifySubscriptionModal from '../components/ModifySubscriptionModal.vue';
import RenewSubscriptionModal from '../components/RenewSubscriptionModal.vue';
import StopSubscriptionModal from '../components/StopSubscriptionModal.vue';
import SubscribeTemplateTable from '../components/SubscribeTemplateTable.vue';
import {
  getAssetTypesFromCompanySubscription,
  setReportAdditionalData,
} from '../report';

@Component({
  name: 'subscribedReport',
  components: {
    'subscribed-report-table': SubscribeTemplateTable,
    'select-table-header': SelectTableHeader,
    'stop-subscription-modal': StopSubscriptionModal,
    'renew-subscription-modal': RenewSubscriptionModal,
    'modify-subscription-modal': ModifySubscriptionModal,
    'create-subscription-report-modal': CreateNewReportSubscribtionModal,
    Pagination,
  },
})
export default class extends Vue {
  /** Local variables */
  subscribedReportCols: any = SUBSCRIBED_REPORT_COL;
  subscribedReportSearchFields: any =
    SUBSCRIBED_REPORT_SEARCH_SELECTION_OPTIONS; // recheck operators when BE implementation is done
  isReportTemplateLoading: boolean = false;
  subscribedReportList: any = {};
  subscribedReportTotal: number = 0;
  isColumnSelectionVisible: boolean = true;
  isStopSubscriptionModalVisible: boolean = false;
  isResumeSubscriptionModalVisible: boolean = false;
  isModifySubscriptionModalVisible: boolean = false;
  isCreateNewSubscriptionReportModalVisible: boolean = false;
  activateOrDeactivate: boolean = false;
  stopSubscriptionModalContent: string = '';
  resumeSubscriptionModalContent: string = '';
  modifySubscribedReportModalTitle: string = '';
  createNewSubscriptionReportModalTitle: string = this.$t(
    'report.subscribeReport'
  ) as string;
  pageSize: number = UserModule.gridPageSize;
  subscriptionToModify: any = {};
  currentPage: number = 1;
  currentRowSelection: any = {};
  tableHeaderIsVisible: boolean = false;
  generalQueryData: any = {
    pageNumber: 1,
    pageSize: UserModule.gridPageSize,
    searchParams: [
      {
        reference: null,
        operator: null,
        value: null,
      },
    ],
    sortByAndOrder: [
      {
        sortBy: null,
        order: null,
      },
    ],
  };
  assetTypeAvailable: string[] | undefined = [];

  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  created() {
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  async prepareDefaultInitialization(): Promise<void> {
    /** Prepare table header > search options for asset types filtration
     * to be only what is found on report default template and
     * logged in user company subscription available asset types
     */
    this.isReportTemplateLoading = true;
    const availableSubscriptionAssetTypes =
      await getAssetTypesFromCompanySubscription();
    const reportTemplatesAvailableAssetTypes = await getAssetTypesList();
    this.assetTypeAvailable = reportTemplatesAvailableAssetTypes
      .filter((reportAssetItem: ReportAssetType) =>
        availableSubscriptionAssetTypes?.some(
          (item: string) => item == reportAssetItem.assetType
        )
      )
      .map((reportAssetItem: ReportAssetType) => reportAssetItem.assetType);
    this.tableHeaderIsVisible = true;

    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.fetchSubscribedReportsDataByCustomParams(finalUrlParamsForSearch);
  }

  /**
   * Handle view event
   * @param rowInfo
   */
  handleView(rowInfo: any): void {
    this.$router.push({
      path: `view/${rowInfo.id}`,
    });
  }

  /**
   * Handle edit event
   * @param rowInfo
   */
  handleEdit(rowInfo: any): void {
    this.$router.push({
      path: `edit/${rowInfo.id}`,
    });
  }

  /** Filter by sort event */
  fetchSubscribedReportDataBySortEvent(sortBy: any, order: any): void {
    this.generalQueryData.sortByAndOrder[0].sortBy = !!order ? sortBy : null;
    this.generalQueryData.sortByAndOrder[0].order = !!order ? order : null;

    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.fetchSubscribedReportsDataByCustomParams(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchSubscribedReportsDataByPageSelection(event: {
    page: number;
    limit: number;
  }): void {
    this.generalQueryData.pageNumber = event.page;
    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );

    this.fetchSubscribedReportsDataByCustomParams(finalUrlParamsForSearch);
  }

  /** Fetch when search is triggered */
  fetchSubscribedReportsDataBySearchParams(): void {
    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.fetchSubscribedReportsDataByCustomParams(finalUrlParamsForSearch);
  }

  /** Retrive remotly subscribed reports data */
  async fetchSubscribedReportsDataByCustomParams(
    requestPayload: QueryParameter
  ): Promise<void> {
    if (!requestPayload) return;
    try {
      this.isReportTemplateLoading = true;
      const apiResponse = await fetchReportSubscription(requestPayload);
      const response = apiResponse.data.subscribedReports;

      response.map((item: SubscribedReport) => {
        if (item) {
          let actions = [REPORT_TEMPLATE_ACTION.Modify];
          if (item.status == SUBSCRIPTION_MANAGEMENT_STATUS.SubstatActive) {
            actions.push(REPORT_TEMPLATE_ACTION.Stop);
          } else if (
            item.status == SUBSCRIPTION_MANAGEMENT_STATUS.SubstatInactive
          ) {
            if (moment().isBefore(moment(item.endDate))) {
              actions.push(REPORT_TEMPLATE_ACTION.Resume);
            }
          }
          item.action = actions;
        }
      });
      this.subscribedReportList = response;
      this.subscribedReportTotal = apiResponse.data.total;
    } catch (error) {
      console.log(error);
    } finally {
      this.isReportTemplateLoading = false;
    }
  }

  /** Handle subscribed action event */
  handleSubscribedActionEvent(item: any, row: any): void {
    this.showModal(item, row);
  }

  /**
   * Show dialog message
   * @param item
   * @param row
   */
  showModal(item: any, row: any): void {
    const subscriptionName: string = `<span class="highlight-modal-info">${row.name}</span>`;
    this.currentRowSelection = row;

    switch (item) {
      case REPORT_TEMPLATE_ACTION.Stop:
        this.stopSubscriptionModalContent = this.$t(
          'report.areYouSureYouWantToStopTheSubscription',
          { subscriptionName: subscriptionName }
        ) as string;
        this.isStopSubscriptionModalVisible = true;
        break;
      case REPORT_TEMPLATE_ACTION.Modify:
        this.subscriptionToModify = row;
        this.modifySubscribedReportModalTitle = row.name;
        this.isModifySubscriptionModalVisible = true;
        break;
      case REPORT_TEMPLATE_ACTION.Resume:
        this.resumeSubscriptionModalContent = this.$t(
          'report.resumeSubscriptionModalContent',
          { subscriptionName: subscriptionName }
        ) as string;
        this.isResumeSubscriptionModalVisible = true;
        break;
      default:
        this.isStopSubscriptionModalVisible = false;
        this.isModifySubscriptionModalVisible = false;
    }
  }

  /** Display none for stop subscription modal */
  cancelModal(): void {
    this.isStopSubscriptionModalVisible = false;
    this.isModifySubscriptionModalVisible = false;
    this.isCreateNewSubscriptionReportModalVisible = false;
    this.isResumeSubscriptionModalVisible = false;
  }

  /** Handle to stop current subscription */
  async handleStopSubscription(): Promise<void> {
    await stopReportSubscription(this.currentRowSelection.id).then((_res) => {
      customSuccessMessage(
        this.$t('report.subscriptionHasBeenStoppedSuccessfully') as string
      );
      this.isStopSubscriptionModalVisible = false;
      this.fetchSubscribedReportsDataBySearchParams();
    });
  }

  /** Renew a subscription */
  async handleRenewSubscription() {
    await renewReportSubscription(this.currentRowSelection.id).then((_res) => {
      customSuccessMessage(
        this.$t('report.subscriptionHasBeenResumedSuccessfully') as string
      );
      this.isResumeSubscriptionModalVisible = false;
      this.fetchSubscribedReportsDataBySearchParams();
    });
  }

  /** Handle subscription modify */
  async modifySubscriptionReport(data: any): Promise<void> {
    setReportAdditionalData(data);
    const res = await updateReportSubscription(
      this.currentRowSelection.id,
      data
    );
    if (res.code === 200) {
      this.isModifySubscriptionModalVisible = false;
      customSuccessMessage(
        this.$t('report.subscribedReportModifiedSuccessfully') as string
      );
      return;
    }

    if (res.data.errors) {
      customFailedMessage(res.data.errors[0].message);
      return;
    }

    customFailedMessage(this.$t('common.serverError') as string);
  }

  /** Handle creation of a new subscription report */
  viewDialogForCreateNewSubscribeReport(): void {
    this.isCreateNewSubscriptionReportModalVisible = true;
  }

  /** Handle create new subscription report */
  async createSubscriptionReport(data: any): Promise<void> {
    try {
      setReportAdditionalData(data);
      const response = await createReportSubscription(data);
      if (response.code === 200) {
        customSuccessMessage(
          this.$t('report.reportHasBeenSubscribedSuccessfully') as string
        );
      }
      this.prepareDefaultInitialization();
    } catch (error) {
      console.log(error);
    } finally {
      this.isCreateNewSubscriptionReportModalVisible = false;
    }
  }
}
</script>

<template>
  <div>
    <div style="min-height: 40px">
      <div class="subscribed-report-container">
        <select-table-header
          id="subscribed_report_search_element"
          v-show="tableHeaderIsVisible"
          style="margin: 20px 0"
          :searchFieldOptions="subscribedReportSearchFields"
          :cols="subscribedReportCols"
          :searchParams="generalQueryData.searchParams[0]"
          :isColumnSelectionVisible="isColumnSelectionVisible"
          :assetTypeAvailable="assetTypeAvailable"
          :needReportTypes="true"
          @search-event="fetchSubscribedReportsDataBySearchParams"
        >
          <template #rightHeader>
            <el-button
              id="create_report_subscription_button"
              v-permission="['AUTHRSC_ACTION_SUBSCRIBED_REPORTS_CREATE']"
              type="plain"
              @click="viewDialogForCreateNewSubscribeReport"
              style="margin-right: 20px"
            >
              <i class="el-icon-plus common-icon" />{{
                $t('report.subscribeReport')
              }}
            </el-button>
          </template>
        </select-table-header>
      </div>
    </div>

    <subscribed-report-table
      id="subscribed_report_table"
      v-loading="isReportTemplateLoading"
      :tableList="subscribedReportList"
      :cols="subscribedReportCols"
      @VIEW="handleView"
      @EDIT="handleEdit"
      @handle-page="fetchSubscribedReportsDataByPageSelection"
      @handle-sort-change="fetchSubscribedReportDataBySortEvent"
      @action-event="handleSubscribedActionEvent"
    />

    <div style="min-height: 48px">
      <Pagination
        id="pagination_component"
        style="padding: 16px 0px 0px 0px"
        v-show="subscribedReportTotal > listQuery.limit"
        :total="subscribedReportTotal"
        :page.sync="listQuery.page"
        :limt.sync="listQuery.limit"
        @pagination="fetchSubscribedReportsDataByPageSelection"
      />
    </div>

    <div
      class="d-flex jc-between ai-center flex-wrap"
      style="padding-top: 5px; padding-bottom: 5px"
    >
      <div class="d-flex ai-center">
        <span class="total-statistics"
          >{{ $t('report.subscribedReportTotalInfo') }}:</span
        >
        <span class="total-statistics-value">{{ subscribedReportTotal }}</span>
      </div>
    </div>

    <stop-subscription-modal
      v-if="isStopSubscriptionModalVisible"
      :visible.sync="isStopSubscriptionModalVisible"
      :activateOrDeactivate="activateOrDeactivate"
      :title="'report.stopSubscription'"
      :modalContent="stopSubscriptionModalContent"
      @handle-cancel="cancelModal"
      @handle-stop-subscription="handleStopSubscription"
    />

    <renew-subscription-modal
      v-if="isResumeSubscriptionModalVisible"
      :visible.sync="isResumeSubscriptionModalVisible"
      :activateOrDeactivate="activateOrDeactivate"
      :title="'report.resumeSubscription'"
      :modalContent="resumeSubscriptionModalContent"
      @handle-cancel="cancelModal"
      @handle-renew-subscription="handleRenewSubscription"
    />

    <modify-subscription-modal
      v-if="isModifySubscriptionModalVisible"
      :visible.sync="isModifySubscriptionModalVisible"
      :activateOrDeactivate="activateOrDeactivate"
      :title="modifySubscribedReportModalTitle"
      :subscriptionToModify="subscriptionToModify"
      @handle-cancel="cancelModal"
      @handle-modify-subscribed-report="modifySubscriptionReport"
    />

    <create-subscription-report-modal
      v-if="isCreateNewSubscriptionReportModalVisible"
      :visible.sync="isCreateNewSubscriptionReportModalVisible"
      :title="createNewSubscriptionReportModalTitle"
      :subscriptionToModify="subscriptionToModify"
      @handle-cancel="cancelModal"
      @handle-create-subscribed-report="createSubscriptionReport"
    />
  </div>
</template>

<style scoped>
.el-dialog__header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>

<style lang="scss" scoped>
#subscribed_report_table {
  :deep(td .el-button) {
    padding: 4px 12px;
  }
}
</style>
