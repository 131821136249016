<script lang="ts">
import { ActiveContext, useActiveContext } from '@/auth/context';
import Pagination from '@/components/pagination/Pagination.vue';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { hasTableHorizontalScrollbar } from '@/utils/table';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import {
  ALL_CLAIMS_CODES,
  AssetLifecycle,
  GENERAL_QUERY_OPERATORS,
  REGISTERED_ASSET_CONNECTION_STATUSES,
} from '@/utils/workData/lookuptable';
import { useResizeObserver, UseResizeObserverReturn } from '@vueuse/core';
import { ElTable } from 'element-ui/types/table';
import Sortable from 'sortablejs';
import { computed, Ref, unref } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'PureTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  $refs!: {
    tableWrapperRef: HTMLDivElement;
    tableRef: ElTable;
  };

  @Prop() tableList!: any[];
  @Prop({ default: true }) showPagination!: boolean;
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() viewPagePath!: string;
  @Prop({ default: 'id' }) viewPathProperty!: string;
  @Prop() actionTable!: string;
  @Prop() maxHeight!: string;
  @Prop({ default: false }) selectable!: boolean;
  @Prop() editing!: any;
  @Prop({ default: false }) groupedSelection!: boolean;
  @Prop({ default: false }) enableRowSelection?: boolean;
  @Prop({ default: false }) expandAll?: boolean;
  @Prop() fitToParent?: boolean;

  list: any = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  assets: any[] = [];
  context!: Ref<ActiveContext>;
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  filter: any = {
    filters: [
      {
        name: 'ownerOrganizationId',
        operator: GENERAL_QUERY_OPERATORS.Equal,
        value: [this.$route.params.customerId],
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: {
      page: '1',
      size: '10',
    },
  };

  resizeObserver!: UseResizeObserverReturn;

  @InitializeReactive
  tableHeight: number = 0;

  @Watch('tableList')
  onListChange() {
    this.list = [...this.tableList];
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  created() {
    this.context = useActiveContext();
  }

  mounted() {
    this.resizeObserver = useResizeObserver(
      computed(() => this.$refs.tableWrapperRef),
      () => {
        if (this.fitToParent !== true) return;

        const hasScrollbar = hasTableHorizontalScrollbar();
        this.tableHeight =
          this.$refs.tableWrapperRef.getBoundingClientRect().height -
          (hasScrollbar ? 10 : 0);
      }
    );
    this.columnDrop();
    this.toggleSelection(this.assets);
  }

  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: string, row: any) {
    this.$emit(item, row);
  }

  /**
   * Handle row click, should follow the next steps of actions:
   *  - mark item when click should be first action
   *  - return a certain route accordingly with row property or this child property
   * @param row
   * @param column
   * @param event
   */
  handleRowClick(row: any, column: any, event: any) {
    // !Important! > First step as higher priority > mark/select element by click on the row if the parent component requires it
    if (this.enableRowSelection) {
      if (!this.assets.includes(row.id)) {
        this.assets.push(row.id);
        this.handleRowToggle(row);
      } else {
        this.assets.splice(this.assets.indexOf(row.id), 1);
        this.handleRowToggle(row);
      }
      this.$emit('updateSelectedAssets', this.assets);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row[this.viewPathProperty]);
    }

    this.$emit('row-click', row);
  }

  handleSelectionChange(event: any) {
    this.assets = event.map((asset: any) => asset.id);

    if (!this.groupedSelection) {
      this.$emit('updateSelectedAssets', this.assets);
      return;
    }

    let selectedReleases = event.map((asset: any) => {
      return {
        asset: asset.assetUUID,
        releaseUUID: asset.newSystemReleaseUUID,
      };
    });
    let group = selectedReleases.reduce((current: any, next: any) => {
      current[next.releaseUUID] = [...(current[next.releaseUUID] || []), next];
      return current;
    }, {});
    this.$emit('updateSelectedAssets', group);
  }

  /** Row toggle handling for row click event */
  handleRowToggle(row: any): void {
    const refOfTable = this.$refs.tableRef as ElTable;
    if (!refOfTable) return;
    refOfTable.toggleRowSelection(row);
  }

  tableRowClassName(tab: any) {
    if (this.assets.find((assetId: any) => assetId === tab.row.id)) {
      return 'selected';
    }
  }

  toggleSelection(rows: any) {
    if (rows.length < 1) return;
    const ref = this.$refs.tableRef as ElTable;
    if (!ref) return;
    rows.forEach((row: any) => {
      ref.toggleRowSelection(row);
    });
  }

  handleSortChange(val: any) {
    let sortType: SorterOrder | '' = '';

    if (val.order === 'ascending') {
      sortType = SorterOrder.ASC;
    } else if (val.order === 'descending') {
      sortType = SorterOrder.DESC;
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  /**
   * Connection asset from table
   */
  handleConnectBtnClick(rowInfo: any) {
    this.$router.push('/asset-mgmt/connect/edit/' + rowInfo.id);
  }

  // TODO: this should be moved out of this component
  showConnectButton(row: any) {
    const registeredStatuses = [
      REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered,
      AssetLifecycle.Registered,
    ];
    const rowStatus =
      row['connectionStatus'] || row['connectStatus'] || row['assetStatus'];

    return registeredStatuses.includes(rowStatus);
  }

  checkClaim(claim: ALL_CLAIMS_CODES) {
    return unref(this.context).claims.hasClaim(claim);
  }
}
</script>

<template>
  <div :class="fitToParent === true && 'fit-to-screen-container'">
    <div
      ref="tableWrapperRef"
      :style="{ flexGrow: fitToParent === true ? 1 : '' }"
    >
      <el-table
        ref="tableRef"
        :data="list"
        :tree-props="treeProp"
        :default-expand-all="!!expandAll ? true : false"
        :class="expandAll ? 'expandAll' : ''"
        row-key="id"
        :max-height="maxHeight"
        :height="fitToParent ? tableHeight : null"
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '7px 0px' }"
        :row-class-name="tableRowClassName"
        @row-click="handleRowClick"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="selectable"
          :reserve-selection="selectable"
          type="selection"
          width="55"
        />
        <el-table-column
          v-for="(col, index) in activeCols"
          :key="index + '_' + tableComponentKey"
          :prop="col.prop"
          min-width="242"
          show-overflow-tooltip
          :sortable="col.sortable"
        >
          <template v-slot:header>
            <div class="table-header">
              <span style="white-space: nowrap">{{
                activeCols[index]?.label
              }}</span>
            </div>
          </template>
          <!-- show icon -->
          <template v-slot="slotProps" v-if="col.prop == 'claims'">
            <span
              class="module-access"
              v-if="
                handleModuleAccessIconRendering(
                  slotProps.$index,
                  'AUTHRSC_MOD_ASSETS'
                )
              "
            >
              <img
                viewBox="0 0 18.3 14.3"
                src="@/assets/imgs/modules-access/assets.svg"
                class="module-access-item"
                :title="$t('AUTHRSC_MOD_ASSETS')"
              />
            </span>
            <span
              class="module-access"
              v-if="
                handleModuleAccessIconRendering(
                  slotProps.$index,
                  'AUTHRSC_MOD_HOME'
                )
              "
            >
              <img
                viewBox="0 0 18.3 14.3"
                src="@/assets/imgs/modules-access/home.svg"
                class="module-access-item"
                :title="$t('AUTHRSC_MOD_HOME')"
              />
            </span>
            <span
              class="module-access"
              v-if="
                handleModuleAccessIconRendering(
                  slotProps.$index,
                  'AUTHRSC_MOD_ADMINISTRATION'
                )
              "
            >
              <img
                viewBox="0 0 18.3 14.3"
                src="@/assets/imgs/modules-access/administration.svg"
                class="module-access-item"
                :title="$t('AUTHRSC_MOD_ADMINISTRATION')"
              />
            </span>
            <span
              class="module-access"
              v-if="
                handleModuleAccessIconRendering(
                  slotProps.$index,
                  'AUTHRSC_MOD_USER_SETTINGS'
                )
              "
            >
              <img
                viewBox="0 0 18.3 14.3"
                src="@/assets/imgs/modules-access/userSetting.svg"
                class="module-access-item"
                :title="$t('AUTHRSC_MOD_USER_SETTINGS')"
              />
            </span>
          </template>

          <!-- Custom icons set -->
          <!-- Pass a dictionary as 'useIconSet' argument for this column eg. {'ICON_KEY': {'img': imgRef, 'title': 'translation.key'}} -->
          <!-- See startDeployment.vue and deploymentManagementCols.ts for example -->
          <template v-slot="slotProps" v-else-if="col.useIconSet">
            <img
              :src="col.useIconSet[slotProps.row[col.prop]].img"
              class="img-auto-center"
              :title="$t(col.useIconSet[slotProps.row[col.prop]].title)"
              v-if="col.useIconSet[slotProps.row[col.prop]]"
            />

            <span v-if="!col.useIconSet[slotProps.row[col.prop]]">{{
              slotProps.row[col.prop]
            }}</span>
          </template>

          <!-- format date info -->
          <template
            v-slot="slotProps"
            v-else-if="
              col.dateFormat === 'date' || col.dateFormat === 'datetime'
            "
          >
            <span>{{
              slotProps.row[col.prop] | formatTimer(col.dateFormat)
            }}</span>
          </template>
          <!-- translate language -->
          <template v-slot="slotProps" v-else-if="col.multiLang">
            <!-- This piece of code renders a value from a row -->
            <!-- slotProps.row is a Dictionary, and col.prop is a key -->
            <span>{{ slotProps.row[col.prop] }}</span>
          </template>
          <!-- action parts -->
          <template v-slot="slotProps" v-else-if="col.prop === 'action'">
            <el-button
              v-for="(item, index) in slotProps.row[col.prop]"
              :key="index"
              @click.stop="handleActionBtnClick(item, slotProps.row)"
              type="text"
              size="small"
            >
              {{ $t(item) }}
            </el-button>
          </template>
          <template v-slot="slotProps" v-else-if="col.prop === 'ifEnable'">
            <div class="enable-container">
              <span class="enable-value">{{
                $t(`${slotProps.row.ifEnable}`)
              }}</span>
              <el-switch
                v-if="editing"
                v-model="slotProps.row.ifEnable"
                class="el-switch-component"
                active-color="var(--Main)"
                inactive-color="#D5D5D5"
              >
              </el-switch>
            </div>
          </template>
          <template v-slot="slotProps" v-else-if="col.prop === 'gatewayId'">
            <el-button
              class="connect-btn"
              v-if="
                checkClaim(ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_CONNECT) &&
                showConnectButton(slotProps.row)
              "
              size="small"
              @click.stop="handleConnectBtnClick(slotProps.row)"
            >
              {{ $t('assetMgmt.connectBtn') }}
            </el-button>
            <span v-else>{{ slotProps.row[col.prop] }}</span>
          </template>
          <template
            v-slot="slotProps"
            v-else-if="col.prop === 'accessOrganizations'"
          >
            <div
              v-if="slotProps.row[col.prop] != undefined"
              class="access-organization"
            >
              {{ slotProps.row[col.prop].join(', ') }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- v-show="total > 0" -->
    <Pagination
      v-show="
        /* @ts-expect-error TODO Wrong type */
        showPagination && parseInt(total) > parseInt(pageSize)
      "
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<style>
.fit-to-screen-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.el-table .selected {
  background: #ffeb99;
}

.connect-btn {
  border-radius: 6px;
}

.connect-btn:hover {
  color: white;
  background-color: #5f6567 !important;
}

.enable-value {
  text-transform: uppercase;
}

.el-switch-component {
  margin-left: 20px;
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  margin-right: 12px;
}
.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

.dropDownMenu-item {
  line-height: 25px;
  text-indent: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 5px;
}

.dropDownMenu-item:hover {
  background-color: var(--Main);
}

.dm-font {
  font-size: 14px;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #363636;
}

.el-input {
  width: 300px;
}

.el-select {
  margin-right: 40px;
}

.util-table-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: var(--fontRobotoRegular);
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: var(--fontRobotoRegular);
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

:deep(.el-button--text) {
  font-size: 14px;
  font-family: $font-Roboto-Medium;
  border: 1px solid #707070;
  padding: 4px 24px;
}
:deep(.el-button--text) {
  color: #707070;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}

:deep(.expandAll .el-table__expand-icon) {
  display: none;
}

:deep(.expandAll .el-table__placeholder) {
  display: none;
}

.img-auto-center {
  justify-self: center;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.access-organization {
  display: contents;
}
</style>
