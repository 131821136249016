<script lang="ts">
import { UUID } from '@/api/common';
import {
  createOrganization,
  getOrganizations,
  getOrgById,
  Organization,
  OrganizationChild,
  OrganizationResponse,
  updateActivationStatus,
  updateOrg,
} from '@/api/organizations';
import { flattenOrganizations } from '@/auth/context';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  customSuccessMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import OrganizationDetailPage from './OrganizationDetailPage.vue';
import OrganizationManagementForm from './OrganizationManagementForm.vue';

interface OrganizationCols {
  label: string;
  prop: string;
  visible: boolean;
}

interface OrganizationTableItem {
  id: string;
  name: string;
  parentName: string;
  assetsCount: number;
  usersCount: number;
  translatedActivationStatus: string;
  note: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
  children: OrganizationTableItem[];
}

@Component({
  name: 'OrganizationManagement',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
    'organization-management-form': OrganizationManagementForm,
    'deactivate-dialog': DeactivateDialog,
    'org-detail-page': OrganizationDetailPage,
  },
})
export default class extends Vue {
  @Prop() customerId!: string;
  @Prop() organizationId!: string;
  @Prop() cols!: OrganizationCols;

  /** Local variables */
  isOrganizationManagementLoading: boolean = false;
  totalOrganizations: number = 0;
  treeProp = { children: 'children', hasChildren: 'hasChildren' };
  loadingText: string = this.$t('userModule.loadingOrganizationData') as string;
  treeSelectIsDisabled: boolean = true;
  orgList: OrganizationResponse = [];
  tableData: OrganizationTableItem[] = [];
  organizationInputFormIsVisible: boolean = false;
  organizationDetailViewFormIsVisible: boolean = false;
  page: string = '1';
  pageSize: string = UserModule.gridPageSize.toString();
  modalWindowIsVisible: boolean = false;
  activateOrDeactivate: string = '';
  dialogContent: TranslateResult = '';
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  selectedCustomerOrganization: Organization = {
    id: '',
    companyId: this.customerId,
    parentId: '',
    name: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    assetsCount: 0,
    usersCount: 0,
    subOrganizationCount: 0,
    timezone: '',
    children: [],
    note: '',
    serviceRegion: '',
    accessibleServiceRegions: [],
    hyvaRegion: '',
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    additionalInfo: '',
    zipCode: '',
    isDeleted: false,
    parentName: '',
  };
  orgForm: Organization = {
    id: '',
    companyId: this.customerId,
    parentId: '',
    name: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    assetsCount: 0,
    usersCount: 0,
    subOrganizationCount: 0,
    timezone: '',
    children: [],
    note: '',
    serviceRegion: '',
    accessibleServiceRegions: [],
    hyvaRegion: '',
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    additionalInfo: '',
    zipCode: '',
    isDeleted: false,
    parentName: '',
  };
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  created() {
    this.selectedCustomerOrganization = this.orgForm;
    this.fetchOrganizationData();
    this.getOrganizationById();
    this.cleanUpOrgInputForm();
  }

  handleParentName(orgs: OrganizationResponse) {
    if (orgs.length === 0) return;
    orgs.forEach((item: Organization) => {
      item.parentName = item.parentName ? item.parentName : '—';
      return this.handleParentName(item.children);
    });
  }

  /**
   * Fetch organization data
   */
  async fetchOrganizationData() {
    try {
      this.isOrganizationManagementLoading = true;
      const requestUrl = this.generateRequestUrlWithParams(
        this.page,
        this.pageSize,
        this.sortAndOrderData
      );
      const response = await getOrganizations(this.customerId, requestUrl);
      this.orgList = response.data;
      this.handleChildrenOrgCount(response.data);
      this.sendTotalOrganizations();
      this.handleParentName(this.orgList);

      this.tableData = this.orgList.map(this.mapOrgToTableItem);
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isOrganizationManagementLoading = false;
    }
  }

  mapOrgToTableItem(
    org: Organization | OrganizationChild
  ): OrganizationTableItem {
    return {
      id: org.id,
      name: org.name,
      parentName: org.parentName ?? '',
      assetsCount: org.assetsCount,
      usersCount: org.usersCount,
      translatedActivationStatus: this.$t(org.activationStatus),
      translatedHyvaRegion: this.$t(org.hyvaRegion),
      translatedServiceRegion: this.$t(org.serviceRegion),
      note: org.note,
      children: org.children.map(this.mapOrgToTableItem),
    };
  }

  /** Handle total count of selected org it self and children */
  handleChildrenOrgCount(organization: OrganizationResponse) {
    const allChildren: Organization[] = flattenOrganizations(organization);
    this.totalOrganizations = allChildren?.length;
  }

  /**
   * Generate request URL by multiple factors
   * @param pageNumber
   * @param pageSize
   * @param sortAndOrderData
   */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    sortAndOrderData.sortBy && sortAndOrderData.order
      ? (finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`)
      : (finalUrl += '&sortBy=createdOn&order=DESC');
    return finalUrl;
  }

  /** Get selected customer organization */
  async getOrganizationById() {
    try {
      this.isOrganizationManagementLoading = true;
      if (!this.organizationId) return;
      const response = await getOrgById(this.organizationId);
      this.selectedCustomerOrganization = response.data;
      this.orgForm.parentId = this.selectedCustomerOrganization.id;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isOrganizationManagementLoading = false;
    }
  }

  /** Get organizations list by page selection */
  fetchOrganizationsByPageSelection() {}

  /**
   * Fetch organizations by sort event
   * @param sortBy
   * @param order
   */
  fetchOrganizationsBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    this.fetchOrganizationData();
  }

  /** Send total organizations count to parent */
  @Emit('send-total-organizations')
  sendTotalOrganizations() {
    return this.totalOrganizations;
  }

  /** Show organization input form */
  showOrganizationInputForm() {
    this.errorInfos = [];
    this.treeSelectIsDisabled = false;
    this.organizationInputFormIsVisible = true;
  }

  /**
   * Handle final save/update btn click action
   */
  validateClickAction() {
    (this.$refs.organizationManagementForm as any).sendOrganizationForm();
  }

  /**
   * Handle save or update remotly the organization input form
   * @param requestBody
   */
  async handleOrganizationSaveRemotly(requestBody: Organization) {
    try {
      this.isOrganizationManagementLoading = true;
      if (requestBody.id) {
        this.loadingText = this.$t('customerModule.updatingText') as string;
        await updateOrg(requestBody.id, requestBody).then((res: any) => {
          if (res.code === 200) {
            customSuccessMessage(this.$t('common.savedSucessfully') as string);
            this.errorInfos = [];
            return;
          }

          if (res.code === 400) {
            const fieldErrorCode = res.data.errors[0].code;
            if (fieldErrorCode === 'ApiErrorFieldDuplicate') {
              customFailedMessage(
                this.$t('organizationModule.duplicateName') as string
              );
            }
            this.errorInfos = res.data.errors;
          }
        });
        return;
      }

      this.loadingText = this.$t(
        'organizationModule.addNewOrganization'
      ) as string;
      await createOrganization(requestBody).then((res: any) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.created') as string);
          this.errorInfos = [];
          return;
        }

        if (res.code === 400) {
          const fieldErrorCode = res.data.errors[0].code;
          if (fieldErrorCode === 'ApiErrorMaxOrganizationsExceeded') {
            customFailedMessage(
              this.$t('organizationModule.maximumOrgsExceeded') as string
            );
          }

          if (fieldErrorCode === 'ApiErrorSubscriptionPackageType') {
            customFailedMessage(
              this.$t('organizationModule.missingOrgSysFeature') as string
            );
          }

          if (fieldErrorCode === 'ApiErrorFieldDuplicate') {
            customFailedMessage(
              this.$t('organizationModule.duplicateName') as string
            );
          }
          this.errorInfos = res.data.errors;
          return;
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationManagementLoading = false;
      this.fetchOrganizationData();
      this.cancelOrganizationManagementForm();
      this.cleanUpOrgInputForm();
    }
  }

  /** Hide organization input form when cancelled */
  cancelOrganizationManagementForm() {
    this.cleanUpOrgInputForm();
    this.organizationInputFormIsVisible = false;
    this.organizationDetailViewFormIsVisible = false;
  }

  /** Clean up org input form */
  cleanUpOrgInputForm() {
    this.orgForm = {
      id: '',
      companyId: this.customerId,
      parentId: this.selectedCustomerOrganization.id,
      name: '',
      activationStatus: ACTIVATION_STATUS.Activated,
      assetsCount: 0,
      usersCount: 0,
      subOrganizationCount: 0,
      timezone: '',
      children: [],
      note: '',
      serviceRegion: '',
      accessibleServiceRegions: [],
      hyvaRegion: '',
      street: '',
      district: '',
      province: '',
      city: '',
      country: '',
      additionalInfo: '',
      zipCode: '',
      isDeleted: false,
      parentName: '',
    };
    this.loadingText = this.$t('userModule.loadingOrganizationData') as string;
  }

  viewOrganizationRowItem(organizationRowItem: OrganizationTableItem) {
    const selectedOrg = this.findOrganization(
      this.orgList,
      organizationRowItem.id
    );

    if (selectedOrg === undefined) {
      throw new Error('Selected Org not found');
    }

    this.orgForm = selectedOrg;
    if (selectedOrg.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.activateOrDeactivate = 'activate';
    } else {
      this.activateOrDeactivate = 'deactivate';
    }
    this.organizationDetailViewFormIsVisible = true;
    this.treeSelectIsDisabled = true;
  }

  findOrganization(
    orgs: Organization[],
    id: UUID
  ): Organization | OrganizationChild | undefined {
    for (const org of orgs) {
      if (org.id === id) {
        return org;
      }

      const foundChildOrg = this.findOrganization(org.children, id);

      if (foundChildOrg) {
        return foundChildOrg;
      }
    }

    return undefined;
  }

  /** Edit organization from detail view page */
  editSelectedOrganization() {
    this.organizationDetailViewFormIsVisible = false;
    this.organizationInputFormIsVisible = true;
  }

  /**
   * Show deactivate/activate modal window for organization
   */
  showModalWindow() {
    if (this.orgForm.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.orgForm.name,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.orgForm.name,
      });
    }
    this.modalWindowIsVisible = true;
  }

  /** Cancel deactivate window modal */
  handleCancelDeactivateModal() {
    this.modalWindowIsVisible = false;
  }

  /** Handle deactivate/activate organization */
  async handleDeactivateOrganization() {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivated')
        : this.$t('common.activated')
    ) as string;
    const finalActivationStatus =
      this.activateOrDeactivate === 'deactivate'
        ? ACTIVATION_STATUS.Deactivated
        : ACTIVATION_STATUS.Activated;
    try {
      this.modalWindowIsVisible = false;
      this.isOrganizationManagementLoading = true;
      await updateActivationStatus(this.orgForm.id, {
        activationStatus: finalActivationStatus,
      });
    } catch (error) {
      console.log(error);
      promptFailedBox(infoMsg);
    } finally {
      promptSuccessBox(infoMsg);
      this.organizationDetailViewFormIsVisible = false;
      this.organizationInputFormIsVisible = false;
      this.fetchOrganizationData();
    }
  }
}
</script>

<template>
  <div
    v-loading="isOrganizationManagementLoading"
    :element-loading-text="loadingText"
  >
    <div
      v-if="
        !organizationInputFormIsVisible && !organizationDetailViewFormIsVisible
      "
    >
      <div class="d-flex jc-start ai-center" style="margin: 20px 0">
        <div class="d-flex ai-center flex-g1"></div>
        <div class="d-flex ai-center">
          <el-button
            type="plain"
            @click="showOrganizationInputForm"
            style="margin-right: 5px"
          >
            <i class="el-icon-plus common-icon" />{{
              $t('organizationModule.addNewOrganization')
            }}
          </el-button>
        </div>
      </div>
      <el-row>
        <pure-table
          class="orgTable"
          :tableList="tableData"
          :total="0"
          :cols="cols"
          :treeProp="treeProp"
          @handle-page="fetchOrganizationsByPageSelection"
          @handle-sort-change="fetchOrganizationsBySortEvent"
          @row-click="viewOrganizationRowItem"
          :expandAll="true"
        />
      </el-row>
    </div>

    <div v-if="organizationDetailViewFormIsVisible">
      <div class="d-flex jc-start ai-center" style="margin: 20px 0">
        <div class="d-flex ai-center flex-g1"></div>
        <div class="d-flex ai-center">
          <el-button
            type="plain"
            @click="editSelectedOrganization"
            style="margin-right: 5px"
          >
            <i class="el-icon-plus common-icon" />{{ $t('common.edit') }}
          </el-button>
        </div>
        <el-button type="plain" @click="showModalWindow">
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/deactivate.svg"
              />
            </div>
            <div>
              {{
                activateOrDeactivate === 'deactivate'
                  ? $t('common.deactivate')
                  : $t('common.activate')
              }}
            </div>
          </div>
        </el-button>
        <el-button
          type="plain"
          @click="cancelOrganizationManagementForm"
          style="margin-right: 5px"
        >
          <i class="el-icon-close common-icon" />{{ $t('common.cancelBtn') }}
        </el-button>
      </div>
      <org-detail-page :orgInfo="orgForm" />
    </div>

    <div
      v-if="
        organizationInputFormIsVisible && !organizationDetailViewFormIsVisible
      "
    >
      <div class="d-flex jc-start ai-center" style="margin: 20px 0">
        <div class="d-flex ai-center flex-g1"></div>
        <div class="d-flex ai-center">
          <el-button
            type="plain"
            @click="validateClickAction"
            style="margin-right: 5px"
          >
            <i class="el-icon-plus common-icon" />{{ $t('common.save') }}
          </el-button>
          <el-button
            v-if="treeSelectIsDisabled"
            type="plain"
            @click="showModalWindow"
          >
            <div class="d-flex ai-center jc-center">
              <div>
                <img
                  style="margin-right: 5px"
                  src="@/assets/imgs/deactivate.svg"
                />
              </div>
              <div>
                {{
                  activateOrDeactivate === 'deactivate'
                    ? $t('common.deactivate')
                    : $t('common.activate')
                }}
              </div>
            </div>
          </el-button>
          <el-button
            type="plain"
            @click="cancelOrganizationManagementForm"
            style="margin-right: 5px"
          >
            <i class="el-icon-close common-icon" />{{ $t('common.cancelBtn') }}
          </el-button>
        </div>
      </div>
      <organization-management-form
        ref="organizationManagementForm"
        :orgForm="orgForm"
        :treeSelectIsDisabled="treeSelectIsDisabled"
        :errorInfos="errorInfos"
        :selectedCustomerOrganization="selectedCustomerOrganization"
        @save-org="handleOrganizationSaveRemotly"
      />
    </div>
    <deactivate-dialog
      :visiable.sync="modalWindowIsVisible"
      :activateOrDeactivate="activateOrDeactivate"
      :title="$t('userModule.user')"
      :content="dialogContent"
      @handle-cancel="handleCancelDeactivateModal"
      @handle-deactivate="handleDeactivateOrganization"
    />
  </div>
</template>

<style scoped></style>

<style lang="scss">
.orgTable :deep(.el-table__row--level-0) {
  background-color: #dddddd !important;
}

.orgTable :deep(.el-table__row > td) {
  border-bottom: none;
}
</style>
