import { render, staticRenderFns } from "./DashboardConf.vue?vue&type=template&id=06057702&scoped=true"
import script from "./DashboardConf.vue?vue&type=script&lang=ts"
export * from "./DashboardConf.vue?vue&type=script&lang=ts"
import style0 from "./DashboardConf.vue?vue&type=style&index=0&id=06057702&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06057702",
  null
  
)

export default component.exports