<script lang="ts">
import { getReportTemplates } from '@/api/report';
import { useHyvaRegionsQuery } from '@/query/hyvaRegion';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import { disabledFutureDate } from '@/utils/date';
import {
  ALARM_CONFIG_METRIC_UNITS_LIST,
  ASSET_TYPE_LIST,
  COMPANY_TYPE,
  COMPANY_TYPE_LIST,
  CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING,
  GENERAL_QUERY_OPERATORS,
  LOCK_STATUSES_LIST,
  MAINTENANCE_ITEM_STATUS_LIST,
  REGISTERED_ASSET_CONNECTION_STATUSES_LIST,
  SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST,
  TIME_UNITS_LIST,
  USER_MANAGEMENT_ACTIVATION_STATUSES_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface OptionList {
  id: string;
  value: string;
}

@Component({
  name: 'SelectTableHeader',
})
export default class extends Vue {
  @Prop({ required: false, default: true }) isColumnSelectionVisible!: boolean;
  @Prop({ required: false, default: undefined }) assetTypeAvailable!: string[];
  @Prop() searchFieldOptions: any;
  @Prop() searchParams: any;
  @Prop({ required: false }) customerType!: COMPANY_TYPE;
  @Prop() cols!: [];
  @Prop() selectedAssetes!: string[];
  @Prop({ required: false, default: false }) needReportTypes!: boolean;

  /** For checking selected rows */
  unwatch: any = null;
  selectedRows: string[] = [];
  addRemoveAccess: boolean = false;

  @Watch('customerType')
  handleCustomerType(newData: string, oldData: string) {
    if (newData) {
      this.setAddRemoveAccess();
    }
    return;
  }

  /** Local Variables */
  selectionWithDropDownSearchOptions: any = [
    // Search options with custom selection or predefined values of code !
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCompanyType,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByAssetType,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByInstallTime,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectionStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCreatedDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByActivationStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByTimeUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByLockStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByMetricUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByManufactureDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByReportFormat,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByReportFrequency,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.maintenanceItemStaus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByServiceRegion,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByHyvaRegion,
  ];
  isSearchFilterWithDateAndTimePicker: any = [
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByInstallTime,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCreatedDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByManufactureDate,
  ];
  customInputSelectionForSearching: OptionList[] = [];
  customerTypeList: OptionList[] = COMPANY_TYPE_LIST;
  assetTypeList: OptionList[] = ASSET_TYPE_LIST;
  availableAssetTypeList: OptionList[] = [];
  customSelection: any = CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING;
  connectionStatusList: OptionList[] =
    REGISTERED_ASSET_CONNECTION_STATUSES_LIST;
  connectStatusList: OptionList[] = REGISTERED_ASSET_CONNECTION_STATUSES_LIST;
  userActivationStatuses: OptionList[] =
    USER_MANAGEMENT_ACTIVATION_STATUSES_LIST;
  maintenanceItemStatus: OptionList[] = MAINTENANCE_ITEM_STATUS_LIST;
  timeUnitsList: OptionList[] = TIME_UNITS_LIST;
  lockStatuses: OptionList[] = LOCK_STATUSES_LIST;
  metricUnitList: OptionList[] = ALARM_CONFIG_METRIC_UNITS_LIST;
  reportFormatList = [] as string[];
  reportFrequencyList: OptionList[] = SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST;

  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;
  hyvaRegionsQuery!: ReturnType<typeof useHyvaRegionsQuery>;

  created() {
    this.hyvaRegionsQuery = useHyvaRegionsQuery();
    this.serviceRegionsQuery = useServiceRegionsQuery();
    this.prepareDefaultInitialization();
    this.setAddRemoveAccess();
    if (this.needReportTypes) {
      // TODO This is a very ugly hack to prevent always making an API
      // call, and instead only make this call on the pages where it's
      // needed. The proper fix is to pass in all necessary lists of
      // options to this component explicitly. See AHMAPP-XXXX
      this.initializeReportTemplates();
    }
  }

  mounted() {
    this.unwatch = this.$watch('selectedAssetes', (newValue, oldValue) => {
      this.selectedRows = newValue;
    });
    this.unwatch = this.$watch('customerType', (newValue, oldValue) => {
      this.customerType = newValue;
    });
  }

  async initializeReportTemplates() {
    const res = await getReportTemplates();
    if (res.code === 200) {
      this.reportFormatList = res.data;
    }
  }

  setAddRemoveAccess() {
    if (!this.customerType) return;
    if (
      [
        COMPANY_TYPE.Dealer,
        COMPANY_TYPE.BodyBuilder,
        COMPANY_TYPE.TruckOEM,
      ].includes(this.customerType)
    )
      this.addRemoveAccess = true;
  }

  /**
   * Prepare default initialization
   */
  prepareDefaultInitialization() {
    if (this.assetTypeAvailable) {
      this.availableAssetTypeList = this.assetTypeList.filter(
        (assetItem: OptionList) =>
          this.assetTypeAvailable.some((item: string) => item == assetItem.id)
      );
    } else {
      this.availableAssetTypeList = this.assetTypeList;
    }

    // As default select first element from search options & it's query operator
    this.searchFieldOptions[0]
      ? (this.searchParams.reference = this.searchFieldOptions[0].prop)
      : null;
    this.searchFieldOptions[0]
      ? (this.searchParams.operator = this.searchFieldOptions[0].operator)
      : null;
  }

  /** Trigger search if not empty */
  handleInputEmpty(value: string) {
    if ((value && value.length > 2) || !value) this.handleSearch();
  }

  /** Trigger search event to parent */
  handleSearch() {
    this.$emit('search-event');
  }

  /** Clear the input when selection has changed */
  handleSelectionChange() {
    if (this.searchParams.value) {
      this.searchParams.value = null;
      this.triggerSearchEventToParent();
    }
    let searchReference = this.searchParams.reference;

    /** Set operator from config for each search reference */
    let currentSearchOption = this.searchFieldOptions.find(
      (item: any) => searchReference === item.prop
    );
    this.searchParams.operator =
      currentSearchOption.hasOwnProperty('operator') &&
      currentSearchOption.operator
        ? currentSearchOption.operator
        : GENERAL_QUERY_OPERATORS.Like;

    if (
      this.selectionWithDropDownSearchOptions.includes(
        this.searchParams.reference
      )
    ) {
      switch (searchReference) {
        case this.customSelection.searchByCompanyType:
          this.customInputSelectionForSearching = this.customerTypeList;
          break;
        case this.customSelection.searchByAssetType:
          this.customInputSelectionForSearching = this.availableAssetTypeList;
          break;
        case this.customSelection.searchByConnectionStatus:
          this.customInputSelectionForSearching = this.connectionStatusList;
          break;
        case this.customSelection.searchByActivationStatus:
          this.customInputSelectionForSearching = this.userActivationStatuses;
          break;
        case this.customSelection.searchByTimeUnit:
          this.customInputSelectionForSearching = this.timeUnitsList;
          break;
        case this.customSelection.searchByLockStatus:
          this.customInputSelectionForSearching = this.lockStatuses;
          break;
        case this.customSelection.searchByMetricUnit:
          this.customInputSelectionForSearching = this.metricUnitList;
          break;
        case this.customSelection.searchByUnit:
          this.customInputSelectionForSearching = this.metricUnitList;
          break;
        case this.customSelection.searchByReportFormat:
          this.customInputSelectionForSearching = this.reportFormatList.map(
            (rf) => ({
              id: rf,
              value: rf,
            })
          );
          break;
        case this.customSelection.searchByReportFrequency:
          this.customInputSelectionForSearching = this.reportFrequencyList;
          break;
        case this.customSelection.searchByConnectStatus:
          this.customInputSelectionForSearching = this.connectStatusList;
          break;
        case this.customSelection.maintenanceItemStaus:
          this.customInputSelectionForSearching = this.maintenanceItemStatus;
          break;
        case this.customSelection.searchByServiceRegion:
          this.customInputSelectionForSearching =
            this.serviceRegionsQuery.data.value?.map((region) => ({
              id: region.serviceRegionCode,
              value: this.$t(region.serviceRegionCode),
            })) ?? [];
          break;
        case this.customSelection.searchByHyvaRegion:
          this.customInputSelectionForSearching =
            this.hyvaRegionsQuery.data.value?.map((region) => ({
              id: region.code,
              value: this.$t(region.code),
            })) ?? [];
          break;
      }
    }
  }

  /** Triggered for custom input of searching -> code needs to be sent in API */
  handleSelectionInputChange() {
    this.triggerSearchEventToParent();
  }

  /** Handle the trigger event to parent */
  triggerSearchEventToParent() {
    this.$emit('search-event');
  }

  pickerOptions: any = {
    disabledDate: disabledFutureDate,
  };

  handleAddAssets() {
    this.$router.push(`${this.$route.params.customerId}/add`);
  }

  handleRemoveAssets() {
    this.$router.push(`${this.$route.params.customerId}/remove`);
  }
}
</script>

<template>
  <div class="d-flex jc-start ai-center">
    <div class="d-flex ai-center flex-g1">
      <div
        class="d-flex ai-center search-select-container"
        style="margin-right: 40px"
      >
        <el-select
          id="common_table_search_reference"
          class="search-select-field"
          style="margin-right: 0px"
          v-model="searchParams.reference"
          :placeholder="$t('common.pleaseSelect')"
          @change="handleSelectionChange"
        >
          <el-option
            v-for="(item, index) in searchFieldOptions"
            :id="'common_table_search_reference_' + index"
            :key="index"
            :label="$t(item.label)"
            :value="item.prop"
          />
        </el-select>

        <el-input
          id="common_table_search_input_value"
          class="search-input-content"
          v-model="searchParams.value"
          v-if="
            !selectionWithDropDownSearchOptions.includes(searchParams.reference)
          "
          :placeholder="$t('common.inputKeywordToSearch')"
          @input="handleInputEmpty"
          @keyup.enter.native="handleSearch"
          clearable
        >
          <i slot="suffix" class="el-icon-search" @click="handleSearch" />
        </el-input>

        <el-select
          id="common_table_search_custom_input_value"
          class="custom-search-option-dropdown"
          v-model="searchParams.value"
          v-if="
            selectionWithDropDownSearchOptions.includes(
              searchParams.reference
            ) &&
            !isSearchFilterWithDateAndTimePicker.includes(
              searchParams.reference
            )
          "
          :placeholder="$t('commonTable.selectAnOption')"
          @change="handleSelectionInputChange"
          style="margin-right: 0px"
        >
          <el-option
            v-for="(item, index) in customInputSelectionForSearching"
            :id="'common_table_search_input_value_' + index"
            :key="index"
            :label="$t(item.value)"
            :value="item.id"
          />
        </el-select>

        <el-date-picker
          id="common_table_search_date_time_picker_value"
          v-if="
            selectionWithDropDownSearchOptions.includes(
              searchParams.reference
            ) &&
            isSearchFilterWithDateAndTimePicker.includes(searchParams.reference)
          "
          class="date-time-picker"
          v-model="searchParams.value"
          type="date"
          v-bind:placeholder="
            searchParams.reference === customSelection.searchByInstallTime
              ? $t('assetMgmt.selectInstallTime')
              : $t('userModule.selectCreatedDate')
          "
          :picker-options="pickerOptions"
          @change="handleSelectionInputChange"
        />
      </div>
      <slot name="leftHeader"></slot>
    </div>
    <div v-if="addRemoveAccess">
      <el-button
        icon="el-icon-remove-outline"
        class="el-button el-button--plain el-button--medium"
        v-on:click="handleRemoveAssets"
        v-if="$router.currentRoute.path.includes('/customer-management/view/')"
      >
        {{ $t('common.removeAccess') }}
      </el-button>
      <el-button
        icon="el-icon-plus"
        class="el-button el-button--plain el-button--medium"
        v-on:click="handleAddAssets"
        v-if="$router.currentRoute.path.includes('/customer-management/view/')"
      >
        {{ $t('common.addAccess') }}
      </el-button>
    </div>
    <div class="d-flex ai-center">
      <slot name="rightHeader"></slot>
      <div
        class="column-button"
        v-if="cols && isColumnSelectionVisible"
        v-popover:popover
      >
        <div
          id="common_table_column_selection_btn"
          class="d-flex ai-center jc-center"
        >
          <div>
            <img
              style="width: 14px; height: 14px; margin-right: 8px"
              src="@/assets/imgs/customer-management/column.svg"
            />
          </div>
          <div>
            {{ $t('common.selectColumn') }}
          </div>
        </div>
      </div>
    </div>

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols"
        :id="'common_table_column_selection_reference' + index"
        :key="index"
        :label="
          /* @ts-expect-error TODO Wrong type */
          $t(item.label)
        "
        v-model="
          /* @ts-expect-error TODO Wrong type */
          item.visible
        "
        :disabled="
          /* @ts-expect-error TODO Wrong type */
          item.required
        "
        style="display: block; color: #373e41 !important"
      />
    </el-popover>
  </div>
</template>

<style scoped>
.el-icon-search {
  background-color: white;
  width: 30px;
  height: 25px;
  padding-top: 5px;
}

.custom-search-option-dropdown :deep(.el-input__inner) {
  width: 300px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.date-time-picker :deep(.el-input__inner) {
  width: 300px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>

<style lang="scss" scoped>
.column-button {
  padding: 5px 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;
}

.column-button:hover {
  color: var(--Main);
  // background-color: #5f6567;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 230px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 300px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
  }
}

:deep(.el-icon-circle-close) {
  font-size: 18px;
}
</style>
