<script lang="ts">
import Pagination from '@/components/pagination/Pagination.vue';
import { UserModule } from '@/store/modules/user';
import { SUBSCRIPTION_MANAGEMENT_STATUS } from '@/utils/workData/lookuptable';
import { SUBSCRIPTION_MANAGEMENT_ASSETS_COLS } from '@/utils/workData/subscriptionManagement';
import moment from 'moment';
import Sortable from 'sortablejs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'SubscriptionManagementTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() pageTotal!: number;
  @Prop() statisticsTips!: string;
  @Prop() createPagePath!: string;
  @Prop() createBtnName!: string;
  @Prop() viewPagePath!: string;
  @Prop({ default: true }) showTableHeaderOptions!: boolean;
  @Prop({ default: false }) isHeaderActionVisible!: boolean;

  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  subscriptionManagementAssetCols: any = SUBSCRIPTION_MANAGEMENT_ASSETS_COLS;
  subsMgmtStatus: any = SUBSCRIPTION_MANAGEMENT_STATUS;
  moment = moment;
  expendRowKeys: any = [];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  getRowKeys(row: any) {
    return row.id;
  }

  handleSortChange(val: any) {
    this.$emit('handle-sort-change', val.prop, val.order);
  }

  handleRowClick(row: any, column: any, event: any) {
    // Delete expanded element if exist in DOM
    let expandedTr = document.getElementsByClassName('el-table__expanded-cell');
    if (expandedTr[0]) expandedTr[0].remove();
    // Delete expanded icon class if exist in DOM
    let expandedIcon = document.getElementsByClassName(
      'el-table__expand-icon--expanded'
    );
    if (expandedIcon && expandedIcon[0]) expandedIcon[0].classList.remove();
    // Add id for new row clicked -> expand the row
    this.expendRowKeys = [];
    this.expendRowKeys.push(row.id);
  }

  handleActionBtnClick(actionName: any, rowInfo: any) {
    this.$emit(actionName, rowInfo);
  }

  getPageList(val: any) {
    this.$emit('handle-pagination', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }

  /**
   * Connection asset from table
   */
  handleConnectBtnClick(rowInfo: any) {
    this.$router.push('/asset-mgmt/connect/edit/' + rowInfo.id);
  }

  /**
   * Handle Renew btn click
   */
  handleRenewBtnClick(row: any) {
    this.$emit('handle-renew-btn-clicked', 'renew', row);
  }

  /**
   * Handle Auto renew btn click
   */
  handleTerminateBtnClick(row: any) {
    this.$emit('handle-terminate-btn-clicked', 'terminate', row);
  }

  handleTerminateSingleAssetBtnClick(parentSlot: any, asset: any) {
    this.$emit(
      'handle-single-asset-terminate-btn-clicked',
      'terminate',
      parentSlot,
      asset
    );
  }
}
</script>

<template>
  <div>
    <div class="header-actions">
      <div class="left-filter-inputs">
        <slot></slot>
      </div>
      <el-button
        class="select-columns-btn"
        type="plain"
        v-if="isHeaderActionVisible"
        v-popover:popover
      >
        <div class="d-flex ai-center jc-center">
          <div>
            <img
              style="width: 14px; height: 14px; margin-right: 8px"
              src="@/assets/imgs/selectColumn.svg"
            />
          </div>
          <div>
            {{ $t('common.selectColumn') }}
          </div>
        </div>
      </el-button>
    </div>
    <el-table
      ref="subsMgmt"
      :data="list"
      :tree-props="treeProp"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @row-click="handleRowClick"
      @sort-change="handleSortChange"
      :expand-row-keys="expendRowKeys"
      :row-key="getRowKeys"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}`"
        :prop="dragCols[index].prop"
        :min-width="col.width ? col.width : '140'"
        :sortable="col.sortable"
        :type="index === 0 ? 'expand' : null"
      >
        <template v-slot:header>
          <div class="d-flex jc-between table-header">
            <span style="word-break: keep-all">{{ $t(col.label) }}</span>
          </div>
        </template>

        <template v-slot="parentSlotProps" v-if="index === 0">
          <div
            v-if="
              parentSlotProps.row.hasOwnProperty('details') &&
              Object.keys(parentSlotProps.row.details).length > 0
            "
            class="expand-body"
          >
            <div class="order-header-container">
              <div class="order-header-item">
                {{ $t('subsMgmt.assetType') }}:
                <span class="order-header-item-value">{{
                  $t(parentSlotProps.row.details.assetType)
                }}</span>
              </div>
              <div class="order-header-item">
                {{ $t('subsMgmt.numberOfAssets') }}:
                <span class="order-header-item-value">{{
                  $t(parentSlotProps.row.details.numberOfAssets)
                }}</span>
              </div>
              <div class="order-header-item">
                {{ $t('subsMgmt.assignDate') }}:
                <span class="order-header-item-value">{{
                  moment(parentSlotProps.row.assignDate).format('YYYY/MM/DD')
                }}</span>
              </div>
              <div class="order-header-item">
                {{ $t('subsMgmt.industry') }}:
                <span class="order-header-item-value">{{
                  $t(parentSlotProps.row.details.industry)
                }}</span>
              </div>
            </div>

            <!-- Asset table -> inner layer -->
            <div
              v-if="
                parentSlotProps.row.details.hasOwnProperty('assets') &&
                parentSlotProps.row.details.assets.length > 0
              "
              class="asset-inner-table"
            >
              <el-table
                class="ineer-assets-table"
                :data="parentSlotProps.row.details.assets"
                row-key="id"
                :row-style="{ height: '40px' }"
                :cell-style="{ padding: '7px 0px', background: '#F0F0F0' }"
                style="width: 1800px"
                :header-cell-style="{ background: '#F0F0F0' }"
              >
                <el-table-column
                  v-for="(
                    assetCol, assetIndex
                  ) in subscriptionManagementAssetCols"
                  :key="`asset_col_${assetIndex}`"
                  :prop="assetCol.prop"
                  :min-width="col.width ? col.width : '100'"
                  :sortable="assetCol.sortable"
                  show-overflow-tooltip
                >
                  <template v-slot:header>
                    <div class="d-flex jc-between table-header">
                      <span style="word-break: break-word">{{
                        $t(assetCol.label)
                      }}</span>
                    </div>
                  </template>

                  <template
                    v-slot="slotProps"
                    v-if="assetCol.prop === 'lastCommunicationTime'"
                  >
                    {{
                      slotProps.row.lastCommunicationTime
                        ? moment(slotProps.row.lastCommunicationTime).format(
                            'YYYY/MM/DD HH:mm:ss'
                          )
                        : ''
                    }}
                  </template>

                  <template
                    v-slot="slotProps"
                    v-else-if="assetCol.prop === 'installTime'"
                  >
                    {{
                      slotProps.row.installTime
                        ? moment(slotProps.row.installTime).format(
                            'YYYY/MM/DD HH:mm:ss'
                          )
                        : ''
                    }}
                  </template>

                  <template
                    v-slot="slotProps"
                    v-else-if="assetCol.prop === 'serviceRegion'"
                  >
                    {{ $t(slotProps.row.serviceRegion) }}
                  </template>

                  <template
                    v-slot="slotProps"
                    v-else-if="assetCol.prop === 'manufactureDate'"
                  >
                    {{
                      slotProps.row.manufactureDate
                        ? moment(slotProps.row.manufactureDate).format(
                            'YYYY/MM/DD HH:mm:ss'
                          )
                        : ''
                    }}
                  </template>

                  <!-- Terminate subscription per single asset -->
                  <template
                    v-slot="slotProps"
                    v-else-if="assetCol.prop === 'actions'"
                  >
                    <el-button
                      id="terminate_subscription_button"
                      v-permission="['AUTHRSC_ACTION_SUB_TERMINATE']"
                      type="default"
                      class="terminate-btn action-btn"
                      size="small"
                      v-if="
                        [
                          subsMgmtStatus.SubstatActive,
                          subsMgmtStatus.SubstatDue,
                          subsMgmtStatus.SubstatPendingRenew,
                        ].includes(slotProps.row.subscriptionStatus)
                      "
                      @click.stop="
                        handleTerminateSingleAssetBtnClick(
                          parentSlotProps.row,
                          slotProps.row
                        )
                      "
                    >
                      {{ $t('subsMgmt.terminateBtn') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- End of Asset table -> inner layer -->

            <div v-else class="no-data-on-expand">
              {{ $t('subsMgmt.noAssetsDataAvailable') }}
            </div>
          </div>

          <div v-else class="no-data-on-expand">
            {{ $t('subsMgmt.noDataOnExpand') }}
          </div>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'orderId'">
          <span v-html="slotProps.row.orderId" />
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'cadId'">
          <span v-html="slotProps.row.cadId" />
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'companyName'">
          <span v-html="slotProps.row.companyName" />
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.prop === 'organizationName'"
        >
          <span v-html="slotProps.row.organizationName" />
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.prop === 'customerSubscriptionPackage'"
        >
          <span v-html="slotProps.row.customerSubscriptionPackage" />
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.prop === 'assetSubscriptionPackage'"
        >
          <span v-html="slotProps.row.assetSubscriptionPackage" />
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.prop === 'subscriptionStatus'"
        >
          <span> {{ $t(slotProps.row.subscriptionStatus) }} </span>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'actions'">
          <el-button
            id="renew_subscription_button"
            v-permission="['AUTHRSC_ACTION_SUB_RENEW']"
            type="default"
            class="renew-btn action-btn"
            size="small"
            v-if="
              !slotProps.row.isAutoRenewEnabled &&
              [
                subsMgmtStatus.SubstatActive,
                subsMgmtStatus.SubstatDue,
                subsMgmtStatus.SubstatPendingRenew,
              ].includes(slotProps.row.subscriptionStatus)
            "
            @click.stop="handleRenewBtnClick(slotProps.row)"
          >
            {{ $t('subsMgmt.renewBtn') }}
          </el-button>
          <div
            v-if="
              slotProps.row.isAutoRenewEnabled &&
              [
                subsMgmtStatus.SubstatActive,
                subsMgmtStatus.SubstatDue,
                subsMgmtStatus.SubstatPendingRenew,
              ].includes(slotProps.row.subscriptionStatus)
            "
            class="auto-renew-flag"
          >
            <span>
              {{ $t('subsMgmt.autoRenewFlag') }}
            </span>
          </div>
          <div
            v-if="
              !slotProps.row.isAutoRenewEnabled &&
              ![
                subsMgmtStatus.SubstatActive,
                subsMgmtStatus.SubstatDue,
                subsMgmtStatus.SubstatPendingRenew,
              ].includes(slotProps.row.subscriptionStatus)
            "
            class="auto-renew-flag"
          >
            <span />
          </div>
          <el-button
            id="terminate_subscription_button"
            v-permission="['AUTHRSC_ACTION_SUB_TERMINATE']"
            type="default"
            class="terminate-btn action-btn"
            size="small"
            v-if="
              slotProps.row.subscriptionStatus !=
              subsMgmtStatus.SubstatTerminated
            "
            @click.stop="handleTerminateBtnClick(slotProps.row)"
          >
            {{ $t('subsMgmt.terminateBtn') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- v-show="total > 0" -->
    <Pagination
      v-show="
        /* @ts-expect-error TODO Wrong type */
        parseInt(pageTotal) > parseInt(pageSize)
      "
      :total="pageTotal"
      :page.sync="listQuery.page"
      :limt.sync="listQuery.limit"
      @pagination="getPageList"
    />

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols.slice(1, 11)"
        :key="index"
        :label="
          /* @ts-expect-error TODO Wrong type */
          $t(item.label)
        "
        v-model="
          /* @ts-expect-error TODO Wrong type */
          item.visible
        "
        :disabled="
          /* @ts-expect-error TODO Wrong type */
          item.required
        "
        style="display: block; color: #373e41 !important"
      ></el-checkbox>
    </el-popover>
  </div>
</template>

<style scoped>
.select-columns-btn {
  height: 40px;
  margin-top: 5px;
}

.el-table {
  background-color: #f0f0f0;
}

.el-table--medium {
  background-color: #f0f0f0;
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: #f0f0f0 !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table :deep(.expand-body) {
  background-color: #f0f0f0 !important;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 10px 0;
}

.left-filter-inputs {
  display: block;
  margin: 4px 0 4px 4px;
}

.order-header-container {
  display: flex;
  /* font-size: 16px; */
  margin-left: 50px;
}

.order-header-item {
  margin: 17px 64px 17px 4px;
  display: flex;
  align-items: center;
}

.order-header-item-value {
  color: #373e41;
  font-family: var(--fontRobotoMedium);
  padding-left: 4px;
}

.el-table__expanded-cell {
  padding: 4px !important;
  background-color: #f0f0f0;
}

.asset-inner-table {
  padding: 0px 0 20px 50px;
  width: 2000px;
}

.renew-btn {
  color: #339d99;
  border-color: #339d99 !important;
}

.renew-btn:hover {
  color: white;
  background-color: #339d99;
}

.action-btn {
  min-width: 80px;
}

.auto-renew-btn {
  color: #373e41;
  border: none !important;
}

.auto-renew-btn:hover {
  color: white;
  background-color: #2e3335;
}

.terminate-btn {
  color: #707070;
  border-color: #707070 !important;
}

.terminate-btn:hover {
  color: white;
  background-color: #5c5b5b;
}

.expand-body {
  overflow-x: scroll;
}

.no-data-on-expand {
  font-size: 16px;
  font-family: var(--fontRobotoMedium);
  margin: 10px;
  padding-left: 45px;
}

.auto-renew-flag {
  padding: 0 12px 0 5px;
  min-width: 90px;
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 860px) {
  .left-filter-inputs {
    display: flex;
  }
}

.table-header {
  display: inline-block;
  // margin-right: 2px;
}

.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}
</style>

<style lang="scss" scoped>
.highlight-search-element {
  background-color: #ffeb9a !important;
  border-radius: 4px;
}

.el-table__empty-block {
  background-color: white !important;
}

.expanded {
  background-color: #c9c9c9 !important;
}

.expanded:hover {
  background-color: #c9c9c9 !important;
}
</style>

<style scoped>
.connect-btn {
  border-radius: 6px;
  /* font-weight: bold; */
}

.connect-btn:hover {
  color: white;
  background-color: #5f6567 !important;
}

.el-button--small {
  padding: 6px 10px !important;
}
</style>
