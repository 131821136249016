<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PROD_CATA_CONF_LIST } from '@/utils/workData/prodCata';
import ModelSelect from '../components/ModelSelect.vue';
import PureTable from '@/components/table/PureTable.vue';
import { getFilterProductModels, ProductModel } from '@/api/products';

@Component({
  name: 'prodModels',
  components: {
    'pure-table': PureTable,
    'model-select': ModelSelect,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = PROD_CATA_CONF_LIST.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  tableList: ProductModel[] = [];
  total: number = 0;
  isProductCatalogueLoading: boolean = false;
  pageSize: string = '10000';
  page: string = '1';
  sortAndOrderData: any = {
    sortBy: 'createdOn',
    order: 'DESC',
  };

  mounted() {
    this.fetchProdmodels();
  }

  /** Get product models */
  async fetchProdmodels(): Promise<void> {
    try {
      this.isProductCatalogueLoading = true;
      const requestUrl = this.generateRequestUrlWithParams(
        this.page,
        this.pageSize,
        null,
        this.sortAndOrderData
      );
      const response = await getFilterProductModels(requestUrl);
      /** Add action props for each item */
      response.data = this.addPropsActions(response.data, ['COPY']);
      this.tableList = this.translateTableData(response.data);
      this.total = response.data.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.isProductCatalogueLoading = false;
    }
  }

  /**
   * Copy row item
   * @param row
   */
  copyOneModel(row: any): void {
    this.$router.push({
      name: 'createNewModel',
      params: {
        id: row.id,
        action: 'copy',
      },
    });
  }

  /**
   * Get product models filteres by custom url query params
   * @param sendData
   */
  async fetchFilterProductModels(sendData: any): Promise<void> {
    try {
      this.isProductCatalogueLoading = true;
      const requestUrl = this.generateRequestUrlWithParams(
        this.page,
        this.pageSize,
        sendData,
        this.sortAndOrderData
      );
      const response = await getFilterProductModels(requestUrl);
      /** Add action props for each item */
      response.data = this.addPropsActions(response.data, ['COPY']);
      this.tableList = this.translateTableData(response.data);
      this.total = response.data.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.isProductCatalogueLoading = false;
    }
  }

  translateTableData(preTranslatedTableData: ProductModel[]): ProductModel[] {
    return preTranslatedTableData.map((item) => ({
      ...item,
      assetTypeCode: this.$t(item.assetTypeCode),
      lifeCycle: this.$t(item.lifeCycle),
    }));
  }

  /**
   * Generate request URL by multiple factors
   * @param pageNumber
   * @param pageSize
   * @param selectForm
   * @param sortAndOrderData
   */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    selectForm: any,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    if (selectForm && selectForm.code)
      finalUrl += `&code=${encodeURIComponent(selectForm.code)}`;
    if (selectForm && selectForm.assetType)
      finalUrl += `&assetType=${selectForm.assetType}`;
    if (selectForm && selectForm.lifeCycle)
      finalUrl += `&lifeCycle=${selectForm.lifeCycle}`;
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    sortAndOrderData.sortBy && sortAndOrderData.order
      ? (finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`)
      : (finalUrl += '&sortBy=createdOn&order=DESC');
    if (finalUrl) finalUrl = '?' + finalUrl.slice(1);
    return finalUrl;
  }

  /**
   * Add props actions
   * @param responseList
   * @param actions
   */
  addPropsActions(
    responseList: ProductModel[],
    actions: string[]
  ): ProductModel[] {
    const result: ProductModel[] = responseList.map((item: ProductModel) => {
      item.action = actions;
      return item;
    });
    return result;
  }

  /**
   * Filter by sort event
   * @param sortBy
   * @param order
   */
  fetchProductModelsBySortEvent(sortBy: any, order: any): void {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    this.fetchProdmodels();
  }
}
</script>

<template>
  <div class="app-container">
    <model-select
      id="product_model_filter"
      @send-filter-data="fetchFilterProductModels"
    ></model-select>
    <pure-table
      id="product_model_table"
      v-loading="isProductCatalogueLoading"
      :tableList="tableList"
      :cols="cols"
      actionTable="Copy"
      :maxHeight="'590'"
      :viewPagePath="'configure-model'"
      @handle-sort-change="fetchProductModelsBySortEvent"
      @COPY="copyOneModel"
    >
    </pure-table>
    <p class="total-statistics">
      {{ $t('prodCata.totalProductModel') }}:
      <span class="total-statistics-value">{{ total }}</span>
    </p>
  </div>
</template>

<style lang="scss" scoped></style>
