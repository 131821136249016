<script lang="ts">
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { COMPANY_TYPE, THEME_LIST } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'GeneralInfomation',
  components: {
    GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) customerInfo!: any;
  @Prop({ required: false }) logo: any | undefined;

  themeColours: any = [];
  companyType: string = '';
  country: string = '';
  amountOfUsers: string = '';
  companyTypesForInstalledCodeDisplay: any = [
    COMPANY_TYPE.Hyva,
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];

  @Watch('customerInfo', { deep: true })
  handleDataAssetTypes(newValue: any) {
    if (newValue) {
      this.convert();
    }
    return newValue;
  }

  convert() {
    if (this.customerInfo.setting && this.customerInfo.setting.theme) {
      let themeId = this.customerInfo.setting.theme;
      let themes = THEME_LIST.filter((item) => item.id == themeId);
      if (themes.length > 0)
        this.themeColours.push({
          key: themes[0].id,
          name: themes[0].name,
          style: themes[0].value,
        });
    }
  }
}
</script>

<template>
  <div>
    <GeneralInfo
      id="cust_view_general_info_name"
      :name="$t('customerModule.name')"
      :value="customerInfo.name"
    />
    <GeneralInfo
      id="cust_view_general_info_companyType"
      :name="$t('customerModule.customerType')"
      :value="customerInfo.companyType"
    />
    <GeneralInfo
      id="cust_view_general_info_companyIndustry"
      :name="$t('customerModule.companyIndustry')"
      :value="customerInfo.industry"
    />
    <GeneralInfo
      id="cust_view_general_info_contractNumber"
      :name="$t('customerModule.contractNumber')"
      :value="customerInfo.contractNumber"
    />
    <GeneralInfo
      id="cust_view_general_info_contactPersonName"
      :name="$t('customerModule.contactPersonName')"
      :value="customerInfo.contactPersonName"
    />
    <GeneralInfo
      id="cust_view_general_info_contactPersonEmail"
      :name="$t('customerModule.contactPersonEmail')"
      :value="customerInfo.contactPersonEmail"
    />
    <GeneralInfo
      id="cust_view_general_info_contactPersonMobile"
      :name="$t('customerModule.contactPersonMobile')"
      :value="customerInfo.contactPersonPhone"
    />
    <!-- <GeneralInfo
      id="cust_view_general_info_amountOfUser"
      :name="$t('customerModule.amountOfUsers')"
      :value="amountOfUsers"
    /> -->
    <GeneralInfo
      id="cust_view_general_info_primaryAccount"
      :name="$t('customerModule.primaryAccount')"
      :value="customerInfo.primaryUserEmail"
    />
    <GeneralInfo
      v-if="
        companyTypesForInstalledCodeDisplay.includes(customerInfo.companyType)
      "
      id="cust_view_general_info_installerCode"
      :name="$t('customerModule.installerCode')"
      :value="customerInfo.installerCode"
    />
    <GeneralInfo
      id="cust_view_general_info_logo"
      :name="$t('customerModule.logo')"
    >
      <img :src="logo" class="avatar" />
    </GeneralInfo>
    <GeneralInfo
      id="cust_view_general_info_themeColor"
      :name="$t('customerModule.themeColours')"
    >
      <div class="d-flex ai-center">
        <div
          class="d-flex ai-center"
          style="width: 60px; height: 60px"
          v-for="(item, index) in themeColours"
          :key="index"
        >
          <el-button :class="item.name" :style="item.style" disabled />
        </div>
      </div>
    </GeneralInfo>
    <GeneralInfo
      id="cust_view_general_info_service_region"
      :name="$t('common.serviceRegion')"
      :value="customerInfo.serviceRegion"
    />
    <GeneralInfo
      id="cust_view_general_info_hyva_region"
      :name="$t('common.hyvaRegion')"
      :value="customerInfo.hyvaRegion"
    />
    <GeneralInfo
      id="cust_view_general_info_province"
      :name="$t('customerModule.province')"
      :value="customerInfo.province"
    />
    <GeneralInfo
      id="cust_view_general_info_city"
      :name="$t('customerModule.city')"
      :value="customerInfo.city"
    />
    <GeneralInfo
      id="cust_view_general_info_district"
      :name="'customerModule.district'"
      :value="customerInfo.district"
    />
    <GeneralInfo
      id="cust_view_general_info_street"
      :name="$t('customerModule.street')"
      :value="customerInfo.street"
    />
    <GeneralInfo
      id="cust_view_general_info_postCode"
      :name="$t('customerModule.postCode')"
      :value="customerInfo.zipCode"
    />
    <GeneralInfo
      id="cust_view_general_info_note"
      :name="$t('customerModule.note')"
      :value="customerInfo.note"
    />
    <GeneralInfo
      id="cust_view_general_info_time_zone"
      :name="$t('customerModule.timeZone')"
      :value="customerInfo.timeZone"
    />
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  width: 96px;
  height: 96px;
  border: 1px solid #d9d9d9;

  .avatar-img {
    width: 96px;
    height: 96px;
  }
}

.theme-color {
  width: 60px;
  height: 60px;
  background-color: pink;
  border: 1px solid #707070;
}
</style>
