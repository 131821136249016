<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CusFormItem from '@/components/form/CusFormItem.vue';

@Component({
  name: 'SubsMgmtModal',
  components: {
    'cus-form-item': CusFormItem,
  },
})
export default class extends Vue {
  @Prop({ required: false }) isModalVisible!: boolean;
  @Prop({ required: true }) triggerSource!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) modalContent!: string;

  /** Buttons events */
  closeDialog() {
    this.$emit('handle-cancel');
  }

  /** Close modal event triggering */
  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  /** Trigger event to parrent for renewing an order */
  handleRenewOrder() {
    this.$emit('handle-renew-event');
  }

  /** Trigger event to parrent for terminating an order */
  handleTerminateOrder() {
    this.$emit('handle-terminate-event');
  }
}
</script>

<template>
  <el-dialog
    :visible="isModalVisible"
    class="new-cust-dialog"
    :width="'600px'"
    :top="'25vh'"
    :title="title"
    @close="closeDialog"
  >
    <div class="modal-body-content">
      <div v-if="triggerSource === 'terminate'" style="margin-top: 20px">
        <img src="@/icons/svg/deactive.svg" style="width: 48px" />
      </div>
      <div class="content-message" v-html="modalContent"></div>
    </div>

    <div class="d-flex ai-center" style="flex-direction: column">
      <div class="action-buttons" style="margin: 10px 0px">
        <el-button
          id="cancelmodalbtn"
          class="cancel-btn"
          style="margin-right: 30px"
          type="plain"
          @click="handleDialogCancel"
        >
          {{ $t('common.cancelBtn') }}
        </el-button>
        <el-button
          id="reneworderbtn"
          v-if="triggerSource === 'renew'"
          class="adjust-btn"
          type="info"
          @click="handleRenewOrder"
        >
          {{ $t('subsMgmt.renewOrder') }}
        </el-button>
        <el-button
          id="terminateorderbtn"
          v-if="triggerSource === 'terminate'"
          class="adjust-btn"
          type="info"
          @click="handleTerminateOrder"
        >
          {{ $t('subsMgmt.terminateOrder') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
.content-message :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.modal-body-content {
  text-align: center;
  /* padding: 20px; */
}

.content-message {
  font-family: var(--fontRobotoMedium);
  font-size: 18px;
  padding: 20px;
  word-break: keep-all;
  line-height: 1.5;
}

.new-cust-dialog {
  border-radius: 15px;
}

.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
  margin-left: 210px;
}

.new-cust-dialog :deep(.el-dialog__header) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.new-cust-dialog :deep(.el-dialog) {
  border-radius: 6px;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}

.overlapping-content {
  padding: 30px 80px;
  text-align: center;
  font-size: 20px;
  line-height: 1.6;
  font-family: var(--fontRobotoMedium);
  word-break: keep-all;
}
</style>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__header) {
  padding: 6px;
  padding-bottom: 6px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
  // border-bottom: 0.2px black solid;
  // margin: 0.5rem 1.5rem;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 100%;
  // margin-right: 30px;
  font-family: var(--fontRobotoMedium);
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.start-date {
  margin-left: 185px;
  margin-bottom: 10px;
}

.action-buttons {
  padding: 0 50px;
  display: flex;
}
</style>
