import { Ref, unref, watchEffect, WatchStopHandle } from 'vue';

import i18n from '@/lang';
import { customFailedMessage } from '@/utils/prompt';

import { AsyncValue } from './async';

/**
 * Show a notification whenever the AsyncValue in the given ref is in an error state.
 *
 * Returns a callback that can be called to stop watching the ref.
 */
export function showNotificationOnError(
  asyncRef: Ref<AsyncValue<unknown>>,
  i18nKey: string
): WatchStopHandle {
  return watchEffect(() => {
    if (unref(asyncRef).error) {
      customFailedMessage(i18n.tc(i18nKey));
    }
  });
}
